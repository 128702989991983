import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { ButtonContainer, ContainerItems, Title, TitleText } from './StyledComponents';

interface Props {
    titleText?: string;
    buttonContainer?: any;
}

export class ContainerTitle extends React.Component<Props> {
    render() {
        const { titleText, buttonContainer } = this.props;

        return (
            <>
                <Title>
                    <Container>
                        <Row>
                            <Col>
                                <ContainerItems>
                                    <TitleText>{titleText}</TitleText>
                                    <ButtonContainer>{buttonContainer}</ButtonContainer>
                                </ContainerItems>
                            </Col>
                        </Row>
                    </Container>
                </Title>
            </>
        );
    }
}

export default ContainerTitle;
