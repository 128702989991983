import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/button/button';
import { defaultValue, ErrorAndMessage } from '../../model/validation';
import { ContainerBody, SubTitle, Title } from './StyledComponents';
import TextField from '../../components/custom-text-field/custom-text-field';
import AccountService from '../../services/account-service';
import Footer from '../footer/footer';
import Header from '../../components/header-white/header';

export interface ActivateAccountProps {
    t: any;
    isFirstAccess?: boolean;
}

const ActivateAccount = (props: ActivateAccountProps): JSX.Element => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordError, setNewPasswordError] = useState<ErrorAndMessage>(defaultValue);
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
    const [newPasswordConfirmationError, setNewPasswordConfirmationError] = useState<ErrorAndMessage>(defaultValue);

    const validatePassword = async () => {
        if (newPassword != null && newPassword.length >= 8) {
            setNewPasswordError({
                message: '',
                value: false
            });
            return {};
        }
        setNewPasswordError({
            message: 'Preencha com uma senha válida',
            value: true
        });
    };

    const validatePasswordConfirmation = async () => {
        if (newPasswordConfirmation === newPassword) {
            setNewPasswordConfirmationError({
                message: '',
                value: false
            });
            return {};
        }
        setNewPasswordConfirmationError({
            message: 'A confirmação não corresponde a senha',
            value: true
        });
    };

    const saveNewPassword = async () => {
        if (newPasswordError.value || newPasswordConfirmationError.value) {
            return {}
        } else {
            const params = new URLSearchParams(history.location.search);
            const key = params.get('key');

            const data = {
                newPassword: newPassword,
                key: key
            };
            if (props.isFirstAccess) {
                await AccountService.activateAccount(data);
                history.replace('/account/activate/success');
            } else {
                await AccountService.resetPassword(data);
                history.replace('/reset/finish/success');
            }

        }
    };

    const history = useHistory();

    return (
        <div>
            <Header />
            <Container>
                <Row>
                    <Col md={{ size: '4', offset: '4' }}>
                        <ContainerBody>
                            <Title>{props.isFirstAccess ? props.t('activate-account.title') : props.t('activate-account.title-two')}</Title>
                            <SubTitle>{props.isFirstAccess ? props.t('activate-account.subtitle') : props.t('activate-account.subtitle-two')}</SubTitle>

                            <TextField
                                onChange={setNewPassword}
                                id="password"
                                value={newPassword}
                                label={props.t('activate-account.placeholder.password')}
                                placeholder={props.t('activate-account.placeholder.password')}
                                error={newPasswordError?.value}
                                errorText={newPasswordError?.message}
                                isPassword
                                onBlur={validatePassword}
                            />
                            <div style={{ marginBottom: '20px', fontSize: '12px', color: '#505050' }}>
                                {' '}
                                {props.t('activate-account.text')}
                            </div>
                            <TextField
                                onChange={setNewPasswordConfirmation}
                                id="password-confirmation"
                                value={newPasswordConfirmation}
                                label={props.t('activate-account.placeholder.password-confirmation')}
                                placeholder={props.t('activate-account.placeholder.password-confirmation')}
                                error={newPasswordConfirmationError?.value}
                                errorText={newPasswordConfirmationError?.message}
                                style={{ marginBottom: '20px' }}
                                isPassword
                                onBlur={validatePasswordConfirmation}
                            />
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Button style={{ width: '206px', height: '44px', fontSize: '14px' }} onClick={() => saveNewPassword()} type="submit">
                                    {props.t('activate-account.button.save')}
                                </Button>
                            </div>
                        </ContainerBody>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default withTranslation()(ActivateAccount) as React.ComponentType<any>;
