import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/button/button';
import { ContainerTitle } from '../../components/container-title/container-title';
import SimpleOrderedTable, { ColumnSort } from '../../components/simple-ordered-table/simple-ordered-table';
import { Status } from '../../model/enums/status';
import { Fuel } from '../../model/fuel';
import { Pageable } from '../../model/pageable';
import { Predicate } from '../../model/predicate';
import FuelService from '../../services/fuel-service';
import StringUtils from '../../shared/util/string-utils';
import ContainerPage from '../../styled-components/ContainerPage';
import FuelCreateOrEditModal from './fuel-create-or-edit-modal/fuel-create-or-edit-modal';

interface IPointsPerLiterProps extends RouteComponentProps, WithTranslation {
    // TODO
}

interface IPointsPerLiterState {
    fuel: Fuel[];
    page: Pageable;
    isLoading: boolean;
    predicate: Predicate;
    isActionLoading: boolean;
    isFuelModalOpen: boolean;
    fuelModalId: number | null;
    alertModalRoutesList: string[];
    isOpenActionModal: number | null;
    isOpenActionModalIsArchive: boolean;
    isAlertModalOpenValue: string | null;
}

class PointsPerLiter extends React.Component<IPointsPerLiterProps, IPointsPerLiterState> {
    $defaultPath: string;
    constructor(props) {
        super(props);
        this.$defaultPath = '';
        this.state = {
            fuel: [],
            predicate: {},
            isLoading: false,
            fuelModalId: null,
            isFuelModalOpen: false,
            isActionLoading: false,
            isOpenActionModal: null,
            isAlertModalOpenValue: null,
            isOpenActionModalIsArchive: false,
            alertModalRoutesList: ['/new', '/edit'],
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'code,desc' }
        };
    }

    componentDidMount() {
        this.getAllFuel().then(callback => callback);
    }

    resetPage = () => {
        this.setState({
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'name,asc' }
        });
    };

    updatePage = async (key: string, event: number | string) => {
        this.setState({
            page: {
                ...this.state.page,
                [key]: event
            }
        });
    };

    getAllFuel = async (shouldResetPage?: boolean) => {
        if (shouldResetPage) this.resetPage();

        const { predicate, page } = this.state;

        this.isLoading(true);

        FuelService.getAllFuels(predicate, page).then(result =>
            this.setState(
                {
                    fuel: result.content,
                    page: {
                        ...this.state.page,
                        size: result.size,
                        page: result.number,
                        totalPages: result.totalPages,
                        totalElements: result.totalElements
                    }
                },
                async () => {
                    this.isLoading(false);
                    if (isEmpty(this.state.fuel) && this.state.page.page) {
                        await this.updatePage('page', this.state.page.page - 1);
                        await this.getAllFuel();
                    }
                }
            )
        );
    };

    isFuelModalOpen = (isFuelModalOpen: boolean, fuelModalId: number | null) => {
        this.setState({
            fuelModalId,
            isFuelModalOpen
        });
    };

    activateFuel = (id: number) => {
        this.isActionLoading(true);
        this.isOpenActionModal(id, true);
        FuelService.activateFuel(id)
            .then(() => {
                this.getAllFuel().then(() => {
                    this.isActionLoading(false);
                    this.isOpenActionModal(null, true);
                });
            })
            .catch(() => this.isActionLoading(false));
    };

    isActionLoading = (isActionLoading: boolean) => {
        this.setState({
            isActionLoading
        });
    };

    isOpenActionModal = (id: number | null, isOpenActionModalIsArchive: boolean) => {
        this.setState({
            isOpenActionModal: id,
            isOpenActionModalIsArchive
        });
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: { ...this.state.page, page: newPage }
            },
            () => this.getAllFuel()
        );
    };

    handleClickRow = (id: number) => {
        const it = this.state.fuel[id];
        this.isFuelModalOpen(true, it.id!);
    };

    handleSort = (code: string) => {
        this.setState(
            {
                page: { ...this.state.page, sort: code }
            },
            () => this.getAllFuel()
        );
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    tableHeaders: ColumnSort[] = [
        { name: this.props.t('points-per-liter.table.fuel'), sortCode: 'fuelType' },
        { name: this.props.t('points-per-liter.table.pointsPerLiter'), sortCode: 'pointsPerLiter' },
        { sortDisabled: true }
    ];

    handleTransformToTableContent = (content?: Fuel[]): (string | JSX.Element)[][] => {
        const { t } = this.props;
        if (content == null || content.length === 0) return [];
        const result: (string | JSX.Element)[][] = [];
        content.forEach((item: Fuel, index: number) => {
            result.push([
                item.fuelName ? item.fuelName : t('global.emptyLabel'),
                item.pointsPerLiter ? StringUtils.formatCurrencyAndNumber(item.pointsPerLiter, true, 0) : t('global.emptyLabel'),
                this.renderLastItems(index, item)
            ]);
        });
        return result;
    };

    renderStatus = (status: Status) => {
        const { t } = this.props;
        const statusClassName = status === Status.ACTIVE ? 'is-active' : 'is-inactive';
        return (
            <div className={classNames([statusClassName, 'status-list-item'])}>
                <span>
                    {t(`enum.status.${status}`)}
                </span>
            </div>
        );
    };

    renderLastItems = (index: number, item: Fuel) => {
        const { isActionLoading } = this.state;
        const deleteClassName = item.status === Status.ACTIVE ? 'icon-deletar' : 'icon-deletar-reverter';
        const isDisabledClassName = isActionLoading ? 'is-item-disabled' : '';
        return (
            <div key={index} style={{ display: 'flex', justifyContent: 'center' }} className={isDisabledClassName}>
                {item.status === Status.INACTIVE ? (
                    <i style={{ opacity: 0.5, marginRight: '17px' }} className="icon-editar action-list-icon" />
                ) : (
                    <i
                        className="icon-editar action-list-icon"
                        onClick={() => this.handleClickRow(index)}
                        style={{ marginRight: '17px', cursor: 'pointer' }}
                    />
                )}
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.activateFuel(item.id!)}
                    className={classNames([deleteClassName, isDisabledClassName, 'action-list-icon'])}
                />
            </div>
        );
    };

    render() {
        const { t } = this.props;
        const { fuel, isFuelModalOpen, fuelModalId, isLoading } = this.state;
        const disabledRows: boolean[] = fuel.map(item => item.status === Status.INACTIVE);
        const tableContent: (string | JSX.Element)[][] = this.handleTransformToTableContent(fuel);
        return (
            <Fragment>
                <ContainerPage>
                    <ContainerTitle
                        titleText={t('points-per-liter.title')}
                        buttonContainer={
                            <Button
                                size="36px"
                                type="submit"
                                style={{ width: '183px', fontSize: '14px' }}
                                onClick={() => this.isFuelModalOpen(true, null)}
                            >
                                {t('points-per-liter.button')}
                            </Button>
                        }
                    />
                    <Container>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} style={{ marginTop: '30px' }}>
                                <SimpleOrderedTable
                                    rows={tableContent}
                                    isLoading={isLoading}
                                    page={this.state.page}
                                    disabledRows={disabledRows}
                                    columnNameKeys={this.tableHeaders}
                                    onChangePage={this.handleChangePage}
                                    onSort={(code: string) => this.handleSort(code)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ContainerPage>
                <FuelCreateOrEditModal
                    isOpen={isFuelModalOpen}
                    id={fuelModalId ?? undefined}
                    onSaveAction={this.getAllFuel}
                    onCancelAction={() => this.isFuelModalOpen(false, null)}
                />
            </Fragment>
        );
    }
}

export default withTranslation()(PointsPerLiter);
