import classNames from 'classnames';
import debounce from 'debounce-promise';
import { isEmpty } from 'lodash';
import React from 'react';
import { components } from 'react-select';
import { AsyncSelectResponse } from '../../model/async-select';
import StringUtils from '../../shared/util/string-utils';
import { AsyncSelectContainer, AsyncSelectStyles } from './StyledComponents';

interface IAsyncSelectFieldProps {
    error?: boolean;
    isMulti?: boolean;
    errorText?: string;
    className?: string;
    onBlur?: () => void;
    openOnTop?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    isClearable?: boolean;
    defaultOptions?: boolean;
    loadingMessage: string | null;
    defaultMessage: string | null;
    noOptionsMessage: string | null;
    onChange?: (event: any) => void;
    getOptions: (event: any) => void;
    value?: AsyncSelectResponse | AsyncSelectResponse[];
}

interface IAsyncSelectFieldState {
    isFocused: boolean;
    isMenuOpen: boolean;
}

class AsyncSelectField extends React.Component<IAsyncSelectFieldProps, IAsyncSelectFieldState>{
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
            isMenuOpen: false,
        };
    }

    isFocused = () => {
        this.setState({
            isFocused: !this.state.isFocused
        });
    };

    isMenuOpen = (isMenuOpen: boolean) => {
        this.setState({
            isMenuOpen
        });
    };

    onBlur = () => {
        this.isFocused();
        this.props.onBlur && this.props.onBlur();
    };

    onFocus = () => {
        this.isFocused();
    };

    renderDropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <div className="icon-dropdown" />
            </components.DropdownIndicator>
        );
    };

    render() {
        const { isFocused, isMenuOpen } = this.state;
        const {
            value, getOptions, isDisabled, isClearable, placeholder, loadingMessage, openOnTop,
            noOptionsMessage, className, onChange, errorText, error, defaultMessage, isMulti, defaultOptions } = this.props;

        const openOnTopClassName = openOnTop ? 'open-on-top' : '';
        const disabledClassName = isDisabled ? 'is-disabled' : '';
        const isMenuOpenClassName = isMenuOpen ? 'is-menu-open' : '';
        const errorClassName = error && !isFocused ? 'on-error' : '';

        const validClassName = isMulti
            ? (!isEmpty(value) && !isFocused ? 'on-valid' : '')
            : (value?.['value'] && !isFocused ? 'on-valid' : '');

        return (
            <AsyncSelectContainer className={className}>
                <AsyncSelectStyles
                    isMulti={isMulti}
                    onChange={onChange}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    placeholder={placeholder}
                    isClearable={isClearable || true}
                    loadOptions={debounce(getOptions, 1250)}
                    onMenuOpen={() => this.isMenuOpen(true)}
                    onMenuClose={() => this.isMenuOpen(false)}
                    defaultOptions={defaultOptions != null ? defaultOptions : true}
                    components={{ DropdownIndicator: this.renderDropdownIndicator }}
                    loadingMessage={(obj: { inputValue: string }) => loadingMessage}
                    value={!isMulti ? (value?.['value'] ? value : null) : (value || [])}
                    noOptionsMessage={(obj: { inputValue: string }) => StringUtils.isStringEmpty(obj.inputValue) ? defaultMessage : noOptionsMessage}
                    className={classNames(errorClassName, validClassName, isMenuOpenClassName, disabledClassName, openOnTopClassName, ['async-select-label'])}
                />
                {error && errorText != null && !isFocused && (
                    <div className={'error-label'}>
                        <span>{errorText}</span>
                    </div>
                )}
            </AsyncSelectContainer>
        );
    }
}

export default AsyncSelectField;
