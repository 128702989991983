
export enum GlobalModalActionTypes {
  OPEN_PAID_DISEASE_MODAL = '@@globalModal/OPEN_PAID_DISEASE_MODAL',
  CLOSE_PAID_DISEASE_MODAL = '@@globalModal/CLOSE_PAID_DISEASE_MODAL',
  OPEN_PAID_IMAGE_MODAL = '@@globalModal/OPEN_PAID_IMAGE_MODAL',
  CLOSE_PAID_IMAGE_MODAL = '@@globalModal/CLOSE_PAID_IMAGE_MODAL',
  OPEN_FEEDBACK_MODAL = '@@globalModal/OPEN_FEEDBACK_MODAL',
  CLOSE_FEEDBACK_MODAL = '@@globalModal/CLOSE_FEEDBACK_MODAL'
}

export interface GlobalModalState {
  paidDiseaseModalOpen: boolean;
  imageModal: ImageModalState;
  feedbackModal: boolean;
}

export interface ImageModalState {
  imageSrc?: string;
  isOpen?: boolean;
}