import * as React from 'react';
import { CommonLoading } from 'react-loadingg';
import './loading-container-screen.scss';

interface ILoadingContainerScreenProps {
    isLoading?: boolean;
    children: JSX.Element | JSX.Element[] | any;
}

export class LoadingContainerScreen extends React.Component<ILoadingContainerScreenProps> {
    render() {
        const { isLoading, children } = this.props;
        return isLoading ? (
            <div className={'loading-container-screen'}>
                <CommonLoading />
            </div>
        ) : (
            children
        );
    }
}

export default LoadingContainerScreen;
