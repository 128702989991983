import React from 'react';

export const TermsOfUse = () => {
    return (
        <div style={{ padding: '48px 16px' }}>
            <h3 style={{ paddingLeft: '18px', marginBottom: '24px', textAlign: 'center' }}>TERMOS DE USO APP AMIGÃO</h3>

            <ol>
                <li>
                    <strong>Aceitação dos Termos de Uso:</strong>
                    <p>
                        Ao baixar, instalar ou usar este aplicativo, você concorda em cumprir estes Termos de Uso. Se você não concordar com
                        estes termos, não use o aplicativo.
                    </p>
                </li>

                <li>
                    <strong>Uso do Aplicativo:</strong>
                    <p>
                        Você concorda em usar o aplicativo apenas para fins legais e de maneira consistente com estes Termos de Uso e com
                        todas as leis aplicáveis.
                    </p>
                </li>

                <li>
                    <strong>Conta do Usuário:</strong>
                    <p>
                        Para acessar determinadas funcionalidades do aplicativo, você pode precisar criar uma conta de usuário. Você é
                        responsável por manter a confidencialidade de suas informações de conta.
                    </p>
                </li>

                <li>
                    <strong>Privacidade:</strong>
                    <p>
                        O uso do aplicativo está sujeito à nossa Política de Privacidade que regula a coleta, uso e divulgação de
                        informações pessoais.
                    </p>
                </li>

                <li>
                    <strong>Propriedade Intelectual:</strong>
                    <p>
                        O conteúdo do aplicativo, incluindo textos, gráficos, logotipos, imagens e software, é protegido por direitos
                        autorais e outras leis de propriedade intelectual.
                    </p>
                </li>

                <li>
                    <strong>Limitação de Responsabilidade:</strong>
                    <p>
                        O aplicativo é fornecido "como está", sem garantias de qualquer tipo. Não nos responsabilizamos por danos diretos,
                        indiretos, incidentais, especiais, consequenciais ou punitivos decorrentes do uso ou impossibilidade de uso do
                        aplicativo.
                    </p>
                </li>

                <li>
                    <strong>Modificações nos Termos de Uso:</strong>
                    <p>
                        Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. As alterações entrarão em vigor após a
                        publicação dos termos revisados no aplicativo.
                    </p>
                </li>

                <li>
                    <strong>Encerramento do Acesso:</strong>
                    <p>Podemos encerrar ou suspender seu acesso ao aplicativo a qualquer momento, por qualquer motivo, sem aviso prévio.</p>
                </li>

                <li>
                    <strong>Lei Aplicável:</strong>
                    <p>
                        Estes Termos de Uso são regidos pelas leis do [inserir país ou região] e quaisquer litígios serão resolvidos nos
                        tribunais competentes desta jurisdição.
                    </p>
                </li>

                <li>
                    <strong>Contato:</strong>
                    <p>
                        Se você tiver dúvidas ou preocupações sobre estes Termos de Uso, entre em contato conosco em{' '}
                        <a href="mailto:contato@postoamigao.com.br">contato@postoamigao.com.br</a>.
                    </p>
                </li>
            </ol>
        </div>
    );
};
