import { Modal } from '@material-ui/core';
import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const SimpleAlertModalContainer = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SimpleAlertModalItems = styled.div`
    display: flex;
    width: 430px;
    padding: 30px 30px 42px 30px;
    min-height: 230px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background-color: white;
    justify-content: space-between;
    position: relative;

    &.is-loading {
        position: relative;
        padding-top: 120px;
        justify-content: flex-start;
    }

    .icon-fechar:before {
        font-size: 32px;
        color: ${Colors.lightNavy};
        left: 175px;
        bottom: 10px;
        position: relative;
        cursor: pointer;
    }
`;

export const LoadingText = styled.span`
    bottom: 45px;
    font-size: 16px;
    color: $dark-grey;
    text-align: center;
    position: absolute;
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;
export const BodyTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: black;
    text-align: center;
`;
export const BodyText = styled.div`
    font-size: 16px;
    color: #353738;
    line-height: 22px;
    text-align: center;
    font-weight: 500;
    margin-top: -10px;
`;
