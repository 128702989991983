export enum ConvenienceCategory {
    FOOD = 'FOOD',
    HYGIENE = 'HYGIENE',
    ENTERTAINMENT = 'ENTERTAINMENT',
    SERVICES = 'SERVICES',
    TRUCK = 'TRUCK',
    OTHERS = 'OTHERS',
    CLEANING = 'CLEANING',
    ACCESSORIES = 'ACCESSORIES',
    TOOLS = 'TOOLS',
    ELECTRONICS = 'ELECTRONICS'
}
