import { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import authenticationApi from '../../api/authentication-api';
import { AUTHORITIES } from '../../config/constants';
import { Authentication } from '../../model/authentication';
import { User } from '../../model/user';
import AuthUtils from '../../shared/util/auth-utils';
import {
  getSessionError,
  getSessionRequest,
  getSessionSuccess,
  loginError,
  loginRequest,
  loginSuccess
} from './actions';
import { AuthenticationActionTypes } from './types';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const result: AxiosResponse<Authentication> = yield call(authenticationApi.login, action.payload);

    if (result.status !== 200 && result.data.token != null) {
      const errorMessage: string = result.status === 401 ? 'badCredentials' : 'An unknown error occured.';
      yield put(loginError(errorMessage));
      return;
    }

    const jwt: string = result.data.token!;
    const decodeJwt: any = jwt_decode(jwt);

    const authorities = [AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.ESTABLISHMENT_ADMIN, AUTHORITIES.ESTABLISHMENT_USER];

    if (authorities.some(authority => decodeJwt.auth.includes(authority))) {
      AuthUtils.setToken(jwt);
      yield put(getSessionRequest());
      yield put(loginSuccess());
      return;
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(loginError(err.stack));
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}

function* handleGetSession() {
  try {
    const result: AxiosResponse<User> = yield call(authenticationApi.getSession);

    if (result.status === 200 && result.data != null) {
      yield put(getSessionSuccess(result.data));
    } else {
      yield put(getSessionError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getSessionError(err.stack));
    } else {
      yield put(getSessionError('An unknown error occured.'));
    }
  }
}

function* watchLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* watchGetSessionRequest() {
  yield takeEvery(AuthenticationActionTypes.GET_SESSION_REQUEST, handleGetSession);
}

function* authenticationSaga() {
  yield all([fork(watchLoginRequest), fork(watchGetSessionRequest)]);
}

export default authenticationSaga;
