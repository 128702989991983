import { AxiosResponse } from 'axios';
import { api } from './api';

class ForgotPasswordApi {

    static forgotPassword = (email: string): Promise<AxiosResponse<void>> => {
        return api.post<void>(`/account/reset-password/${email}/init`);
    };

}

export default ForgotPasswordApi;