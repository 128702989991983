import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import FuelApi from '../api/fuel-api';
import { Fuel } from '../model/fuel';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

class FuelService {
    static getAllFuels = async (predicate: Predicate, pageable: Pageable): Promise<PageableResponse<Fuel>> => {
        try {
            const result: AxiosResponse<PageableResponse<Fuel>> = await FuelApi.getAllFuels(predicate, pageable);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getAllFuels');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getAllFuels');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static getFuel = async (id: number): Promise<Fuel> => {
        try {
            const result: AxiosResponse<Fuel> = await FuelApi.getFuel(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getFuel');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getFuel');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static createFuel = async (fuel: Fuel): Promise<Fuel> => {
        try {
            const result: AxiosResponse<Fuel> = await FuelApi.createFuel(fuel);
            if (isResponseSuccess(result.data != null, result.status, StatusCodes.CREATED)) {
                ToastUtils.emitSuccessToast('createFuel');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('createFuel');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('createFuel');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static updateFuel = async (fuel: Fuel): Promise<Fuel> => {
        try {
            const result: AxiosResponse<Fuel> = await FuelApi.updateFuel(fuel);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('updateFuel');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('updateFuel');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('updateFuel');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static activateFuel = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await FuelApi.activateFuel(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('activateFuel');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('activateFuel');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('activateFuel');
            return Promise.reject(error.response as AxiosResponse);
        }
    };
}

export default FuelService;
