import { City } from './city';
import { State } from './state';

export interface Address {
    id?: number;
    city?: City;
    state?: State;
    street?: string;
    number?: string;
    zipcode?: string;
    district?: string;
    latitude?: string;
    cityName?: string;
    longitude?: string;
    stateName?: string;
    complement?: string;
}

export function convertToAddressLabel(address?: Address) {
    let result = '';
    if (address != null) {
        result += address.street != null ? `${address.street}, ` : '';
        result += address.number != null ? `${address.number} - ` : '';
        result += address.city != null ? address.city.name : '';
        result += address.city?.state != null ? `/${address.city.state.name}` : '';
        result += address.zipcode != null ? ` - Cep:   ${address.zipcode}` : '';
    }
    return result;
}
