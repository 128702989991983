import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Col,  Row } from 'reactstrap';
import { User } from '../../../model/user';
import UserService from '../../../services/user-service';
import { CustomTextFieldProfile, DataContainer, SubTitle } from '../StyledComponents';

interface IMyDataProps extends RouteComponentProps, WithTranslation {
    // TODO
}

interface IMyDataState {
    myData?: User;
    isLoading: boolean;
}

class MyData extends React.Component<IMyDataProps, IMyDataState> {
    $defaultPath: string;
    constructor(props: Readonly<IMyDataProps>) {
        super(props);
        this.$defaultPath = '';
        this.state = {
            myData: {},
            isLoading: true
        };
    }

    componentDidMount() {
        this.isLoading(true);

        UserService.getMyData().then(result =>
            this.setState({ myData: result })
        );
    }


    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    render() {
        const { t } = this.props;
        const { myData } = this.state;

        return (
            <Col style={{ paddingLeft: '35px' }} md={{ size: '8' }}>
                <DataContainer>{t('profile.my-profile.data')}</DataContainer>
                <CustomTextFieldProfile
                    id={t('profile.textField.name')}
                    placeholder={t('profile.textField.name')}
                    value={myData?.name}
                    isDisabled
                />
                <CustomTextFieldProfile
                    id={t('profile.textField.cpf')}
                    placeholder={t('profile.textField.cpf')}
                    value={myData?.cpf}
                    isDisabled
                />
                <CustomTextFieldProfile
                    id={t('profile.textField.email')}
                    placeholder={t('profile.textField.email')}
                    value={myData?.email}
                    isDisabled
                />
                <CustomTextFieldProfile
                    id={t('profile.textField.phone')}
                    placeholder={t('profile.textField.phone')}
                    value={myData?.phone}
                    isDisabled
                />
                <SubTitle>{t('profile.my-profile.address')}</SubTitle>
                <Row>
                    <Col md="7" style={{ paddingRight: '3px' }}>
                        <CustomTextFieldProfile
                            id={t('profile.textField.address')}
                            placeholder={t('profile.textField.address')}
                            value={myData?.address?.street}
                            isDisabled
                        />
                    </Col>
                    <Col md="5" style={{ paddingLeft: '3px' }}>
                        <CustomTextFieldProfile
                            id={t('profile.textField.complement')}
                            placeholder={t('profile.textField.complement')}
                            value={myData?.address?.complement}
                            isDisabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="6" style={{ paddingRight: '3px' }}>
                        <CustomTextFieldProfile
                            id={t('profile.textField.city')}
                            placeholder={t('profile.textField.city')}
                            value={myData?.address?.city}
                            isDisabled
                        />
                    </Col>
                    <Col md="3" style={{ paddingRight: '3px', paddingLeft: '3px' }}>
                        <CustomTextFieldProfile
                            id={t('profile.textField.state')}
                            placeholder={t('profile.textField.state')}
                            value={myData?.address?.state}
                            isDisabled
                        />
                    </Col>
                    <Col md="3" style={{ paddingLeft: '3px' }}>
                        <CustomTextFieldProfile
                            id={t('profile.textField.cep')}
                            placeholder={t('profile.textField.cep')}
                            value={myData?.address?.zipcode}
                            isDisabled
                        />
                    </Col>
                </Row>
            </Col>
        );
    }
}

export default withTranslation()(MyData) as any;