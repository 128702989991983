import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

function propsForCardContainer(props) {
    const defaultStyle = {
        borderColor: Colors.sunflowerYellow,
        background: 'white',
        borderLeft: Colors.sunflowerYellow,
    };
    let additionalStyle:any = {};
    if (props.disabled) {
        additionalStyle = {
            borderColor: 'transparent',
            borderLeft: '#9199a1',
            background: '#EEEEEE',

        }
    }
    if (props.hasNew) {
        additionalStyle = {
            borderColor: Colors.colorSuccessStyle,
            borderLeft: Colors.colorSuccessStyle,
        }
    }
    if (props.isError) {
        additionalStyle = {
            borderColor: Colors.coral,
            borderLeft: Colors.coral,
        }
    }
    if (props.isActive) {
        additionalStyle = {
            borderColor: Colors.darkSkyBlue,
            borderLeft: Colors.darkSkyBlue,
        }
    }
    return { ...defaultStyle, ...additionalStyle };
}

export const CardContainer = styled.div.attrs(propsForCardContainer)`
  height: 61px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: ${props => props.background};
  border: solid 1px ${props => props.borderColor};
  border-left: solid 3px ${props => props.borderLeft};
  padding: 12px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 2px 4px 0 ${Colors.brownGrey};
  }
`;

export const Icon = styled.div`
  width: 34px;
  max-width: 34px;
  min-width: 34px;
  height: 34px;
  max-height: 34px;
  min-height: 34px;
  font-size: 19px;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  border-color: ${props => (props.disabled ? '#9199a1' : '#fdd00f')};

  div:before {
    color: ${Colors.lightNavy};
    color: ${props => (props.disabled ? '#808080' : '#143d66')};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex:1 1 100%;
  width: auto;
  overflow: hidden;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const Points = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: -3px;
  color: ${props => (props.disabled ? '#808080' : '#1e7ac9')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 190px;
`;
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;

  .icon-deletar {
    cursor: pointer;
  }

  .icon-delete_rounded:before {
    margin-right: 10px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  .icon-editar:before {
    margin-right: 10px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
