import { Table, TableRow } from '@material-ui/core';
import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const StyledTableRow = styled(TableRow)`
    .MuiTableCell-head {
        border-top: none;
        color: ${Colors.greyishBrown};
        font-weight: 500;
        line-height: 1.64;
        font-size: 0.6875rem;
        vertical-align: middle;
        padding: 4px 0 4px 15px;
    }
    .MuiTableCell-head:nth-last-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        border: none;
    }
`;

export const StyledTable = styled(Table)`
    min-width: 90vh;
    border-spacing: 0 10px;
    border-collapse: separate;
    margin-bottom: 20px;

    .MuiTableHead-root {
        border-bottom: solid 2px #1478cc;
    }

    .MuiTableBody-root:before {
        content: '-';
        display: block;
        line-height: 1em;
        color: transparent;
    }

    .custom-toggle-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiTableBody-root {
        border-bottom: unset;
        width: 100%;
        tr {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
            height: 55px;
            border-radius: 8px;
        }
        tr:hover {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
        }
        tr:active {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        }
        .MuiTableCell-root {
            color: #6e7475;
            font-size: 14px;
            min-height: 55px;
            max-width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            vertical-align: middle;
            word-break: break-word;
            padding: 10px 10px 10px 15px;
            border: unset;
            cursor: pointer;
        }
    }
`;

export const StyledPagination = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    justify-content: flex-end;

    .MuiPagination-root {
        .MuiPagination-ul {
            li:first-child {
                order: 2;
                margin-left: 24px;
                margin-right: -7px;
                .MuiPaginationItem-outlined {
                    border: solid 1px #1478cc;
                    background: white;
                }
                .MuiPaginationItem-rounded {
                    border-radius: 4px 0 0 4px;
                }
            }
            li:nth-child(2) {
                order: 1;
                .MuiPaginationItem-outlined {
                    border: solid 1px #bcbcbc;
                    background: white;
                }
                .MuiPaginationItem-root {
                    height: 29px;
                }
                .input-style {
                    width: 46px;
                    height: 26px;
                    border-radius: 6px;
                    border: solid 1px #bcbcbc;
                    background-color: #ffffff;
                    text-align: center;
                    margin-left: 10px;
                    margin-right: 10px;
                    font-size: 0.875rem;
                    color: #333333;
                }
                .text-style {
                    font-size: 0.875rem;
                    color: #333333;
                }
            }
            li:last-child {
                order: 3;
                .MuiPaginationItem-outlined {
                    border: solid 1px #1478cc;
                    background: white;
                }
                .MuiPaginationItem-rounded {
                    border-radius: 0 4px 4px 0;
                }
            }
            li {
                .MuiSvgIcon-root {
                    fill: #1478cc;
                    stroke-width: -10px;
                    stroke: white;
                    @include itemSize(24px, 24px);
                }
                .MuiPaginationItem-root {
                    min-width: 29px;
                    height: 29px;
                }
            }
        }
    }
`;

export const StyledText = styled.span`
    font-size: 0.875rem;
    color: #333333;
`;

export const StyledInput = styled.input`
    width: 46px;
    height: 26px;
    border-radius: 6px;
    border: solid 1px #bcbcbc;
    background-color: #ffffff;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 0.875rem;
    color: #333333;
`;
