import { AxiosResponse } from 'axios';
import { Fuel } from '../model/fuel';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { requestParamsFormatter } from '../shared/util/api-utils';
import { api } from './api';

class FuelApi {
    static getAllFuels = (predicate: Predicate, page: Pageable): Promise<AxiosResponse<PageableResponse<Fuel>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(predicate, page);
        return api.get<PageableResponse<Fuel>>(`/fuels?&sort=status,asc&${pageParams}&${filterParams}`);
    };

    static getFuel = (id: number): Promise<AxiosResponse<Fuel>> => {
        return api.get<Fuel>(`/fuels/${id}`);
    };

    static createFuel = (fuel: Fuel): Promise<AxiosResponse<Fuel>> => {
        return api.post<Fuel>('/fuels', fuel);
    };

    static updateFuel = (fuel: Fuel): Promise<AxiosResponse<Fuel>> => {
        return api.put<Fuel>('/fuels', fuel);
    };

    static activateFuel = (id: number): Promise<AxiosResponse<void>> => {
        return api.patch<void>(`/fuels/activate/${id}`);
    };
}

export default FuelApi;
