import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import LoadingContainerScreen from '../../../components/loading-container-screen/loading-container-screen';
import { ButtonContainer } from '../../../components/simple-alert-modal/StyledComponents';
import { Fuel } from '../../../model/fuel';
import { defaultValue, ErrorAndMessage, Validation } from '../../../model/validation';
import FuelService from '../../../services/fuel-service';
import StringUtils from '../../../shared/util/string-utils';
import { entityValidator } from '../../../shared/util/validator-utils';
import {
    ButtonLeft,
    ButtonRight,
    CustomTextFieldMargin
} from '../../establishment/StyledComponents';
import './fuel-create-or-edit-modal.scss';

interface IFuelCreateOrEditModalProps extends WithTranslation {
    id?: number;
    isOpen?: boolean;
    onSaveAction: () => void;
    onCancelAction: () => void;
}

interface IFuelCreateOrEditModalState {
    fuel: Fuel;
    isSaving: boolean;
    isLoading: boolean;
    fuelValidation: {
        fuelName: Validation[];
        pointsPerLiter: Validation[];
    };
    fuelError: {
        fuelName: ErrorAndMessage;
        pointsPerLiter: ErrorAndMessage;
    };
}

class FuelCreateOrEditModal extends React.Component<IFuelCreateOrEditModalProps, IFuelCreateOrEditModalState> {
    $defaultPath: string;

    constructor(props) {
        super(props);
        this.state = {
            fuel: {},
            isSaving: false,
            isLoading: false,
            fuelValidation: {
                fuelName: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.fuelType') }],
                pointsPerLiter: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.pointsPerLiter') }],
            },
            fuelError: {
                fuelName: defaultValue,
                pointsPerLiter: defaultValue
            }
        };
        this.$defaultPath = '';
    }

    componentWillReceiveProps(nextProps: Readonly<IFuelCreateOrEditModalProps>) {
        if (nextProps.id != null && (nextProps.id !== this.props.id)) this.getFuel(nextProps.id);
    }

    closeAndReset = async () => {
        await this.resetAll();
        this.props.onCancelAction();
    };

    resetAll = async () => {
        this.setState({
            fuel: {},
            fuelError: {
                fuelName: defaultValue,
                pointsPerLiter: defaultValue
            }
        });
    };

    getFuel = (id: number) => {
        this.isLoading(true);
        FuelService.getFuel(id)
            .then(result => {
                this.setState({
                    fuel: result
                }, () => this.isLoading(false));
            })
            .catch(() => {
                this.isLoading(false);
                this.closeAndReset().then(callback => callback);
            })
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    isSaving = (isSaving: boolean) => {
        this.setState({
            isSaving
        });
    };

    validateLabel = (key: string) => {
        const { fuelValidation } = this.state;
        this.setState({
            fuelError: {
                ...this.state.fuelError,
                [key]: entityValidator(this.getValidationEvents(key), fuelValidation[key])
            }
        });
    };

    fuelAction = () => {
        if (this.state.fuel.id != null) return this.updateFuel();
        return this.createFuel();
    };

    updateFuel = () => {
        this.isSaving(true);
        FuelService.updateFuel(this.state.fuel)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };

    createFuel = () => {
        this.isSaving(true);
        FuelService.createFuel(this.state.fuel)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };

    globalValidateLabels = () => {
        const newFuelError = { ...this.state.fuelError };
        const { fuelValidation } = this.state;

        Object.keys(fuelValidation).forEach(key =>
            newFuelError[key] = entityValidator(this.getValidationEvents(key), fuelValidation[key]));

        this.setState({
            fuelError: newFuelError
        }, () => {
            if (this.findAnyError()) return;
            this.fuelAction();
        });
    };

    findAnyError = () => {
        return Object.keys(this.state.fuelError).filter(key => this.state.fuelError[key]?.value).length > 0;
    };

    getValidationEvents = (key: string) => {
        const { fuel } = this.state;
        switch (key) {
            case 'fuelName':
                return [[fuel.fuelName]];

            case 'pointsPerLiter':
                return [[fuel.pointsPerLiter]];

            default:
                return [[false]]
        }
    };

    updateLabel = (event: any, key: string) => {
        this.setState({
            fuel: {
                ...this.state.fuel,
                [key]: event
            }
        });
    };

    render() {
        const { t, isOpen } = this.props;
        const { fuel, isLoading, isSaving, fuelError } = this.state;
        const backdropProps = { timeout: 500 };
        const fuelClassName = isLoading ? 'is-loading' : '';
        const onCloseAction = !isLoading && !isSaving ? this.closeAndReset : void(0);
        return (
            <Modal
                closeAfterTransition
                open={isOpen || false}
                onClose={onCloseAction}
                BackdropComponent={Backdrop}
                BackdropProps={backdropProps}
                className={'fuel-create-or-edit-wrapper'}
            >
                <Fade in={isOpen}>
                    <div className={classNames([fuelClassName, 'fuel-create-or-edit-wrap'])}>
                        <LoadingContainerScreen isLoading={isLoading}>
                            <div className="icon-fechar" onClick={onCloseAction} />
                            <span className={'body-title'}>
                                {t(fuel.id != null ? 'fuel.editModal' : 'fuel.newModal')}
                            </span>
                            <div className={'middle-items'}>
                                <CustomTextFieldMargin
                                    className={'pointsPerLiter'}
                                    id={StringUtils.randomString()}
                                    error={fuelError.fuelName?.value}
                                    errorText={fuelError.fuelName?.message}
                                    placeholder={t('fuel.textField.fuelType')}
                                    onBlur={() => this.validateLabel('fuelName')}
                                    onChange={event => this.updateLabel(event, 'fuelName')}
                                    value={fuel?.fuelName}
                                />
                                <CustomTextFieldMargin
                                    isOnlyNumbers
                                    className={'pointsPerLiter'}
                                    id={StringUtils.randomString()}
                                    error={fuelError.pointsPerLiter?.value}
                                    errorText={fuelError.pointsPerLiter?.message}
                                    placeholder={t('fuel.textField.pointsPerLiter')}
                                    onBlur={() => this.validateLabel('pointsPerLiter')}
                                    onChange={event => this.updateLabel(!StringUtils.isStringEmpty(event) ? Number(event) : null, 'pointsPerLiter')}
                                    value={fuel.pointsPerLiter != null ? StringUtils.formatCurrencyAndNumber(fuel.pointsPerLiter, true, 0) : undefined}
                                />
                            </div>
                            <ButtonContainer>
                                <ButtonLeft onClick={onCloseAction} disabled={isSaving} color="white" type="submit">
                                    {t('global.button.cancel')}
                                </ButtonLeft>
                                <ButtonRight onClick={this.globalValidateLabels} isLoading={isSaving} type="submit">
                                    {t('global.button.save')}
                                </ButtonRight>
                            </ButtonContainer>
                        </LoadingContainerScreen>
                    </div>
                </Fade>
            </Modal>
        );
    }
}

export default withTranslation()(FuelCreateOrEditModal);
