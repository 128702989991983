export const maskCnpj = (value: string) => {
  return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskCPF = (text: string) => {
  if (text === undefined) return text;
  return text
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskNumber = (value: string) => {
  return value.replace(/\D/g, '');
};

export const maskAlphaNumeric = (value: string) => {
  return value.replace(/[^a-zA-Z0-9a-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]/, '');
};

export const maskOnlyLetters = (value: string) => {
  return value.replace(/[^a-zA-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/, '');
};

export const maskPhone = (value: string) => {
  if (value === undefined) return value;
  if (unmask(value).length > 11) return value.slice(0, value.length - 1);

  let phone = value;

  phone = phone
      .replace(/\D/g, '')
      .replace(/(\d)/, '($1')
      .replace(/(.{2})(\d)/, '$1$2')
      .replace(/(.{3})(\d)/, '$1) $2');

  if (unmask(phone).length < 11) {
    phone = phone.replace(/(.{9})(\d)/, '$1-$2');
  } else {
    phone = phone.replace(/(.{10})(\d)/, '$1-$2');
  }

  return phone;
};

export const maskZipCode = (value: string) => {
  if (unmask(value).length > 8) return value.slice(0, value.length - 1);
  return value.replace(/\D/g, '').replace(/(.{5})(\d)/, '$1-$2');
};

export const unmask = (value: string) => {
  if (value === undefined) return value;
  return value.replace(/\D/g, '');
};
