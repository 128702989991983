import styled from 'styled-components/macro';

export const Title = styled.div`
    width: 100%;
    height: 65px;
    border-bottom: solid 1px #dadbdd;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TitleText = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: black;
`;

export const ContainerItems = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
`;
export const ButtonContainer = styled.div`
    margin: 0;
    width: auto;
`;