import { AxiosResponse } from 'axios';
import { api } from './api';

class AccountApi {
    static activateAccount = (data): Promise<AxiosResponse<void>> => {
        return api.post<void>('/account/activate', data);
    };

    static resetPassword = (data): Promise<AxiosResponse<void>> => {
        return api.post<void>('/account/reset-password/finish', data);
    };
}

export default AccountApi;
