import React, { Fragment } from 'react';
import classNames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/button/button';
import ContainerTitleBack from '../../components/container-title-back/container-title-back';
import SimpleOrderedTable, { ColumnSort } from '../../components/simple-ordered-table/simple-ordered-table';
import { Status } from '../../model/enums/status';
import { Pageable } from '../../model/pageable';
import ContainerPage from '../../styled-components/ContainerPage';
import { DataContainer, SideBarStyle } from './StyledComponents';
import UserCreateOrEditModal from '../establishment/item/user/user-create-or-edit-modal/user-create-or-edit-modal';
import UserService from '../../services/user-service';
import { isEmpty } from 'lodash';
import { User } from '../../model/user';
import MyData from './my-data/my-data';
import { Predicate } from '../../model/predicate';
import { UserType } from '../../model/enums/user-type';
import { maskPhone } from '../../shared/util/mask-utils';

interface IProfileProps extends RouteComponentProps, WithTranslation {
    // TODO
}
interface IProfileState {
    user?: User[];
    myData?: User;
    page: Pageable;
    isLoading: boolean;
    activePage: string;
    predicate: Predicate;
    isUserModalOpen: boolean;
    userModalId: number | null;
    alertModalRoutesList: string[];
}
class Profile extends React.Component<IProfileProps, IProfileState> {
    $defaultPath: string;
    constructor(props: Readonly<IProfileProps>, context?: any) {
        super(props);
        this.$defaultPath = '';
        this.state = {            
            myData: {},
            user: [{}],
            predicate: {},
            isLoading: true,
            userModalId: null,
            activePage: 'my-data',
            isUserModalOpen: false,
            alertModalRoutesList: ['/new', '/edit'],
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'firstName,asc' }
        };
    }

    componentDidMount() {
        this.isLoading(true);

        UserService.getMyData().then(result =>
            this.setState({ myData: result })
        );

        this.getAllUsers();
    }

    getAllUsers = async (shouldResetPage?: boolean) => {
        if (shouldResetPage) this.resetPage();

        const { predicate, page } = this.state;

        this.isLoading(true);

        UserService.getAllUsers(predicate, page).then(result =>
            this.setState(
                {
                    user: result.content,
                    page: {
                        ...this.state.page,
                        size: result.size,
                        page: result.number,
                        totalPages: result.totalPages,
                        totalElements: result.totalElements
                    }
                },
                async () => {
                    this.isLoading(false);
                    if (isEmpty(this.state.user) && this.state.page.page) {
                        await this.updatePage('page', this.state.page.page - 1);
                        await this.getAllUsers();
                    }
                }
            )
        );
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    updatePage = async (key: string, event: number | string) => {
        this.setState({
            page: {
                ...this.state.page,
                [key]: event
            }
        });
    };

    resetPage = () => {
        this.setState({
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'name,asc' }
        });
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: { ...this.state.page, page: newPage }
            },
            () => this.getAllUsers()
        );
    };

    isUserModalOpen = (isUserModalOpen: boolean, userModalId: number | null) => {
        this.setState({
            isUserModalOpen,
            userModalId
        });
    };

    private handleClickRow = (id: number) => {
        // const it = this.state?.user[id]!;
        // this.props.history.push(`/admin/resales/${it.id}/edit`);
    };

    private handleSort = (code: string) => {
        this.setState(
            {
                page: { ...this.state.page, sort: code }
            },
            () => this.getAllUsers()
        );
    };

    updateItem = (user: User) => {
        user.status = user.status === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE;
    };

    renderContacts = (item: User) => {
        const { t } = this.props;
        return (
            <div className={'column-list-item is-contacts'}>
                <span>{item?.customer?.phone ? maskPhone(item.customer?.phone) : t('global.emptyLabel')}</span>
                <span>{item.email || t('global.emptyLabel')}</span>
            </div>
        );
    };

    renderStatus = (status: Status) => {
        const { t } = this.props;
        const statusClassName = status === Status.ACTIVE ? 'is-active' : 'is-inactive';
        return (
            <div className={classNames([statusClassName, 'status-list-item'])}>
                <span>
                    {t(`enum.status.${status}`)}
                </span>
            </div>
        );
    };

    activateUser = (id: number) => {
        this.isLoading(true);
        UserService.activateUser(id)
            .then(() => {
                this.getAllUsers().then(() => {
                    this.isLoading(false);
                });
            })
            .catch(() => this.isLoading(false));
    };


    tableHeaders: ColumnSort[] = [
        { name: this.props.t('profile.table.name'), sortCode: 'name' },
        { name: this.props.t('profile.table.position'), sortCode: 'position' },
        { name: this.props.t('profile.table.contact'), sortCode: 'contact' },
        { name: this.props.t('profile.table.status'), sortCode: 'status' },
        { sortDisabled: true }
    ];


    handleTransformToTableContent = (content?: User[]): (string | JSX.Element)[][] => {
        if (content == null || content.length === 0) return [];
        const result: (string | JSX.Element)[][] = [];
        content.forEach((item: User, index: number) => {
            result.push([
                item?.customer?.name ?? 'name',
                item.authorities && item.authorities[0] === UserType.ADMIN ? 'Administrador' : 'Usuário',
                this.renderContacts(item),
                this.renderStatus(item.status || Status.INACTIVE),
                <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.status === Status.INACTIVE ? (
                        <i style={{ opacity: 0.5, marginRight: '17px' }} className="icon-editar" />
                    ) : (
                            <i
                                style={{ marginRight: '17px', cursor: 'pointer' }}
                                className="icon-editar"
                                onClick={() => this.isUserModalOpen(true, item?.id!)}
                            />
                        )}
                    <div
                        style={{ cursor: 'pointer' }}
                        className={item.status === Status.ACTIVE ? 'icon-deletar' : 'icon-deletar-reverter'}
                        onClick={() => this.activateUser(item?.id!)}
                    />
                </div>
            ]);
        });
        return result;
    };

    render() {
        const { t } = this.props;
        const { user, activePage, isUserModalOpen, userModalId,} = this.state;
        const disabledRows: boolean[] = user?.map(item => item.status === Status.INACTIVE)!;
        const tableContent: (string | JSX.Element)[][] = this.handleTransformToTableContent(user);

        return (
            <Fragment>
                <>
                    <ContainerPage>
                        <ContainerTitleBack titleText={t('profile.title')}>
                            {activePage === 'user-management' && (
                                <Button style={{ width: '184px', fontSize: '14px' }} onClick={() => this.isUserModalOpen(true, null)} size="37px">
                                    {t('profile.users.newUser')}
                                </Button>
                            )}
                        </ContainerTitleBack>
                        <Container>
                            <Row style={{ marginTop: '20px' }}>
                                <Col md="2">
                                    <SideBarStyle
                                        data-active={activePage === 'my-data'}
                                        onClick={() => this.setState({ activePage: 'my-data' })}
                                    >
                                        {t('profile.my-profile.title')}
                                    </SideBarStyle>
                                    <SideBarStyle
                                        data-active={activePage === 'user-management'}
                                        onClick={() => this.setState({ activePage: 'user-management' })}
                                    >
                                        {t('profile.users.title')}
                                    </SideBarStyle>
                                    <SideBarStyle onClick={() => this.props.history.replace('/logout')}>
                                        {t('profile.logout')}
                                    </SideBarStyle>
                                </Col>
                                {activePage === 'my-data' ? (
                                    <MyData />
                                ) : (
                                        <Col style={{ paddingLeft: '35px' }} md={{ size: '10' }}>
                                            <DataContainer>{t('profile.users.titleSecond')}</DataContainer>
                                            <SimpleOrderedTable
                                                rows={tableContent}
                                                page={this.state.page}
                                                disabledRows={disabledRows}
                                                columnNameKeys={this.tableHeaders}
                                                onChangePage={this.handleChangePage}
                                                onSort={(code: string) => this.handleSort(code)}
                                            />

                                        </Col>
                                    )}
                            </Row>
                        </Container>
                        <UserCreateOrEditModal
                            isAdmin
                            isOpen={isUserModalOpen}
                            id={userModalId ?? undefined}
                            onSaveAction={this.getAllUsers}
                            onCancelAction={() => this.isUserModalOpen(false, null)}
                        />
                    </ContainerPage>
                </>
            </Fragment>
        );
    }
}

export default withTranslation()(Profile);
