import React from 'react';
import StringUtils from '../../shared/util/string-utils';
import { ActionContainer, CardContainer, Icon, Points, TextContainer, Title } from './StyledComponents';

interface Props {
    t?: any;
    icon?: any;
    title?: string;
    points?: number;
    hasNew?: boolean;
    isError?: boolean;
    isActive?: boolean;
    disabled?: boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
    onHandleToggle?: () => void;
}

export class ConvenienceCard extends React.Component<Props> {
    render() {
        const { icon, title, points, hasNew, disabled, isError, isActive, onDeleteClick, onEditClick, onHandleToggle } = this.props;
        return (
            <CardContainer disabled={disabled} hasNew={hasNew} isError={isError} isActive={isActive}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Icon disabled={disabled}>{icon}</Icon>
                    <TextContainer>
                        <Title>{title}</Title>
                        <Points disabled={disabled}>
                                <span>
                                    {StringUtils.formatCurrencyAndNumber(points || 0, true, 0)}
                                    {' pontos'}
                                </span>
                        </Points>
                    </TextContainer>
                    <ActionContainer disabled={disabled}>
                        <div className="icon-delete_rounded" onClick={onDeleteClick} />
                        <div className="icon-editar" onClick={onEditClick} />
                        <div className="icon-deletar" onClick={onHandleToggle} />
                    </ActionContainer>
                </div>
            </CardContainer>
        );
    }
}

export default ConvenienceCard;
