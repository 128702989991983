import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AuthUtils from '../../shared/util/auth-utils';
import {
    HeaderBody,
    HeaderContainer,
    LinkContainer,
    LinkText,
    LogoHeader,
    StyledSpan,
    UserContainer
} from './StyledComponents';

export interface HeaderProps {
    t: any;
    name?: string;
}
const Header = (props: HeaderProps): JSX.Element => {
    const parentHeight = { height: '58px' };
    return (
        <HeaderBody style={parentHeight}>
            <Container style={parentHeight}>
                <Row style={parentHeight}>
                    <Col style={parentHeight}>
                        <HeaderContainer>
                            <Link to="/">
                                <LogoHeader />
                            </Link>
                            {AuthUtils.isFgs() && (
                                <LinkContainer>
                                    <NavLink to="/establishment">
                                        <LinkText>{props.t('header.account.establishment')}</LinkText>
                                    </NavLink>
                                    <NavLink to="/points-per-liter">
                                        <LinkText>{props.t('header.account.points')}</LinkText>
                                    </NavLink>
                                </LinkContainer>
                            )} 
                            {AuthUtils.isFgs() ? (
                                <NavLink to="/profile">
                                    <UserContainer>
                                    <span>
                                        {/* tslint:disable-next-line:jsx-key */}
                                        <Trans i18nKey="header.hello" values={{ person: `${props.name || ''}!` }} components={[<StyledSpan />]} />
                                        &nbsp;
                                    </span>
                                        <div className="icon-perfil" />
                                    </UserContainer>
                                </NavLink>
                            ) : (
                                <NavLink to="/logout">
                                    <UserContainer>
                                    <span>
                                        {/* tslint:disable-next-line:jsx-key */}
                                        <Trans i18nKey="header.logout" components={[<StyledSpan />]} />
                                        &nbsp;
                                    </span>
                                    </UserContainer>
                                </NavLink>
                            )}
                        </HeaderContainer>
                    </Col>
                </Row>
            </Container>
        </HeaderBody>
    );
};

export default withTranslation()(Header) as React.ComponentType<any>;
