import jwt_decode from 'jwt-decode';
import { AUTHORITIES } from '../../config/constants';

class AuthUtils {
    static AUTH_TOKEN_KEY = 'jhi-authenticationToken';

    static isAuthenticated = () => {
        return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY) !== null;
    }

    static isUser = () => {
        if (!AuthUtils.isAuthenticated()) return false;
        // @ts-ignore
        return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.USER);
    }

    static isAdmin = () => {
        if (!AuthUtils.isAuthenticated()) return false;
        // @ts-ignore
        return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.ADMIN);
    }

    static isEstablishmentUser = () => {
        if (!AuthUtils.isAuthenticated()) return false;
        // @ts-ignore
        return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.ESTABLISHMENT_USER);
    }

    static isEstablishmentAdmin = () => {
        if (!AuthUtils.isAuthenticated()) return false;
        // @ts-ignore
        return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.ESTABLISHMENT_ADMIN);
    }

    static isFgs = () => {
        return AuthUtils.isAdmin() || AuthUtils.isUser();
    }

    static isEstablishment = () => {
        return AuthUtils.isEstablishmentAdmin() || AuthUtils.isEstablishmentUser();
    }

    static getToken = () =>  {
        return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY);
    }

    static setToken = (token: string) => {
        localStorage.setItem(AuthUtils.AUTH_TOKEN_KEY, token);
    }

    static removeToken = () => {
        localStorage.removeItem(AuthUtils.AUTH_TOKEN_KEY);
    }

    static getMainPath = () => {
        if (AuthUtils.isFgs()) return '/establishment';
        if (AuthUtils.isEstablishment()) return '/my-establishment/convenience';
        return '/logout';
    }
}

export default AuthUtils;
