import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import authenticationApi from '../api/authentication-api';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

export const AuthenticationService = () => {
    const finishPasswordReset = async (key: string, password: string): Promise<boolean> => {
        try {
            const result: AxiosResponse<void> = await authenticationApi.finishPasswordReset(key, password);
            if (result.status === StatusCodes.OK) {
                return Promise.resolve(true);
            }
            return Promise.reject({ status: 400 } as AxiosResponse);
        } catch (error) {
            console.error(error);
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    const requestPasswordReset = async (email: string): Promise<boolean> => {
        try {
            const result: AxiosResponse<void> = await authenticationApi.requestPasswordReset(email);
            if (result.status === StatusCodes.OK) {
                return Promise.resolve(true);
            }
            return Promise.reject({ status: result.status } as AxiosResponse);
        } catch (error) {
            console.error(error);
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    const checkIfEmailExists = async (email: string, checkIfIsAvailable: boolean): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await authenticationApi.checkIfEmailExists(email, checkIfIsAvailable);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('checkIfEmailExists');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('checkIfEmailExists');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    return {
        checkIfEmailExists,
        finishPasswordReset,
        requestPasswordReset
    }
}


export default AuthenticationService();
