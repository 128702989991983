import { AxiosResponse } from 'axios';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { User } from '../model/user';
import { requestParamsFormatter } from '../shared/util/api-utils';
import { api } from './api';

class UserApi {
    static getAllUsers = (predicate: Predicate, page: Pageable, establishmentId?: number): Promise<AxiosResponse<PageableResponse<User>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(predicate, page);
        return api.get<PageableResponse<User>>(`/users/admin?establishmentId=${establishmentId ?? ''}&${pageParams}&${filterParams}`);
    };

    static getUser = (id: number): Promise<AxiosResponse<User>> => {
        return api.get<User>(`/users/admin/${id}`);
    };

    static createUser = (user: User, establishmentId?: number): Promise<AxiosResponse<User>> => {
        return api.post<User>(`/users/admin?establishmentId=${establishmentId ?? ''}`, user);
    };

    static updateUser = (user: User, establishmentId?: number): Promise<AxiosResponse<User>> => {
        return api.put<User>(`/users/admin?establishmentId=${establishmentId ?? ''}`, user);
    };

    static deleteUser = (id: number): Promise<AxiosResponse<void>> => {
        return api.delete<void>(`/users/${id}`);
    };

    static activateUser = (id: number): Promise<AxiosResponse<void>> => {
        return api.patch<void>(`/users/activate/${id}`);
    };

    static getMyData = (): Promise<AxiosResponse<User>> => {
        return api.get<User>('/users/me');
    };
}

export default UserApi;