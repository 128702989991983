import { InputAdornment, TextField } from '@material-ui/core';
import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const InputAdornmentStyled = styled(InputAdornment)`
    .icon-sucesso_rounded:before {
        font-size: 18px;
        color: ${Colors.colorSuccessStyle};
    }
`;

export const TextFieldStyled = styled(TextField)`


    .Mui-error {
        .MuiInputBase-input {
            border-top: 2px solid red;
            background: white;
        }
    }

    &:not(.Mui-error).validate.filled {
        .MuiFilledInput-root {
            border-top: 2px solid ${Colors.colorSuccessStyle};
            background: white !important;
            &:focus {
                background: white;
            }
        }
    }

    &:focus,
    .Mui-focused {
        .MuiInputBase-input {
            background-color: white;
        }
    }

    .MuiFilledInput-root.filled.validate {
        background: white;
    }

    .MuiFilledInput-root {
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        background-color: #f6f6f6 !important;
        transition: none !important;
    }

    .MuiInputBase-input:focus {
        background-color: white;
    }

    .MuiFilledInput-underline:before,
    &.MuiFilledInput-underline:before {
        border-bottom: none !important;
    }

    .MuiFilledInput-underline:after {
        display: none;
    }

    .MuiSelect-select:focus {
        background-color: #f7f9fa;
        border-radius: 4px;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${Colors.blueyGrey};
    }
    
`;
