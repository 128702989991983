import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import ConvenienceApi from '../api/convenience-api';
import { Convenience } from '../model/convenience';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

class ConvenienceService {

    static getAllConveniences = async (predicate: Predicate, pageable: Pageable, establishmentId?: number): Promise<PageableResponse<Convenience>> => {
        try {
            const result: AxiosResponse<PageableResponse<Convenience>> = await ConvenienceApi.getAllConveniences(predicate, pageable, establishmentId);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getAllConveniences');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getAllConveniences');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static getConvenience = async (id: number): Promise<Convenience> => {
        try {
            const result: AxiosResponse<Convenience> = await ConvenienceApi.getConvenience(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getConvenience');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getConvenience');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static createConvenience = async (convenience: Convenience, notifyUser?: boolean): Promise<Convenience> => {
        try {
            const result: AxiosResponse<Convenience> = await ConvenienceApi.createConvenience(convenience, notifyUser);
            if (isResponseSuccess(result.data != null, result.status, StatusCodes.CREATED)) {
                ToastUtils.emitSuccessToast('createConvenience');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('createConvenience');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('createConvenience');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static updateConvenience = async (convenience: Convenience): Promise<Convenience> => {
        try {
            const result: AxiosResponse<Convenience> = await ConvenienceApi.updateConvenience(convenience);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('updateConvenience');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('updateConvenience');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('updateConvenience');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static deleteConvenience = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await ConvenienceApi.deleteConvenience(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('deleteConvenience');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('deleteConvenience');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('deleteConvenience');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static activateConvenience = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await ConvenienceApi.activateConvenience(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('activateConvenience');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('activateConvenience');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('activateConvenience');
            return Promise.reject(error.response as AxiosResponse);
        }
    };
}

export default ConvenienceService;
