import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { compose } from 'redux';
import Header from '../src/features/menu/header';
import './_toasts.scss';
import './App.scss';
import LoadingContainerScreen from './components/loading-container-screen/loading-container-screen';
import { HttpRequestStatus } from './model/enums/http-request-status';
import { IRootState } from './reducer';
import { getSessionRequest, logoutRequest } from './reducer/authentication/actions';
import AppRoutes from './shared/routes/routes';
import AuthUtils from './shared/util/auth-utils';
import GlobalStyles from './styled-components/GlobalStyles';

const baseHref = '/';

interface IAppProps extends StateProps, DispatchProps {
    // TODO
}

interface IAppState {
    isLoading: boolean;
}

class App extends React.Component<IAppProps, IAppState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        if (AuthUtils.isAuthenticated()) {
            this.props.getSession();
        } else {
            this.isLoading(false);
        }
    }

    componentWillReceiveProps(nextProps: Readonly<IAppProps>) {
        if (nextProps.getSessionStatus === HttpRequestStatus.SUCCESS) {
            this.isLoading(false);
        }

        if (nextProps.getSessionStatus === HttpRequestStatus.ERROR) {
            this.props.logout();
            this.isLoading(false);
        }
    }

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    renderApp = (appProps: RouteComponentProps<any>) => {
        const { isLoading } = this.state;
        const { isAuthenticated } = this.props;
        const notAllowedPathname: string[] = ['/register', '/'];
        const checkIfIsAuthenticated = isAuthenticated && !notAllowedPathname.includes(appProps.location.pathname);
        return (
            <div className={'App'}>
                <GlobalStyles />
                <LoadingContainerScreen isLoading={isLoading}>
                    {checkIfIsAuthenticated && <Header {...this.props} {...appProps} name={this.props.account?.firstName} />}
                    <Route path={'/'} component={() => (<AppRoutes {...appProps} isAuthenticated={checkIfIsAuthenticated} />)} />
                </LoadingContainerScreen>
            </div>
        );
    };

    closeToastifyIcon = () => (
        <i className={'icon-fechar toastify-close-icon'} />
    );

    render() {
        return (
            <div className={'page-container'}>
                <BrowserRouter basename={baseHref}>
                    <Route render={this.renderApp} />
                </BrowserRouter>
                <ToastContainer
                    draggable
                    rtl={false}
                    closeOnClick
                    pauseOnHover
                    autoClose={2000}
                    newestOnTop={false}
                    className={'toast-wrapper'}
                    position={toast.POSITION.BOTTOM_CENTER}
                    closeButton={<this.closeToastifyIcon />}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IRootState) => ({
    account: state.authentication.account,
    isAuthenticated: state.authentication.isAuthenticated,
    getSessionStatus: state.authentication.getSessionStatus
});

const mapDispatchToProps = {
    logout: logoutRequest,
    getSession: getSessionRequest
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(connect(mapStateToProps, mapDispatchToProps))(App) as React.ComponentType<any>;
