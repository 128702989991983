import { AxiosResponse } from 'axios';
import AccountApi from '../api/account-api';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

class AccountService {

    static activateAccount = async (data): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await AccountApi.activateAccount(data);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('activate-account');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('activate-account');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('activate-account');
            return Promise.reject(error.response as AxiosResponse);
        }
    };


    static resetPassword = async (data): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await AccountApi.resetPassword(data);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('activate-account');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('activate-account');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('activate-account');
            return Promise.reject(error.response as AxiosResponse);
        }
    }
}

export default AccountService;