import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import SaleApi from '../api/sale.api';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { Sale } from '../model/sale';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

class SaleService {
    static getAllSales = async (predicate: Predicate, pageable: Pageable, establishmentId?: number): Promise<PageableResponse<Sale>> => {
        try {
            const result: AxiosResponse<PageableResponse<Sale>> = await SaleApi.getAllSales(predicate, pageable, establishmentId);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getAllSales');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getAllSales');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static getSale = async (id: number): Promise<Sale> => {
        try {
            const result: AxiosResponse<Sale> = await SaleApi.getSale(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getSale');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getSale');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static createSale = async (sale: Sale, notifyUser?: boolean): Promise<Sale> => {
        try {
            const result: AxiosResponse<Sale> = await SaleApi.createSale(sale, notifyUser);
            if (isResponseSuccess(result.data != null, result.status, StatusCodes.CREATED)) {
                ToastUtils.emitSuccessToast('createSale');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('createSale');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('createSale');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static updateSale = async (sale: Sale): Promise<Sale> => {
        try {
            const result: AxiosResponse<Sale> = await SaleApi.updateSale(sale);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('updateSale');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('updateSale');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('updateSale');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static deleteSale = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await SaleApi.deleteSale(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('deleteSale');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('deleteSale');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('deleteSale');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static activateSale = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await SaleApi.activateSale(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('activateSale');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('activateSale');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('activateSale');
            return Promise.reject(error.response as AxiosResponse);
        }
    };
}

export default SaleService;
