import classNames from 'classnames';
import React, { Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Document } from '../../model/document';
import StringUtils from '../../shared/util/string-utils';
import './image-upload.scss';

interface IImageUploadProps extends WithTranslation {
    width?: string;
    height?: string;
    image?: Document;
    subtitle?: string;
    isDisabled?: boolean;
    callbackRef?: (ref: object) => void;
}

interface IImageUploadState {
    picture: string;
    isUploading: boolean;
    uploadedFile?: Document;
}

class ImageUpload extends React.Component<IImageUploadProps, IImageUploadState> {
    $fieldInputRef: any;
    $defaultPath: string;

    static defaultProps = {
        height: '107px'
    };

    constructor(props) {
        super(props);
        this.state = {
            picture: '',
            isUploading: false
        };
        this.$fieldInputRef = React.createRef();
        this.$defaultPath = 'teste';
    }

    componentDidMount() {
        this.props.image && this.setImage();
        this.props.callbackRef && this.props.callbackRef(this);
    }

    setImage = () => {
        this.setState({
            uploadedFile: {
                ...this.props.image,
                ...this.state.uploadedFile
            },
            picture: this.props.image?.file ? StringUtils.generateBase64(this.props.image) : this.props.image?.url!
        });
    };

    isUploading = (isUploading: boolean) => {
        this.setState({
            isUploading
        });
    };

    getFile = () => {
        return this.state.uploadedFile;
    };

    deleteFile = () => {
        this.setState({
            picture: '',
            uploadedFile: {}
        });
    };

    onClick = () => {
        this.$fieldInputRef.current.click();
    };

    onUploadFile = (event: any) => {
        const acceptFiles = ['image'];
        const uploadedFile = event.target.files[0];

        if (uploadedFile == null || uploadedFile?.type.indexOf(acceptFiles[0])) return;

        this.isUploading(true);

        const reader = new FileReader();

        reader.onload = (ev: any) => {
            this.setState(
                {
                    picture: ev.target.result,
                    uploadedFile: {
                        ...this.state.uploadedFile,
                        id: undefined,
                        fileName: uploadedFile.name,
                        fileContentType: uploadedFile.type,
                        file: ev.target.result.split(',')[1]
                    }
                },
                () => this.isUploading(false)
            );
        };

        reader.readAsDataURL(uploadedFile);
    };

    renderEmptyContainer = () => {
        const { t, isDisabled } = this.props;
        return (
            <Fragment>
                <i className={'icon-imagem_rounded'} style={{fontSize: '30px'}}/>
                <div className={'image-upload-title-wrapper'}>
                </div>
                {!isDisabled && (
                    <div className={'image-upload-button'} onClick={this.onClick}>
                        <span>{t('sale.image')}</span>
                        <input ref={this.$fieldInputRef} onChange={this.onUploadFile} type={'file'} accept={'image/*'} multiple={false} />
                    </div>
                )}
                <div className='text'>
                    <p className='image-text'>{t('sale.imageText')}</p>
                    <p className='image-size'>{t('sale.imageSize')}</p>
                </div>
            </Fragment>
        );
    };

    renderNonEmptyContainer = () => {
        const { picture } = this.state;
        const { isDisabled } = this.props;
        return (
            <Fragment>
                <div className={'image-rendering'}>
                    <img src={picture} alt={''} />
                </div>
                {!isDisabled && (
                    <img className={'delete-image'}
                        // src={require('../../images/icon/delete-image.svg')} 
                        onClick={this.deleteFile} alt={''} />
                )}
            </Fragment>
        );
    };

    render() {
        const { picture } = this.state;
        const { width, height } = this.props;
        const wrapperStyles = { width, height };
        const isPictureEmpty = StringUtils.isStringEmpty(picture);
        const isFileEmptyClassName = isPictureEmpty ? 'is-empty' : '';
        return (
            <div className={classNames([isFileEmptyClassName, 'image-upload-wrapper'])} style={wrapperStyles}>
                {isPictureEmpty ? this.renderEmptyContainer() : this.renderNonEmptyContainer()}
            </div>
        );
    }
}

export default withTranslation()(ImageUpload);
