import { Modal } from '@material-ui/core';
import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const DeleteAlertModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteAlertModalItems = styled.div`
  display: flex;
  width: 656px;
  padding: 14px 169px 40px;
  min-height: 215px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  background-color: white;
  justify-content: space-between;
  position: relative;

  &.is-loading {
    position: relative;
    padding-top: 120px;
    justify-content: flex-start;
  }

  .icon-fechar:before {
    font-size: 32px;
    color: ${Colors.lightNavy};
    right: 25px;
    position: absolute;
    cursor: pointer;
  }
`;

export const LoadingText = styled.span`
  bottom: 45px;
  font-size: 16px;
  color: $dark-grey;
  text-align: center;
  position: absolute;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const BodyTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: black;
  text-align: center;
`;

export const BodyText = styled.div`
  color: #4a4a4a;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`;
