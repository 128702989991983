import { isEmpty } from 'lodash';
import { AsyncSelectResponse } from '../../model/async-select';
import { defaultValue, ErrorAndMessage, Validation } from '../../model/validation';

class ValidatorUtils {
    static isAsyncSelectSelected = (response: AsyncSelectResponse) => {
        return (response.value != null && response.label != null);
    }

    static isObjectOrArrayEmpty = (entity: object | any[]) => {
        return isEmpty(entity);
    }
}

export const entityValidator = (parameters: any[][], validation: Validation[]) => {
    const entityError: ErrorAndMessage = { ...defaultValue };
    validation.forEach((item, index) =>  {
        if (!entityError.value) {
            entityError.message = item.message;
            entityError.value = item.handler(parameters[index]);
        }
    });
    return entityError;
}

export default ValidatorUtils;
