import { isEmpty } from 'lodash';
import React, { ComponentType, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import AccessDenied from '../error/access-denied';
import AuthUtils from '../util/auth-utils';

interface IPrivateRouteProps extends StateProps, RouteComponentProps {
    path: string;
    exact?: boolean;
    accessLevel?: AccessLevel[]
    component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

interface IPrivateRouteState {
    isPreLoading: boolean;
}

export enum AccessLevel {
    USER = 'ROLE_USER',
    ADMIN = 'ROLE_ADMIN',
    CUSTOMER = 'ROLE_CUSTOMER',
    ESTABLISHMENT_USER = 'ROLE_ESTABLISHMENT_USER',
    ESTABLISHMENT_ADMIN = 'ROLE_ESTABLISHMENT_ADMIN'
}

class PrivateRoute extends React.Component<IPrivateRouteProps, IPrivateRouteState> {
    constructor(props) {
        super(props);
        this.state = {
            isPreLoading: true
        }
    }

    componentDidMount() {
        this.isPreLoadingTimeout();
    }

    isPreLoadingTimeout = () => {
        setTimeout(() => this.isPreLoading(false), 50);
    };

    isPreLoading = (isPreLoading: boolean) => {
        this.setState({
            isPreLoading
        });
    };

    checkIfAuthoritiesIncludes = () => {
        const { account, accessLevel } = this.props;
        let authoritiesIncludes = false;

        accessLevel?.forEach(access => {
            if (account?.authorities?.includes(access as any)) authoritiesIncludes = true;
        });

        return accessLevel && !isEmpty(accessLevel) ? authoritiesIncludes : true;
    };

    renderNonAuthenticatedRoute = () => {
        const { history } = this.props;
        const redirectProps = { pathname: '/', state: { from: history.location.pathname } };
        return <Redirect to={redirectProps} />;
    };

    renderAuthenticatedRoute = () => {
        const { isPreLoading } = this.state;
        const { exact, path, component } = this.props;
        if (isPreLoading) return;
        return (
            <Fragment>
                {this.checkIfAuthoritiesIncludes() ? (
                    <Route exact={exact} path={path} component={component} />
                ) : (
                    <AccessDenied />
                )}
            </Fragment>
        );
    };

    render() {
        return (
            <Fragment>
                {AuthUtils.isAuthenticated() ? (
                    this.renderAuthenticatedRoute()
                ) : (
                    this.renderNonAuthenticatedRoute()
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    account: authentication.account
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps))(withRouter(PrivateRoute));
