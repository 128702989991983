import styled from 'styled-components/macro';
import { responsive } from '../../_media-query';
import backgroundImage from '../../images/background-login.jpg';
import logoImage from '../../images/logo.svg';
import HelpText from '../../styled-components/HelpText';

export const LoginContainer = styled.div`
  ${HelpText} {
    position: absolute;
    top: calc(100% - 2px);
    z-index: 1;
  }

  .row {
    margin: 0;
  }
  [class^="col"] {
    padding: 0;
  }

  a {
    width: 100%;
  }
`;

export const Background = styled.div`
  background-image: url('${backgroundImage}');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
`;

export const Logo = styled.div`
  content: url('${logoImage}');
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 169px;
`;

export const Items = styled.div`
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 500px;
  max-width: 488px;
  padding: 65px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemLink = styled.div`
  font-size: 12px;
  letter-spacing: 0.5px;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 20px;
  width: 100%;
  color: #34bfff;

  &:hover {
    color: #34bfff;
  }
`;

export const TitleLogin = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  color: #143d66;
  text-align: left;
  width: 100%;

  span:last-child {
    font-weight: 900;
  }
    /* ${responsive('s')} {
        display: none;
    } */
`;
export const SubTitleLogin = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 2.29;
  color: #808080;
  text-align: left;
  width: 100%;
  margin-bottom: 28px;
  margin-top: 3px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 46px;
`;
