import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import 'toasted-notes/src/styles.css';
import '../../App.scss';
import Button from '../../components/button/button';
import TextField from '../../components/custom-text-field/custom-text-field';
import { HttpRequestStatus } from '../../model/enums/http-request-status';
import { IRootState } from '../../reducer';
import { getSessionRequest, loginRequest, logoutRequest } from '../../reducer/authentication/actions';
import AuthUtils from '../../shared/util/auth-utils';
import { FlexCenteredCol } from '../../styled-components/Flex';
import {
    Background,
    ButtonContainer,
    ItemLink,
    LoginContainer,
    Logo,
    SubTitleLogin,
    TitleLogin
} from './StyledComponents';

interface ILoginProps extends StateProps, DispatchProps, WithTranslation, RouteComponentProps {
    // TODO
}

export interface ILoginState {
    login: string;
    password: string;
    loading: boolean;
    isLoadingSend: boolean;
    hasErrorLogin: boolean;
    usernameError?: boolean;
    passwordError?: boolean;
    isLoadingAction: boolean;
    usernameErrorText: string;
    passwordErrorText: string;
    loginResetPassword: string;
    isResetPasswordOpen: boolean;
    getSessionEventPrevent: boolean;
    loginResetPasswordError: boolean;
    loginResetPasswordErrorText: string;
}

class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            loading: true,
            isLoadingSend: false,
            hasErrorLogin: false,
            loginResetPassword: '',
            isLoadingAction: false,
            isResetPasswordOpen: false,
            getSessionEventPrevent: false,
            loginResetPasswordError: false,
            loginResetPasswordErrorText: '',
            usernameErrorText: this.props.t('login.error.username'),
            passwordErrorText: this.props.t('login.error.password')
        };
    }

    componentDidMount() {
        if (this.props.location.state?.['from'] != null) {
            window.history.replaceState(null, '', this.props.location.state?.['from']);
        }

        if (AuthUtils.isAuthenticated()) {
            this.redirectToPath();
        } else {
            this.loading(false);
        }
    }

    componentWillReceiveProps(newProps: Readonly<ILoginProps>) {
        if (newProps.getSessionStatus === HttpRequestStatus.SUCCESS && !this.state.getSessionEventPrevent) {
            this.setState({ getSessionEventPrevent: true }, () => this.redirectToPath());
        }

        if (newProps.loginStatus === HttpRequestStatus.SUCCESS && newProps.getSessionStatus === HttpRequestStatus.NOOP) {
            this.props.getSession();
        }

        if (newProps.getSessionStatus === HttpRequestStatus.ERROR) {
            this.props.logout();
            this.loading(false);
        }

        if (newProps.loginStatus === HttpRequestStatus.ERROR) {
            this.setState({
                loading: false,
                passwordError: true,
                usernameError: undefined,
                passwordErrorText: this.props.t('login.error.password')
            });
        }
    }

    redirectToPath = () => {
        const redirectPath = this.props.location.state?.['from'] || AuthUtils.getMainPath();
        this.props.history.replace(redirectPath);
    };

    loading = (loading: boolean) => {
        this.setState({
            loading
        });
    };

    isLoadingAction = () => {
        this.setState({
            isLoadingAction: !this.state.isLoadingAction
        });
    };

    isLoadingSend = () => {
        this.setState({
            isLoadingSend: !this.state.isLoadingSend
        });
    };

    handleSubmit = () => {
        if (this.state.login.length === 0 || this.state.password.length === 0) {
            this.setState({
                usernameError: this.state.login.length === 0,
                passwordError: this.state.password.length === 0,
                usernameErrorText: this.props.t('login.error.usernameLength'),
                passwordErrorText: this.props.t('login.error.passwordLength')
            });
        } else {
            this.setState({
                loading: true,
                passwordError: false,
                usernameError: false
            });
            this.props.login({ username: this.state.login, password: this.state.password });
        }
    };

    onChangeUserName = (value: string) => {
        this.setState({
            login: value
        });
    };

    onChangePassword = (value: string) => {
        this.setState({
            password: value
        });
    };

    onSetErrorLogin = (value: boolean) => {
        this.setState({
            hasErrorLogin: value
        });
    };

    goToRegister = () => {
        this.props.history.push('/register');
    };

    onBlurUsername = () => {
        this.setState({
            usernameError: this.state.usernameError ? this.state.login.length === 0 : undefined
        });
    };

    onBlurPassword = () => {
        this.setState({
            passwordError: this.state.passwordError ? this.state.password.length === 0 : undefined
        });
    };

    render() {
        const { t } = this.props;
        const { login, password, usernameError, usernameErrorText, passwordError, passwordErrorText, loading } = this.state;
        return (
            <LoginContainer>
                <Row>
                    <Col md="7">
                        <Background />
                        <Logo />
                    </Col>
                    <Col md={{ size: '3', offset: '1' }}>
                        <FlexCenteredCol>
                            <TitleLogin>
                                <span>{t('login.welcome1')}</span>
                                <span>{t('login.welcome2')}</span>
                            </TitleLogin>
                            <SubTitleLogin>
                                <span>{t('login.welcome3')}</span>
                            </SubTitleLogin>
                            <TextField
                                isEmail
                                id="username"
                                value={login}
                                error={usernameError}
                                onBlur={this.onBlurUsername}
                                errorText={usernameErrorText}
                                placeholder="Insira seu e-mail"
                                onChange={this.onChangeUserName}
                                label={t('login.form.username')}
                            />
                            <Link to="/forgot-password">
                                <ItemLink>{t('login.form.forgotPassword')}</ItemLink>
                            </Link>
                            <TextField
                                isPassword
                                id="password"
                                value={password}
                                error={passwordError}
                                onBlur={this.onBlurPassword}
                                errorText={passwordErrorText}
                                placeholder="Insira sua senha"
                                onChange={this.onChangePassword}
                                onEnterPress={this.handleSubmit}
                                InputProps={{ autComplete: 'off' }}
                                label={t('login.form.password')}
                                helpText={t('login.form.helpPass')}
                            />
                            <ButtonContainer>
                                <Button onClick={this.handleSubmit} isLoading={loading} type="submit">
                                    {t('global.button.enter')}
                                </Button>
                            </ButtonContainer>
                        </FlexCenteredCol>
                    </Col>
                </Row>
            </LoginContainer>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    loginStatus: authentication.loginStatus,
    isAuthenticated: authentication.isAuthenticated,
    getSessionStatus: authentication.getSessionStatus
});

const mapDispatchToProps = {
    login: loginRequest,
    logout: logoutRequest,
    getSession: getSessionRequest
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Login) as React.ComponentType<any>;
