import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import ConvenienceCard from '../../../../components/convenience-card/convenience-card';
import Icon from '../../../../components/icon/icon';
import DeleteAlertModal from '../../../../components/delete-alert-modal/delete-alert-modal';
import LoadingContainerScreen from '../../../../components/loading-container-screen/loading-container-screen';
import { Convenience as ConvenienceEntity } from '../../../../model/convenience';
import { Pageable } from '../../../../model/pageable';
import { Predicate } from '../../../../model/predicate';
import ConvenienceService from '../../../../services/convenience-service';
import AuthUtils from '../../../../shared/util/auth-utils';
import { ButtonRight } from '../../StyledComponents';
import { EstablishmentTitleContainer } from '../StyledComponents';
import ConvenienceCreateOrEditModal from './convenience-create-or-edit-modal/convenience-create-or-edit-modal';

interface IConvenienceProps extends WithTranslation {
    establishmentId: number | string;
}

interface IConvenienceState {
    page: Pageable;
    active?: boolean;
    isLoading: boolean;
    isDeleting: boolean;
    predicate: Predicate;
    isActionLoading: boolean;
    deleteModalId: number | null;
    alertModalRoutesList: string[];
    isConvenienceModalOpen: boolean;
    isOpenActionModal: number | null;
    convenience: ConvenienceEntity[];
    convenienceModalId: number | null;
    isOpenActionModalIsArchive: boolean;
    isAlertModalOpenValue: string | null;
}

class Convenience extends React.Component<IConvenienceProps, IConvenienceState> {
    $defaultPath: string;
    constructor(props) {
        super(props);
        this.state = {
            predicate: {},
            convenience: [],
            isLoading: true,
            isDeleting: false,
            deleteModalId: null,
            isActionLoading: false,
            isOpenActionModal: null,
            convenienceModalId: null,
            isAlertModalOpenValue: null,
            isConvenienceModalOpen: false,
            isOpenActionModalIsArchive: false,
            alertModalRoutesList: ['/new', '/edit'],
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'id,desc' }
        };
        this.$defaultPath = '';
    }

    componentDidMount() {
        this.getAllConvenience().then(callback => callback);
    }

    resetPage = () => {
        this.setState({
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'id,asc' }
        });
    };

    updatePage = async (key: string, event: number | string) => {
        this.setState({
            page: {
                ...this.state.page,
                [key]: event
            }
        });
    };

    getAllConvenience = async (shouldResetPage?: boolean) => {
        if (shouldResetPage) this.resetPage();

        const { establishmentId } = this.props;
        const { predicate, page } = this.state;

        this.isLoading(true);

        const establishmentIdValidation = AuthUtils.isFgs() ? Number(establishmentId) : undefined;

        ConvenienceService.getAllConveniences(predicate, page, establishmentIdValidation).then(result =>
            this.setState(
                {
                    convenience: result.content,
                    page: {
                        ...this.state.page,
                        size: result.size,
                        page: result.number,
                        totalPages: result.totalPages,
                        totalElements: result.totalElements
                    }
                },
                async () => {
                    this.isLoading(false);
                    if (isEmpty(this.state.convenience) && this.state.page.page) {
                        await this.updatePage('page', this.state.page.page - 1);
                        await this.getAllConvenience();
                    }
                }
            )
        );
    };

    isConvenienceModalOpen = (isConvenienceModalOpen: boolean, convenienceModalId: number | null) => {
        this.setState({
            convenienceModalId,
            isConvenienceModalOpen
        });
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: { ...this.state.page, page: newPage }
            },
            () => this.getAllConvenience()
        );
    };

    handleClickRow = (id: number) => {
        const it = this.state.convenience[id];
        this.isConvenienceModalOpen(true, it.id!);
    };

    handleSort = (code: string) => {
        this.setState(
            {
                page: { ...this.state.page, sort: code }
            },
            () => this.getAllConvenience()
        );
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    isDeleting = (isDeleting: boolean) => {
        this.setState({
            isDeleting
        });
    };

    deleteModalId = (deleteModalId: number | null) => {
        this.setState({
            deleteModalId
        });
    };

    deleteConvenience = (id: number) => {
        this.isDeleting(true);
        ConvenienceService.deleteConvenience(id)
            .then(() => {
                this.isDeleting(false);
                this.deleteModalId(null);
                this.getAllConvenience(false).then(callback => callback);
            })
            .catch(() => this.isDeleting(false))
    };

    renderLoading = () => {
        return (
            <Row style={{ display: 'flex', width: '100%', height: '300px' }}>
                <Col md={12} className={'base-centered-column'}>
                    <div className={'base-access-denied-wrapper'}>
                        <LoadingContainerScreen isLoading children={''} />
                    </div>
                </Col>
            </Row>
        );
    };

    renderEmpty = () => {
        const { t } = this.props;
        return (
            <Row style={{ display: 'flex', width: '100%', height: '300px' }}>
                <Col md={12} className={'base-centered-column'}>
                    <div className={'is-empty-or-loading-wrapper'}>
                        <img src={require('../../../../images/FOOD.svg')} alt={''} />
                        <span>{t('global.list.isEmpty')}</span>
                    </div>
                </Col>
            </Row>
        );
    };

    activateConvenience = (id: number) => {
        this.isLoading(true);
        ConvenienceService.activateConvenience(id)
            .then(() => {
                this.getAllConvenience().then(() => {
                    this.isLoading(false);
                });
            })
            .catch(() => this.isLoading(false));
    };

    renderItem = (index: number, item: ConvenienceEntity) => {
        return (
            <Col md="4" key={index}>
                <ConvenienceCard
                    title={item.name || ''}
                    points={item.pointAmount || 0}
                    onDeleteClick={() => this.deleteModalId(item.id!)}
                    icon={<Icon isDisabled={!item.active} type={item.category}/>}
                    onEditClick={() => this.isConvenienceModalOpen(true, item.id!)}
                    onHandleToggle={() => this.activateConvenience(item.id!)}
                    disabled={!item.active}
                />
            </Col>
        );
    };

    render() {
        const { establishmentId, t } = this.props;
        const { convenience, isConvenienceModalOpen, convenienceModalId, isLoading, deleteModalId, isDeleting } = this.state;
        return (
            <Fragment>
                <EstablishmentTitleContainer>
                    {/*<Filter value={filterValue} onChange={this.updateFilterValue} filters={[Filters.TYPE]} />*/}
                    {(AuthUtils.isEstablishmentAdmin() || AuthUtils.isAdmin()) && (
                        <ButtonRight style={{ width: '184px', fontSize: '14px' }} onClick={() => this.isConvenienceModalOpen(true, null)} size="37px">
                            {this.props.t('establishment.items.convenience.button')}
                        </ButtonRight>
                    )}
                </EstablishmentTitleContainer>
                <Row style={{ minHeight: '300px' }}>
                    {isLoading ? (
                        this.renderLoading()
                    ) : (
                        !isEmpty(convenience)
                            ? convenience?.map((item, index) => this.renderItem(index, item))
                            : this.renderEmpty()
                    )}
                </Row>
                <ConvenienceCreateOrEditModal
                    isOpen={isConvenienceModalOpen}
                    id={convenienceModalId ?? undefined}
                    onSaveAction={this.getAllConvenience}
                    establishmentId={AuthUtils.isFgs() ? Number(establishmentId) : undefined}
                    onCancelAction={() => this.isConvenienceModalOpen(false, null)}
                />
                <DeleteAlertModal
                    isOpen={deleteModalId != null}
                    isInternalLoading={isDeleting}
                    title={t('convenience.deleteTitle')}
                    onCancelAction={() => this.deleteModalId(null)}
                    onConfirmAction={() => this.deleteConvenience(deleteModalId!)}
                />
            </Fragment>
        );
    }
}

export default withTranslation()(Convenience);
