import styled from 'styled-components/macro';
import Colors from '../../../styled-components/Colors';

export const FullLength = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  height: 90px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: black;
`;

export const ContainerItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 90px;

  .icon-seta:before {
    color: white;
  }
`;

export const ContainerInfos = styled.div`
  max-width: 50%;
`;

export const LineInfo = styled.div`
  display: flex;
  align-items: center;
  line-height: 23px;

  .icon-pin:before,
  .icon-tel:before,
  .icon-email:before {
    color: ${Colors.lightNavy};
    font-size: 18px;
    margin-right: 10px;
  }

  span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.8125rem;
    color: ${Colors.greyishBrown};
  }
`;

export const ButtonContainer = styled.div`
  transform: rotate(180deg);
  font-size: 32px;
  padding: 3px;
  margin-right: 20px;
  cursor: pointer;
  background: ${Colors.darkSkyBlue};
  border-radius: 50%;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);

  &:hover {
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.2);
  }
  &:active {
    background: ${Colors.bluish};
    transition: 250ms;
  }
`;

export const EstablishmentItems = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Items = styled.div`
  /* border: solid 1px #dadbdd; */
  background: white;
`;

export const ItemsBorder = styled.div`
  border-bottom: solid 1px #dadbdd;
  position: relative;
  top: 37px;
`;

export const EstablishmentSection = styled.li`
  width: 256px;
  height: 37px;
  border: solid 1px #dadbdd;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: margin-top 250ms ease, height 250ms ease;

  &:not(:first-of-type) {
    margin-left: -1px;
  }

  a {
    font-size: 14px;
    color: #000000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    height: 45px;
    margin-top: -8px;
    border-top: solid 3px ${Colors.sunflower};
    background-color: #fafafa;
    border-bottom: none;
  }

  ${console.log}
  ${props =>
          props.active &&
          `
        height: 45px;
        margin-top: -8px;
        border-top: solid 3px ${Colors.sunflower};
        background-color: #fafafa;
        border-bottom: none;
    `}
`;

export const EstablishmentTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 26px;
`;
