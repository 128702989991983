import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { HeaderBody } from './StyledComponents';
import LogoBranca from '../../images/logo-branca.svg';
import { Col, Container, Row } from 'reactstrap';

export interface HeaderWhiteProps {
    t: any;
}
export const Footer = (props: HeaderWhiteProps): JSX.Element => {
    return (
        <HeaderBody>
            <Container>
                <Row>
                    <Col  md={{ size: '11', offset: '1' }}>
                        <div style={{ width: '100%' }}>
                            <img src={LogoBranca} alt=''/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </HeaderBody>
    );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Footer) as React.ComponentType<any>;
