import { AxiosResponse } from 'axios';
import ForgotPasswordApi from '../api/forgot-password-api';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

class ForgotPasswordService {

    static forgotPassword = async (email: string): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await ForgotPasswordApi.forgotPassword(email);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('forgotPassword');
            return Promise.reject({status: result.status, statusText: result.statusText } as AxiosResponse);
            
        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('forgotPassword');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

}

export default ForgotPasswordService;
