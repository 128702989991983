import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/button/button';
import TextField from '../../components/custom-text-field/custom-text-field';
import ButtonContainerCenter from '../../styled-components/ButtonContainerCenter';
import { ContainerBody, SubTitle, Title } from './StyledComponents';
import { defaultValue, ErrorAndMessage } from '../../model/validation';
import ForgotPasswordService from '../../services/forgot-password-service';
import Footer from '../footer/footer';
import Header from '../../components/header-white/header';
import ContainerTitleBackSmall from '../../components/container-title-back-small/container-title-back-small';

export interface ForgotPasswordProps {
    t: any;
}

const ForgotPassword = (props: ForgotPasswordProps): JSX.Element => {
    const history = useHistory();

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<ErrorAndMessage>(defaultValue);

    const validateEmail = async () => {
        if (email != null && email.length > 0 && email.indexOf('@') > 0) {
            setEmailError({
                message: '',
                value: false
            });
            await ForgotPasswordService.forgotPassword(email);
            history.replace('/forgot-password/sucess');
            return {};
        }
        setEmailError({
            message: 'Preencha com um e-mail válido',
            value: true
        });
    };

    return (
        <div>
            <Header />
            <ContainerTitleBackSmall titleText={props.t('global.button.backStep')} />
            <Container>
                <Row>
                    <Col md={{ size: '4', offset: '4' }}>
                        <ContainerBody>
                            <Title>{props.t('forgot-password.title')}</Title>
                            <SubTitle>{props.t('forgot-password.subtitle')}</SubTitle>
                            <TextField
                                onChange={setEmail}
                                id="username"
                                isEmail
                                value={email}
                                label={props.t('login.form.username')}
                                placeholder="Insira seu e-mail"
                                error={emailError?.value}
                                errorText={emailError?.message}
                            />
                            <ButtonContainerCenter>
                                <Button onClick={validateEmail} type="submit">
                                    {props.t('global.button.submit')}
                                </Button>
                            </ButtonContainerCenter>
                        </ContainerBody>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default withTranslation()(ForgotPassword) as React.ComponentType<any>;
