import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './icon.scss';

interface IconProps extends WithTranslation {
    isDisabled?: boolean;
    type?: string;
}

class Icon extends React.Component<IconProps> {
    $defaultPath: string;

    constructor(props) {
        super(props);
        this.$defaultPath = '';
    }

    render() {
        const { isDisabled, type } = this.props;
        const disabledClassName = isDisabled ? 'is-disabled' : '';
        const imageSource = isDisabled ? `${type}-grey` : type;
        return (
            <div className={classNames([disabledClassName, 'fork-knife-icon-wrapper'])}>
                <img src={require(`../../images/${imageSource}.svg`)} alt={''} />
            </div>
        );
    }
}

export default withTranslation()(Icon);
