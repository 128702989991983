import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const Title = styled.div`
    width: 100%;
    height: 65px;
    border-bottom: solid 1px #dadbdd;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TitleText = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: black;
    white-space: nowrap;
`;

export const ContainerItems = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 65px;

    .icon-seta:before {
        color: white;
    }
`;
export const ButtonContainer = styled.div`
    transform: rotate(180deg);
    font-size: 32px;
    padding: 3px;
    margin-right: 20px;
    cursor: pointer;
    background: ${Colors.darkSkyBlue};
    border-radius: 50%;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);

    &:hover {
        box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.2);
    }
    &:active {
        background: ${Colors.bluish};
        transition: 250ms;
    }
`;

export const ChildrenStyle = styled.div`
    flex: 1 1 100%;
    text-align: right;
`;
