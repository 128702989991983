import { isNil } from 'lodash';
import { Document } from '../../model/document';

class StringUtils {
    static isStringEmpty = (event?: string) => {
        return isNil(event) || event === '';
    }

    static isPasswordInvalid = (event: string) => {
        return StringUtils.isStringEmpty(event) || event.length < 5 || event.length > 250;
    }

    static isSyntaxEmpty = (event?: string) => {
        return event === '${}' || event === '#{}';
    }

    static isSizeInvalid = (size: number, event?: string) => {
        if (StringUtils.isStringEmpty(event)) return true;
        return event?.length !== size;
    }

    static isSizeBetween = (min: number, max: number, event?: string) => {
        if (StringUtils.isStringEmpty(event)) return false;
        return event!.length >= min && event!.length <= max;
    }

    static generateFileUrl = (file: string, type: string) => {
        const blobObject = StringUtils.b64toBlob(file, type, null);
        return URL.createObjectURL(blobObject);
    };

    static formatCustomSymbolForCurrencyAndNumber = (event: number, symbol?: string, minimumFractionDigits?: number) => {
        if (symbol) return `${symbol} ${StringUtils.formatCurrencyAndNumber(event, true, minimumFractionDigits)}`;
        return StringUtils.formatCurrencyAndNumber(event, false, minimumFractionDigits);
    };

    static formatCurrencyAndNumber = (event: number, without$?: boolean, minimumFractionDigits?: number) => {
        if (without$) return event.toLocaleString('pt-BR', { minimumFractionDigits: minimumFractionDigits ?? 2 });
        return event.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits });
    };

    static generateBase64 = (file: Document) => {
        return `data:${file.fileContentType};base64, ${file.file}`;
    };

    static b64toBlob = (b64Data, contentType, size) => {
        const sliceSize = size ?? 512;

        const byteCharacters = atob(b64Data);
        const byteArrays: Uint8Array[] = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType ?? '' });
    };


    static isEmailInvalid = (event?: string) => {
        if (StringUtils.isStringEmpty(event)) return true;
        const emailRegex = /\S+@\S+\.\S+/;
        return !emailRegex.test(event!);
    }

    static toObject = (key: string, event: any) => {
        const result = {};
        result[key] = event;
        return result;
    }

    static randomColor = () => {
        const colors: string[] = ['#14b071', '#4f35a5', '#1e98d6'];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    static randomString = () => {
        return (
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        );
    }

    static b64EncodeUnicode = (str: string) => {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode(Number(`0x${p1}`));
            }));
    }

    static removeAccentToLowerCase = (event?: string) =>  {
        if (StringUtils.isStringEmpty(event)) return '';

        let withoutAccent;

        withoutAccent = event!.replace(new RegExp('[Ç]','gi'), 'c');
        withoutAccent = withoutAccent.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        withoutAccent = withoutAccent.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        withoutAccent = withoutAccent.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        withoutAccent = withoutAccent.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        withoutAccent = withoutAccent.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');

        return withoutAccent.toLowerCase();
    }

    static phoneMask(text) {
        return StringUtils.applyMask(text, '(**) *****-****');
    };

    static applyMask(text, mask = '*****-***') {
        let j = 0;
        let modifiedText = '';
        const requiredLength = mask.match(/\*/g)!.length;

        if (requiredLength > text.length) return text;

        for (const char of mask) {
            if (char !== '*') {
                modifiedText += char;
            } else {
                modifiedText += text[j];
                j++;
            }
        }

        return modifiedText;
    };
}

export default StringUtils;
