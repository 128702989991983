import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const ContainerBody = styled.div`
    padding-top: 133px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const Title = styled.div`
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 32px;
    text-align: center;
    color: black;
`;
export const SubTitle = styled.div`
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 34px;
    color: ${Colors.greyishBrown};
    text-align: center;
    letter-spacing: 0.67px;
`;
export const ConclusionImgContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
`;
