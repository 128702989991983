import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import pageNotFound from '../../shared/error/page-not-found';
import PrivateRoute, { AccessLevel } from '../../shared/routes/private-routes';
import Establishment from './establishment';
import EstablishmentItemRoutes from './item';
import NewEstablishment from './new-or-edit';

class EstablishmentRoutes extends React.Component<RouteComponentProps> {
    render() {
        const matchUrl: string = this.props.match.url;
        return (
            <Switch>
                <Route exact path={matchUrl} component={Establishment} />
                <PrivateRoute accessLevel={[AccessLevel.ESTABLISHMENT_ADMIN, AccessLevel.ADMIN]} exact path={`${matchUrl}/new`} component={NewEstablishment} />
                <PrivateRoute accessLevel={[AccessLevel.ESTABLISHMENT_ADMIN, AccessLevel.ADMIN]} exact path={`${matchUrl}/edit/:id`} component={NewEstablishment} />
                <Route path={`${matchUrl}/:id`} component={EstablishmentItemRoutes} />
                <Route path={'*'} component={pageNotFound} />
            </Switch>
        );
    }
}

export default EstablishmentRoutes;
