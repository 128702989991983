import qs from 'qs';
import HttpStatus from '../../model/enums/http-status';
import StatusCode from 'http-status-codes';
import { Pageable } from '../../model/pageable';
import { Predicate } from '../../model/predicate';

export const checkApiStatus = (validation: boolean, status: number, expectedStatus?: HttpStatus): boolean => {
    const compareStatus = expectedStatus || HttpStatus.OK;
    return status === compareStatus && validation;
};

export const isResponseSuccess = (validation: boolean, status: number, expectedStatus?: number): boolean => {
    const compareStatus = expectedStatus || StatusCode.OK;
    return status === compareStatus && validation;
};

export const requestParamsFormatter = (predicate: Predicate, page: Pageable, predicateParams?: object) => {
    const pageParams = qs.stringify({ ...page });
    const filterParams: string = Object.keys(predicate)
        .filter(key => predicate[key] != null)
        .map(key => (Array.isArray(predicate[key])
            ? `${key}=${(predicate[key].map(item => item[predicateParams?.[key] || 'name'])).join(',')}`
            : `${key}=${predicate[key]}`))
        .join('&');
    return [pageParams, filterParams];
};
