import styled from 'styled-components/macro';
import logoImage from '../../images/logo.svg';
import Colors from '../../styled-components/Colors';

export const HeaderBody = styled.div`
  background: #143d66;
  height: 58px;
  width: 100%;
`;

export const LinkContainer = styled.div`
  height: 100%;
  align-items: center;
  display: flex;
  margin-left: 50px;
  flex: 1 1 100%;
`;

export const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;

  & > :not(${LinkContainer}) {
    flex: 0 0;
  }
`;
export const LinkText = styled.span`
  margin-top: 4px;
  font-size: 1rem;
  line-height: 50px;
  display: inline-block;
  height: 100%;
  color: white;
  font-weight: 400;
  border-bottom: 4px solid transparent;
  transition: color 250ms ease;

  &:first-of-type {
    margin-right: 30px;
  }

  &:hover {
    font-size: 1rem;
    font-weight: 800;
    border-bottom: 4px solid transparent;
  }

  .active &,
  &:active {
    font-size: 1rem;
    font-weight: 800;
    border-bottom: 4px solid ${Colors.sunflower};
  }
`;

export const StyledSpan = styled.span`
  font-weight: 700;
  color: #27beff;
  transition: color 250ms ease;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  white-space: nowrap;

  &:hover,
  .active &,
  &:active {
    .icon-perfil:before {
      color: ${Colors.sunflower};
    }

    ${StyledSpan} {
      color: ${Colors.sunflower};
    }
  }

  .icon-perfil:before {
    font-size: 24px;
    color: white;
    transition: color 250ms ease;
  }
`;

export const LogoHeader = styled.div`
  content: url('${logoImage}');
  width: 130px;
  cursor: pointer;
`;
