import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import LoadingContainerScreen from '../../components/loading-container-screen/loading-container-screen';
import { logoutRequest } from '../../reducer/authentication/actions';

// tslint:disable-next-line:no-empty-interface
interface ILogoutProps extends DispatchProps {
    // TODO
}

class Logout extends React.Component<ILogoutProps> {
    componentDidMount() {
        this.requestLogout().then(callback => callback);
    }

    requestLogout = async () => {
        await this.props.logout();
        window.location.replace('/');
    };

    render() {
        return (
            <Fragment>
                <Row className='base-full-height'>
                    <Col md={12} className={'base-centered-column'}>
                        <LoadingContainerScreen isLoading children={void(0)} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    logout: logoutRequest
};

type DispatchProps = typeof mapDispatchToProps;

export default compose(connect(null, mapDispatchToProps))(Logout);
