import React, { Fragment } from 'react';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SimpleOrderedTable, { ColumnSort } from '../../../../components/simple-ordered-table/simple-ordered-table';
import { Status } from '../../../../model/enums/status';
import { UserTypeEstablishment } from '../../../../model/enums/user-type';
import { Pageable } from '../../../../model/pageable';
import { User } from '../../../../model/user';
import UserService from '../../../../services/user-service';
import AuthUtils from '../../../../shared/util/auth-utils';
import { maskPhone } from '../../../../shared/util/mask-utils';
import { ButtonRight } from '../../StyledComponents';
import { EstablishmentTitleContainer } from '../StyledComponents';
import UserCreateOrEditModal from './user-create-or-edit-modal/user-create-or-edit-modal';
import { isEmpty } from 'lodash';
import { Predicate } from '../../../../model/predicate';

interface IUserProps extends WithTranslation {
    establishmentId: string;
}

interface IUserState {
    user: User[];
    page: Pageable;
    isLoading: boolean;
    predicate: Predicate;
    isUserModalOpen: boolean;
    userModalId: number | null;
    alertModalRoutesList: string[];
}

class UserList extends React.Component<IUserProps, IUserState> {

    constructor(props) {
        super(props);
        this.state = {
            user: [],
            predicate: {},
            isLoading: true,
            userModalId: null,
            isUserModalOpen: false,
            alertModalRoutesList: ['/new', '/edit'],
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'firstName,asc' },

        };
    }

    componentDidMount() {
        this.getAllUsers().then(callback => callback); 
    }

    getAllUsers = async (shouldResetPage?: boolean) => {
        if (shouldResetPage) this.resetPage();

        const { establishmentId } = this.props;
        const { predicate, page } = this.state;

        this.isLoading(true);

        const establishmentIdValidation = AuthUtils.isFgs() ? Number(establishmentId) : undefined;

        UserService.getAllUsers(predicate, page, establishmentIdValidation).then(result =>
            this.setState(
                {
                    user: result.content,
                    page: {
                        ...this.state.page,
                        size: result.size,
                        page: result.number,
                        totalPages: result.totalPages,
                        totalElements: result.totalElements
                    }
                },
                async () => {
                    this.isLoading(false);
                    if (isEmpty(this.state.user) && this.state.page.page) {
                        await this.updatePage('page', this.state.page.page - 1);
                        await this.getAllUsers();
                    }
                }
            )
        );
    };

    resetPage = () => {
        this.setState({
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'name,asc' }
        });
    };

    updatePage = async (key: string, event: number | string) => {
        this.setState({
            page: {
                ...this.state.page,
                [key]: event
            }
        });
    };

    isUserModalOpen = (isUserModalOpen: boolean, userModalId: number | null) => {
        this.setState({
            isUserModalOpen,
            userModalId
        });
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: { ...this.state.page, page: newPage }
            },
            () => this.getAllUsers()
        );
    };

    private handleSort = (code: string) => {
        this.setState(
            {
                page: { ...this.state.page, sort: code }
            },
            () => this.getAllUsers()
        );
    };

    updateItem = (user: User) => {
        user.activated = !user.activated;
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    activateUser = (id: number) => {
        this.isLoading(true);
        UserService.activateUser(id)
            .then(() => {
                this.getAllUsers().then(() => {
                    this.isLoading(false);
                });
            })
            .catch(() => this.isLoading(false));
    };

    tableHeaders: ColumnSort[] = [
        { name: this.props.t('establishment.table.userName'), sortCode: 'userName' },
        { name: this.props.t('establishment.table.office'), sortCode: 'office' },
        { name: this.props.t('establishment.table.contact'), sortCode: 'cnpj' },
        { name: this.props.t('establishment.table.status'), sortCode: 'status' },
        { sortDisabled: true }
    ];

    handleTransformToTableContent = (content?: User[]): (string | JSX.Element)[][] => {
        if (content == null || content.length === 0) return [];
        const result: (string | JSX.Element)[][] = [];

        content.forEach((item: User, index: number) => {
            result.push([
                item?.customer?.name ?? 'nome',
                item?.authorities![0] === UserTypeEstablishment.ESTABLISHMENT_ADMIN ? 'Administrador' : 'Usuário',
                this.renderContacts(item),
                this.renderStatus(item.status || Status.INACTIVE),
                <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.status === Status.INACTIVE ? (
                        <i style={{ opacity: 0.5, marginRight: '17px' }} className="icon-editar" />
                    ) : (
                            <i
                                style={{ marginRight: '17px', cursor: 'pointer' }}
                                className="icon-editar"
                                onClick={() => this.isUserModalOpen(true, item?.id!)}
                            />
                        )}
                    <div
                        style={{ cursor: 'pointer' }}
                        className={item.status === Status.ACTIVE ? 'icon-deletar' : 'icon-deletar-reverter'}
                        onClick={() => this.activateUser(item?.id!)}
                    />
                </div>
            ]);
        });
        return result;
    };

    renderContacts = (item: User) => {
        const { t } = this.props;
        return (
            <div className={'column-list-item is-contacts'}>
                <span>{item.customer?.phone ? maskPhone(item.customer?.phone) : t('global.emptyLabel')}</span>
                <span>{item.email || t('global.emptyLabel')}</span>
            </div>
        );
    };

    renderStatus = (status: Status) => {
        const { t } = this.props;
        const statusClassName = status === Status.ACTIVE ? 'is-active' : 'is-inactive';
        return (
            <div className={classNames([statusClassName, 'status-list-item'])}>
                <span>
                    {t(`enum.status.${status}`)}
                </span>
            </div>
        );
    };

    render() {
        const { establishmentId } = this.props;
        const { user, isUserModalOpen, userModalId } = this.state;
        const disabledRows: boolean[] = user.map(item => item.status === Status.INACTIVE);
        const tableContent: (string | JSX.Element)[][] = this.handleTransformToTableContent(user);
        return (
            <Fragment>
                <>
                    <EstablishmentTitleContainer>
                        <ButtonRight style={{ width: '184px', fontSize: '14px' }} onClick={() => this.isUserModalOpen(true, null)} size="37px">
                            {this.props.t('establishment.items.user.button')}
                        </ButtonRight>
                    </EstablishmentTitleContainer>
                    <div>
                        <Row>
                            <Col>
                                <SimpleOrderedTable
                                    rows={tableContent}
                                    page={this.state.page}
                                    disabledRows={disabledRows}
                                    columnNameKeys={this.tableHeaders}
                                    onChangePage={this.handleChangePage}
                                    onSort={(code: string) => this.handleSort(code)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <UserCreateOrEditModal
                        isOpen={isUserModalOpen}
                        id={userModalId ?? undefined}
                        onSaveAction={this.getAllUsers}
                        onCancelAction={() => this.isUserModalOpen(false, null)}
                        establishmentId={AuthUtils.isFgs() ? Number(establishmentId) : undefined}
                    />
                </>
            </Fragment>
        );
    }
}

export default withTranslation()(UserList);
