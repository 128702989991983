import { createMuiTheme, TableBody, TableCell, TableHead, TableRow, ThemeProvider } from '@material-ui/core';
import { ptPT } from '@material-ui/core/locale';
import { Pagination, PaginationItem } from '@material-ui/lab';
import React, { Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Pageable } from '../../model/pageable';
import ClickableIcon from '../clickable-icon/clickable-icon';
import LoadingContainerScreen from '../loading-container-screen/loading-container-screen';
import { StyledInput, StyledPagination, StyledTable, StyledTableRow, StyledText } from './StyledComponents';

interface Props extends RouteComponentProps, WithTranslation {
    page: Pageable;
    isLoading?: boolean;
    disabledRows?: boolean[];
    emptyTableMessage?: string;
    columnNameKeys: ColumnSort[];
    onSort?: (code: string) => void;
    rows?: (string | JSX.Element)[][];
    onClickRow?: (index: number) => void;
    onChangePage: (newPage: number) => void;
}

interface State {
    columnNameKeys: ColumnSort[];
}

export interface ColumnSort {
    name?: string;
    sortCode?: string;
    type?: 'asc' | 'desc';
    sortDisabled?: boolean;
    sortActivated?: boolean;
}

export class SimpleOrderedTable extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            columnNameKeys: props.columnNameKeys
        };
    }

    renderCells = (row: (string | JSX.Element)[], rowKey: string, rowIndex: number): JSX.Element[] => {
        const result: JSX.Element[] = [];
        row.forEach((item: string | JSX.Element, index: number) => {
            const style = index === 0 ? 'td-first' : index === row.length - 1 ? 'td-last' : '';
            result.push(
                <TableCell
                    className={style}
                    key={`${rowKey}[cell-${index}]`}
                    onClick={() => {
                        if (this.props.onClickRow && style !== 'td-last') {
                            this.props.onClickRow(rowIndex);
                        }
                    }}
                >
                    {item}
                </TableCell>
            );
        });

        return result;
    };

    renderEmptyContentMessage = () => {
        const { t, isLoading } = this.props;
        return (
            <TableRow className={'row-container'}>
                <TableCell colSpan={this.state.columnNameKeys.length} className={'cell-text item-max-width is-empty-or-loading'}>
                    <div className={'is-empty-or-loading-wrapper'}>
                        <LoadingContainerScreen isLoading={isLoading}>
                            <img src={require('../../images/FOOD.svg')} alt={''} />
                            <span>{t('global.list.isEmpty')}</span>
                        </LoadingContainerScreen>
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    onSort = (column: ColumnSort) => {
        const columnNameKeys = [...this.state.columnNameKeys];
        const type = column.sortActivated ? (column.type === 'asc' ? 'desc' : 'asc') : 'asc';
        const code = `${column.sortCode ?? ''},${type}`;

        columnNameKeys
            .filter(it => !it.sortDisabled)
            .forEach(it => {
                if (column.name === it.name) {
                    it.sortActivated = true;
                    it.type = type;
                } else if (column.name !== it.name && it.sortActivated) {
                    it.sortActivated = false;
                }
            });

        if (this.props.onSort) {
            this.setState(
                {
                    columnNameKeys
                },
                () => this.props.onSort!(code)
            );
        }
    };

    renderSortHeader = (column: ColumnSort): JSX.Element => {
        const iconSortActivated = column.type === 'desc' ? require('../../images/flecha2.svg') : require('../../images/flecha3.svg');
        return (
            <div style={{ cursor: 'pointer' }} onClick={() => (!column.sortDisabled ? this.onSort(column) : null)}>
                {column.name ?? ''}
                {!column.sortDisabled && column.sortActivated ? (
                    <span>
                        <ClickableIcon style={'sort-icon'} iconPath={iconSortActivated} onClick={() => null} />
                    </span>
                ) : (
                    <span>
                        <ClickableIcon style={'sort-default-icon'} iconPath={require('../../images/flechas.svg')} onClick={() => null} />
                    </span>
                )}
            </div>
        );
    };

    renderPagination = () => {
        return (
            <StyledPagination>
                <Pagination
                    shape="rounded"
                    defaultPage={1}
                    boundaryCount={1}
                    variant="outlined"
                    page={(this.props.page.page ?? 0) + 1}
                    count={this.props.page.totalPages || 0}
                    onChange={(event, page) => this.props.onChangePage(page - 1)}
                    renderItem={itemArgs => {
                        if (itemArgs.type === 'previous' || itemArgs.type === 'next') {
                            return <PaginationItem {...itemArgs} />;
                        } else if (itemArgs.page === 1) {
                            return (
                                <Fragment>
                                    <StyledText>{'Página '}</StyledText>
                                    <StyledInput
                                        value={(this.props.page.page ?? 0) + 1}
                                        onChange={evt =>
                                            this.props.onChangePage(
                                                Math.max(0, Math.min(this.props.page.totalPages || 0, +evt.target.value) - 1)
                                            )
                                        }
                                    />
                                    <StyledText>{' de '}</StyledText>
                                    <StyledText>{this.props.page.totalPages || 0}</StyledText>
                                </Fragment>
                            );
                        }
                        return null;
                    }}
                />
            </StyledPagination>
        );
    };

    render() {
        return (
            <ThemeProvider theme={createMuiTheme(ptPT)}>
                <StyledTable>
                    <TableHead>
                        <StyledTableRow>
                            {this.state.columnNameKeys != null &&
                                this.state.columnNameKeys.map((column: ColumnSort, index: number) => (
                                    <TableCell key={`column-header-${index}`}>
                                        {column.sortDisabled ? column.name ?? '' : this.renderSortHeader(column)}
                                    </TableCell>
                                ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.rows != null && this.props.rows.length !== 0
                            ? this.props.rows.map((row: (string | JSX.Element)[], index: number) => (
                                  <TableRow
                                      key={`row-${index}`}
                                      style={{
                                          opacity: this.props.disabledRows && this.props.disabledRows[index] ? '0.5' : '',
                                          boxShadow: this.props.disabledRows && this.props.disabledRows[index] ? 'unset' : '',
                                          backgroundColor: this.props.disabledRows && this.props.disabledRows[index] ? '#eeeeee' : ''
                                      }}
                                  >
                                      {this.renderCells(row, `row-${index}`, index)}
                                  </TableRow>
                              ))
                            : this.renderEmptyContentMessage()}
                    </TableBody>
                </StyledTable>
                {this.renderPagination()}
            </ThemeProvider>
        );
    }
}

export default withTranslation()(withRouter(SimpleOrderedTable));
