import { AxiosResponse } from 'axios';
import { City } from '../model/city';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { requestParamsFormatter } from '../shared/util/api-utils';
import { api } from './api';

class CityApi {
    static getAllCities = (predicate: Predicate, page: Pageable): Promise<AxiosResponse<PageableResponse<City>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(predicate, page);
        return api.get<PageableResponse<City>>(`/cities?${pageParams}&${filterParams}`);
    };
}

export default CityApi;
