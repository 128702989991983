import AsyncSelect from 'react-select/async';
import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const AsyncSelectContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    .icon-dropdown:before {
        color: ${Colors.lightNavy};
        font-size: 12px;
        cursor: pointer;
        margin-right: 10px;
    }

    .error-label {
        display: flex;
        width: 100%;
        padding-left: 14px;
        span {
            font-size: 12px;
            padding-top: 2px;
            color: ${Colors.coral};
            letter-spacing: 0.54px;
        }
    }
`;

export const AsyncSelectStyles = styled(AsyncSelect)`
    [class$='-control'] {
        overflow: hidden;
        outline: none;
        box-shadow: none;
        height: 56px;
        background: #f6f6f6;

            span {
                display: none;
            }
        }

        [class$='-ValueContainer'] {
            height: 56px;
        }
    }

    &.is-disabled {
        cursor: unset;
        pointer-events: none;
        [class$='-control'] {
            opacity: 0.6;
        }
    }

    &.on-error {
        [class$='-control'] {
            border-top: 1px solid transparent;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 6px;
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top: 2px solid red;
            border-left: 1px solid red;
            border-right: 1px solid red;
            z-index: 1;
        }
    }
    &.on-valid {
        [class$='-control'] {
            border-top: 1px solid transparent;
            background: white;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 6px;
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top: 2px solid #00c391;
            border-left: 1px solid #00c391;
            border-right: 1px solid #00c391;
            z-index: 1;
        }
    }

    &.is-menu-open {
        [class$='-control'] {
            overflow: hidden;
            border-color: #bcbcbc;
            outline: none;
            box-shadow: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            [class$='-ValueContainer'],
            [class$='-IndicatorsContainer'] {
                background-color: white;
                outline: none;
                height: 56px;
            }
        }
    }

  &.open-on-top {
    .css-26l3qy-menu {
      top: auto;
      bottom: 100%
    }
  }

    [class$='-control']:hover {
        border-color: #bcbcbc;
    }

    [class$='-menu'] {
        background: white;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
        box-shadow: none;
        border: 1px solid #bcbcbc;
        top: 99%;
        z-index: 2;
        overflow: hidden;

        [class$='-MenuList'] {
            [class$='-NoOptionsMessage'] {
                text-align: left;
            }
        }
    }
`;
