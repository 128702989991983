import styled from 'styled-components/macro';
import CustomTextField from '../../components/custom-text-field/custom-text-field';
import Colors from '../../styled-components/Colors';

export const SideBarStyle = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    padding-left: 13px;

    &:not(:first-child) {
        border-top: solid 1px ${Colors.veryLightPink};
    }
    &:last-of-type {
        border-top: solid 1px ${Colors.veryLightPink};
        border-bottom: solid 1px ${Colors.veryLightPink};
    }
    &[data-active='true'],
    &:hover,
    &:active {
        border-radius: 8px;
        background-color: #d2edfa;
        font-weight: 600;
        border: none;
        font-size: 1rem;
    }
    &[data-active='true'] + div,
    &:hover + div,
    &:active + div {
        border-top: 1px solid transparent;
    }
`;

export const DataContainer = styled.div`
    font-size: 20px;
    font-weight: 500;
    padding-top: 12px;
    margin-bottom: 18px;
`;

export const CustomTextFieldProfile = styled(CustomTextField)`
    .Mui-disabled {
        border-color: transparent;
    }
    margin-bottom: 6px;
`;

export const SubTitle = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: ${Colors.greyishBrown};
    margin-top: 24px;
    margin-bottom: 12px;
`;
