import styled from 'styled-components/macro';

export const HeaderBody = styled.div`
  width: 100%;
  height: 58px;
  min-height: 58px;
  max-height: 58px;
  background-color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #DADBDD;
`;
