import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import UserApi from '../api/user-api';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { User } from '../model/user';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

class UserService {
    static getAllUsers = async (predicate: Predicate, pageable: Pageable, establishmentId?: number): Promise<PageableResponse<User>> => {
        try {
            const result: AxiosResponse<PageableResponse<User>> = await UserApi.getAllUsers(predicate, pageable, establishmentId);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getAllUsers');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getAllUsers');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static getUser = async (id: number): Promise<User> => {
        try {
            const result: AxiosResponse<User> = await UserApi.getUser(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getUser');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getUser');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static createUser = async (user: User, establishmentId?: number): Promise<User> => {
        try {
            const result: AxiosResponse<User> = await UserApi.createUser(user, establishmentId);
            if (isResponseSuccess(result.data != null, result.status, StatusCodes.CREATED)) {
                ToastUtils.emitSuccessToast('createUser');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('createUser');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('createUser');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static updateUser = async (user: User, establishmentId?: number): Promise<User> => {
        try {
            const result: AxiosResponse<User> = await UserApi.updateUser(user, establishmentId);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('updateUser');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('updateUser');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('updateUser');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static deleteUser = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await UserApi.deleteUser(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('deleteUser');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('deleteUser');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('deleteUser');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static activateUser = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await UserApi.activateUser(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('activateUser');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('activateUser');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('activateUser');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static getMyData = async (): Promise<User> => {
        try {
            const result: AxiosResponse<User> = await UserApi.getMyData();
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getMyData');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getMyData');
            return Promise.reject(error.response as AxiosResponse);
        }
    };
}

export default UserService;