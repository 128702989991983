import styled from 'styled-components/macro';

export const FlexCenteredRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FlexCenteredCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;


