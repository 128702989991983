import styled, { css } from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

function propsForCardContainer(props) {
    const defaultStyle = {
        borderColor: Colors.sunflowerYellow,
        background: 'white',
        borderLeft: Colors.sunflowerYellow
    };
    let additionalStyle: any = {};
    if (props.disabled) {
        additionalStyle = {
            borderColor: 'transparent',
            background: '#EEEEEE'
        };
    }
    if (props.hasNew) {
        additionalStyle = {
            borderColor: Colors.colorSuccessStyle,
        };
    }
    if (props.isError) {
        additionalStyle = {
            borderColor: Colors.coral,
        };
    }
    if (props.isActive) {
        additionalStyle = {
            borderColor: Colors.darkSkyBlue,
        };
    }
    return { ...defaultStyle, ...additionalStyle };
}

export const CardContainer = styled.div.attrs(propsForCardContainer)`
    height: 360px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px ${props => props.borderColor};
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    }

    ${props => props.disabled && css`
        &:before {
            content: '';
            position: absolute;
            top: 42px;
            left: 0;
            right: 0;
            bottom: 0;
            background: white;
            z-index: 1;
            opacity: 0.8;
        }
        .icon-delete_rounded,
        .icon-editar {
            pointer-events: none;
        }
    `}
`;

export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding: 12px;

    .icon-delete_rounded,
    .icon-editar,
    .icon-deletar {
        font-size: 18px;
        margin-left: 14px;
        cursor: pointer;
    }
`;
export const Image = styled.div`
    background-color: gray;
    width: 100%;
    height: 106px;
    overflow: hidden;
    position: relative;

    img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }
`;

export const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.22;
    color: ${Colors.greyishBrown};
    margin: 14px 9px 14px 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`;
export const Points = styled.div`
    font-size: 19px;
    font-weight: 800;
    color: ${Colors.lightNavy};
    padding: 0 20px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.03);
    margin: auto;
    border-radius: 4px;
    line-height: 35px;
`;
export const Description = styled.div`
    font-size: 14px;
    font-weight: 300;
    color: ${Colors.brownGreyTwo};
    margin: 10px 40px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`;
export const SecondDescription = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: ${Colors.greyishBrown};
    margin: 0 29px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`;
