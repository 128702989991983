import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ButtonContainer, ChildrenStyle, ContainerItems, Title, TitleText } from './StyledComponents';

interface ContainerTitleBackProps extends RouteComponentProps {
    titleText?: string;
    children?: ReactNode;
}

export class ContainerTitleBack extends React.Component<ContainerTitleBackProps> {
    render() {
        const { titleText, children } = this.props;

        return (
            <>
                <Title>
                    <Container>
                        <Row>
                            <Col>
                                <ContainerItems>
                                    <ButtonContainer onClick={() => this.props.history.goBack()} className="icon-seta" />
                                    <TitleText>{titleText}</TitleText>
                                    <ChildrenStyle>{children}</ChildrenStyle>
                                </ContainerItems>
                            </Col>
                        </Row>
                    </Container>
                </Title>
            </>
        );
    }
}

export default withRouter(ContainerTitleBack);
