import styled from 'styled-components/macro';
import Colors from '../../styled-components/Colors';

export const ContainerBody = styled.div`
    padding-top: 133px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const Title = styled.div`
    font-size: 1.475rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    color: ${Colors.black};
`;
export const SubTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 26px;
    color: ${Colors.greyishBrown};
    text-align: center;
`;
