import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import LoadingContainerScreen from '../../../components/loading-container-screen/loading-container-screen';
import { convertToAddressLabel } from '../../../model/address';
import { EstablishmentPage } from '../../../model/establishment';
import EstablishmentService from '../../../services/establishment-service';
import pageNotFound from '../../../shared/error/page-not-found';
import AuthUtils from '../../../shared/util/auth-utils';
import StringUtils from '../../../shared/util/string-utils';
import ContainerPage from '../../../styled-components/ContainerPage';
import Convenience from './convenience/convenience';
import Sales from './sales/sales';
import {
    ButtonContainer,
    ContainerInfos,
    ContainerItems,
    EstablishmentItems,
    EstablishmentSection,
    Header,
    Items,
    ItemsBorder,
    LineInfo,
    TitleText
} from './StyledComponents';
import UserList from './user/user';

interface EstablishmentItemRoutesProps extends WithTranslation, RouteComponentProps<{ id: string }> {
    // TODO
}

interface EstablishmentItemRoutesState {
    isLoading: boolean;
    establishment: EstablishmentPage;
}

class EstablishmentItemRoutes extends React.Component<EstablishmentItemRoutesProps, EstablishmentItemRoutesState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            establishment: {}
        }
    }

    componentDidMount() {
        if (AuthUtils.isFgs()) return this.getEstablishment(Number(this.props.match?.params?.id || 0));
        return this.getMyEstablishment();
    }

    getEstablishment = (id: number) => {
        EstablishmentService.getEstablishment(id)
            .then(result => {
                this.setState({
                    establishment: result
                }, () => this.isLoading(false));
            });
    };

    getMyEstablishment = () => {
        EstablishmentService.getMyEstablishment()
            .then(result => {
                this.setState({
                    establishment: result
                }, () => this.isLoading(false));
            });
    };

    redirectToList = () => {
        this.props.history.push('/establishment');
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    renderLoading = () => {
        return (
            <Row className={'base-full-height'}>
                <Col md={12} className={'base-centered-column'}>
                    <div className={'base-access-denied-wrapper'}>
                        <LoadingContainerScreen isLoading children={''} />
                    </div>
                </Col>
            </Row>
        );
    };

    render() {
        const { isLoading, establishment } = this.state;
        const matchUrl: string = this.props.match.url;
        if (isLoading) return this.renderLoading();
        return (
            <ContainerPage>
                <Items>
                    <Header>
                        <Container>
                            <Row>
                                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <ContainerItems>
                                        {AuthUtils.isFgs() && (
                                            <ButtonContainer onClick={this.redirectToList} className="icon-seta" />
                                        )}
                                        <TitleText>{establishment.name || ''}</TitleText>
                                    </ContainerItems>
                                    <ContainerInfos>
                                        <LineInfo>
                                            <div className="icon-pin" />
                                            <span>{convertToAddressLabel(establishment.address || {})}</span>
                                        </LineInfo>
                                        <LineInfo>
                                            <div className="icon-tel" />
                                            <span>{StringUtils.phoneMask(establishment.phone || '')}</span>
                                        </LineInfo>
                                        <LineInfo>
                                            <div className="icon-email" />
                                            <span>{establishment.email || ''}</span>
                                        </LineInfo>
                                    </ContainerInfos>
                                </Col>
                            </Row>
                        </Container>
                    </Header>
                    <ItemsBorder />
                    <Container>
                        <Row>
                            <Col>
                                <EstablishmentItems>
                                    <EstablishmentSection active={Boolean(window.location.href.match('/convenience'))}>
                                        <Link replace to={`${matchUrl}/convenience`}>{this.props.t('establishment.items.header.convenience')}</Link>
                                    </EstablishmentSection>
                                    <EstablishmentSection active={Boolean(window.location.href.match('/sales'))}>
                                        <Link replace to={`${matchUrl}/sales`}>{this.props.t('establishment.items.header.sales')}</Link>
                                    </EstablishmentSection>
                                    <EstablishmentSection active={Boolean(window.location.href.match('/user'))}>
                                        <Link replace to={`${matchUrl}/user`}>{this.props.t('establishment.items.header.user')}</Link>
                                    </EstablishmentSection>
                                </EstablishmentItems>
                            </Col>
                        </Row>
                    </Container>
                </Items>
                <Switch>
                    <Container>
                        <Row>
                            <Col>
                                <Switch>
                                    <Route exact path={`${matchUrl}/convenience`} component={() => <Convenience establishmentId={this.props.match?.params?.id} />} />
                                    <Route exact path={`${matchUrl}/sales`} component={() => <Sales establishmentId={this.props.match?.params?.id} />}  />
                                    <Route exact path={`${matchUrl}/user`} component={() => <UserList establishmentId={this.props.match?.params?.id} />} />
                                    <Route path={'*'} component={pageNotFound} />
                                </Switch>
                            </Col>
                        </Row>
                    </Container>
                </Switch>
            </ContainerPage>
        );
    }
}

export default withTranslation()(EstablishmentItemRoutes);
