import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ButtonLeft, ButtonRight } from '../../features/establishment/StyledComponents';
import {
    BodyText,
    BodyTitle,
    ButtonContainer,
    DeleteAlertModalContainer,
    DeleteAlertModalItems
} from './StyledComponents';

interface IDeleteAlertModalProps extends WithTranslation {
    title?: string;
    label?: string;
    isOpen: boolean;
    isDisabledOption?: boolean;
    onCancelAction: () => void;
    isInternalLoading?: boolean;
    onConfirmAction: () => void;
}

class DeleteAlertModal extends React.Component<IDeleteAlertModalProps, any> {
    render() {
        const {
            isOpen,
            title,
            onCancelAction,
            onConfirmAction,
            label,
            isInternalLoading,
        } = this.props;
        const backdropProps = { timeout: 500 };
        const onCloseAction = !isInternalLoading ? onCancelAction : void 0;
        return (
            <DeleteAlertModalContainer
                open={isOpen}
                closeAfterTransition
                onClose={onCloseAction}
                BackdropComponent={Backdrop}
                BackdropProps={backdropProps}
            >
                <Fade in={isOpen}>
                    <DeleteAlertModalItems>
                        <div className="icon-fechar" onClick={onCloseAction} />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <BodyTitle>{title || ''}</BodyTitle>
                        </div>
                        {/* tslint:disable-next-line:jsx-key */}
                        {label && label.split('\n').map((item, index) => <BodyText>{item}</BodyText>)}
                        <ButtonContainer>
                            <ButtonLeft onClick={onCloseAction} disabled={isInternalLoading} color="white" type="submit">
                                {this.props.t('global.button.cancel')}
                            </ButtonLeft>
                            <ButtonRight onClick={onConfirmAction} isLoading={isInternalLoading} color="red" type="submit">
                                {this.props.t('global.button.delete')}
                            </ButtonRight>
                        </ButtonContainer>
                    </DeleteAlertModalItems>
                </Fade>
            </DeleteAlertModalContainer>
        );
    }
}

export default withTranslation()(DeleteAlertModal);
