import classNames from 'classnames';
import React from 'react';
import './custom-checkbox.scss';

export interface ICustomCheckboxProps {
    style?: any;
    title?: string;
    checked: boolean;
    isDisabled?: boolean;
    onChange: () => void;
    divClassName?: string;
    value?: string | number;
}

class CustomCheckbox extends React.Component<ICustomCheckboxProps> {
    render() {
        const { isDisabled, divClassName, onChange, style, checked, title } = this.props;
        const isDisabledClassName = isDisabled ? 'is-disabled' : '';
        return (
            <div style={style} className={classNames([divClassName, isDisabledClassName, 'custom-check-box-wrapper'])}>
                <div className={'custom-check-box-wrap'} onClick={onChange}>
                    {checked && (<img src={require('../../images/ok.svg')} alt={''} />)}
                </div>
                {title && <span className={checked ? 'checked-text-color' : ''}>{title}</span>}
            </div>
        );
    }
}

export default CustomCheckbox;
