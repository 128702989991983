import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Filters, FilterValue } from '../../../../components/filter/filter';
import SalesCard from '../../../../components/sales-card/sales-card';
import LoadingContainerScreen from '../../../../components/loading-container-screen/loading-container-screen';
import DeleteAlertModal from '../../../../components/delete-alert-modal/delete-alert-modal';
import { AsyncSelectResponse } from '../../../../model/async-select';
import { Pageable } from '../../../../model/pageable';
import { Predicate } from '../../../../model/predicate';
import { Sale } from '../../../../model/sale';
import SaleService from '../../../../services/sale-service';
import AuthUtils from '../../../../shared/util/auth-utils';
import { ButtonRight } from '../../StyledComponents';
import { EstablishmentTitleContainer } from '../StyledComponents';
import SaleCreateOrEditModal from './sale-create-or-edit-modal/sale-create-or-edit-modal';

interface ISalesProps extends WithTranslation {
    establishmentId: number | string;
}

interface ISalesState {
    page: Pageable;
    isLoading: boolean;
    isDeleting: boolean;
    predicate: Predicate;
    deleteModalId: number | null;
    alertModalRoutesList: string[];
    isSaleModalOpen: boolean;
    isOpenActionModal: number | null;
    sale: Sale[];
    saleModalId: number | null;
    isAlertModalOpenValue: string | null;
    filterValue: FilterValue;
}

class Sales extends React.Component<ISalesProps, ISalesState> {
    $defaultPath: string;
    constructor(props) {
        super(props);
        this.state = {
            predicate: {},
            sale: [],
            isLoading: true,
            isDeleting: false,
            deleteModalId: null,
            isOpenActionModal: null,
            saleModalId: null,
            isAlertModalOpenValue: null,
            isSaleModalOpen: false,
            alertModalRoutesList: ['/new', '/edit'],
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'id,desc' },
            filterValue: {
                TYPE: {}
            }
        };
        this.$defaultPath = '';
    }

    componentDidMount() {
        this.getAllSales().then(callback => callback);
    }

    resetPage = () => {
        this.setState({
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'id,asc' }
        });
    };

    updatePage = async (key: string, event: number | string) => {
        this.setState({
            page: {
                ...this.state.page,
                [key]: event
            }
        });
    };

    getAllSales = async (shouldResetPage?: boolean) => {
        if (shouldResetPage) this.resetPage();

        const { establishmentId } = this.props;
        const { predicate, page } = this.state;

        this.isLoading(true);

        const establishmentIdValidation = AuthUtils.isFgs() ? Number(establishmentId) : undefined;

        SaleService.getAllSales(predicate, page, establishmentIdValidation).then(result =>
            this.setState(
                {
                    sale: result.content,
                    page: {
                        ...this.state.page,
                        size: result.size,
                        page: result.number,
                        totalPages: result.totalPages,
                        totalElements: result.totalElements
                    }
                },
                async () => {
                    this.isLoading(false);
                    if (isEmpty(this.state.sale) && this.state.page.page) {
                        await this.updatePage('page', this.state.page.page - 1);
                        await this.getAllSales();
                    }
                }
            )
        );
    };

    updateFilterValue = (filterType: Filters, event: AsyncSelectResponse) => {
        this.setState({
            filterValue: {
                ...this.state.filterValue,
                [filterType]: event
            }
        });
    };

    isSaleModalOpen = (isSaleModalOpen: boolean, saleModalId: number | null) => {
        this.setState({
            isSaleModalOpen,
            saleModalId
        });
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    isDeleting = (isDeleting: boolean) => {
        this.setState({
            isDeleting
        });
    };

    deleteModalId = (deleteModalId: number | null) => {
        this.setState({
            deleteModalId
        });
    };

    deleteSale = (id: number) => {
        this.isDeleting(true);
        SaleService.deleteSale(id)
            .then(() => {
                this.isDeleting(false);
                this.deleteModalId(null);
                this.getAllSales(false).then(callback => callback);
            })
            .catch(() => this.isDeleting(false))
    };

    activateSale = (id: number) => {
        this.isLoading(true);
        SaleService.activateSale(id)
            .then(() => {
                this.getAllSales().then(() => {
                    this.isLoading(false);
                });
            })
            .catch(() => this.isLoading(false));
    };

    renderLoading = () => {
        return (
            <Row style={{ display: 'flex', width: '100%', height: '300px' }}>
                <Col md={12} className={'base-centered-column'}>
                    <div className={'base-access-denied-wrapper'}>
                        <LoadingContainerScreen isLoading children={''} />
                    </div>
                </Col>
            </Row>
        );
    };


    renderCard = (item: Sale) => {
        return (
            <Col md="3" >
                <SalesCard
                    id={item.id}
                    title={item?.name}
                    points={item?.pointAmount}
                    description={item?.description}
                    secondDescription={item?.promotionDescription}
                    image={item?.image?.url}
                    onDeleteClick={() => this.deleteModalId(item.id!)}
                    onEditClick={() => this.isSaleModalOpen(true, item.id!)}
                    onHandleToggle={() => this.activateSale(item.id!)}
                    disabled={!item.active}               
                />
            </Col>
        );
    };

    render() {
        const { t, establishmentId } = this.props;
        const { isSaleModalOpen, saleModalId, deleteModalId, isDeleting, sale } = this.state;

        return (
            <Fragment>
                <>
                    <EstablishmentTitleContainer>
                        {/* <Filter value={filterValue} onChange={this.updateFilterValue} filters={[Filters.TYPE]} /> */}
                        <ButtonRight style={{ width: '184px', fontSize: '14px' }} onClick={() => this.isSaleModalOpen(true, null)} size="37px">
                            {this.props.t('establishment.items.sales.button')}
                        </ButtonRight>
                    </EstablishmentTitleContainer>
                    <div>
                        <Row>
                            {sale.map(sale => this.renderCard(sale))}
                        </Row>
                    </div>
                    <SaleCreateOrEditModal
                        isOpen={isSaleModalOpen}
                        id={saleModalId ?? undefined}
                        onSaveAction={this.getAllSales}
                        onCancelAction={() => this.isSaleModalOpen(false, null)}
                        establishmentId={AuthUtils.isFgs() ? Number(establishmentId) : undefined}
                    />
                    <DeleteAlertModal
                        isOpen={deleteModalId != null}
                        isInternalLoading={isDeleting}
                        title={t('sale.deleteTitle')}
                        onCancelAction={() => this.deleteModalId(null)}
                        onConfirmAction={() => this.deleteSale(deleteModalId!)}
                    />
                </>
            </Fragment>
        );
    }
}

export default withTranslation()(Sales);
