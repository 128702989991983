import { HttpRequestStatus } from '../../model/enums/http-request-status';
import { User } from '../../model/user';

export type ApiResponse = Record<string, any>;

export enum AuthenticationActionTypes {
  LOGIN_ERROR = '@@authentication/LOGIN_ERROR',
  LOGIN_REQUEST = '@@authentication/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@authentication/LOGIN_SUCCESS',
  LOGOUT_REQUEST = '@@authentication/LOGOUT_REQUEST',
  GET_SESSION_ERROR = '@@authentication/GET_SESSION_ERROR',
  GET_SESSION_REQUEST = '@@authentication/GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS = '@@authentication/GET_SESSION_SUCCESS'
}

export interface AuthenticationState {
  readonly account?: User;
  readonly error?: string;
  readonly isAuthenticated: boolean;
  readonly loginStatus: HttpRequestStatus;
  readonly getSessionStatus: HttpRequestStatus;
}
