import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/button/button';
import { ContainerTitle } from '../../components/container-title/container-title';
import Icon from '../../components/icon/icon';
import SimpleOrderedTable, { ColumnSort } from '../../components/simple-ordered-table/simple-ordered-table';
import { convertToAddressLabel } from '../../model/address';
import { Status } from '../../model/enums/status';
import { EstablishmentPage } from '../../model/establishment';
import { Pageable } from '../../model/pageable';
import { Predicate } from '../../model/predicate';
import EstablishmentService from '../../services/establishment-service';
import AuthUtils from '../../shared/util/auth-utils';
import { maskPhone } from '../../shared/util/mask-utils';
import ContainerPage from '../../styled-components/ContainerPage';

interface IEstablishmentProps extends RouteComponentProps, WithTranslation {
    // TODO
}

interface IEstablishmentState {
    page: Pageable;
    isLoading: boolean;
    predicate: Predicate;
    isActionLoading: boolean;
    isOpenActionModal: number | null;
    establishment: EstablishmentPage[];
    isOpenActionModalIsArchive: boolean;
}

class Establishment extends React.Component<IEstablishmentProps, IEstablishmentState> {
    $defaultPath: string;
    constructor(props: Readonly<IEstablishmentProps>) {
        super(props);
        this.$defaultPath = '';
        this.state = {
            predicate: {},
            isLoading: false,
            establishment: [],
            isActionLoading: false,
            isOpenActionModal: null,
            isOpenActionModalIsArchive: false,
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'name,asc' }
        };
    }

    componentDidMount() {
        this.getAllEstablishment().then(callback => callback);
    }

    resetPage = () => {
        this.setState({
            page: { size: 10, page: 0, totalElements: 0, totalPages: 0, sort: 'name,asc' }
        });
    };

    updatePage = async (key: string, event: number | string) => {
        this.setState({
            page: {
                ...this.state.page,
                [key]: event
            }
        });
    };

    getAllEstablishment = async (shouldResetPage?: boolean) => {
        if (shouldResetPage) this.resetPage();

        const { predicate, page } = this.state;

        this.isLoading(true);

        EstablishmentService.getAllEstablishments(predicate, page).then(result =>
            this.setState(
                {
                    establishment: result.content,
                    page: {
                        ...this.state.page,
                        size: result.size,
                        page: result.number,
                        totalPages: result.totalPages,
                        totalElements: result.totalElements
                    }
                },
                async () => {
                    this.isLoading(false);
                    if (isEmpty(this.state.establishment) && this.state.page.page) {
                        await this.updatePage('page', this.state.page.page - 1);
                        await this.getAllEstablishment();
                    }
                }
            )
        );
    };

    activateEstablishment = (id: number) => {
        this.isActionLoading(true);
        this.isOpenActionModal(id, true);
        EstablishmentService.activateEstablishment(id)
            .then(() => {
                this.getAllEstablishment().then(() => {
                    this.isActionLoading(false);
                    this.isOpenActionModal(null, true);
                });
            })
            .catch(() => this.isActionLoading(false));
    };

    isActionLoading = (isActionLoading: boolean) => {
        this.setState({
            isActionLoading
        });
    };

    isOpenActionModal = (id: number | null, isOpenActionModalIsArchive: boolean) => {
        this.setState({
            isOpenActionModal: id,
            isOpenActionModalIsArchive
        });
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: { ...this.state.page, page: newPage }
            },
            () => this.getAllEstablishment()
        );
    };

    handleClickRow = (id: number) => {
        const it = this.state.establishment[id];
        this.props.history.push(`/establishment/${it.id}/convenience`);
    };

    handleSort = (code: string) => {
        this.setState(
            {
                page: { ...this.state.page, sort: code }
            },
            () => this.getAllEstablishment()
        );
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    tableHeaders: ColumnSort[] = [
        { name: this.props.t('establishment.table.name'), sortCode: 'name' },
        { name: this.props.t('establishment.table.contact'), sortCode: 'email' },
        { name: this.props.t('establishment.table.type'), sortCode: 'category' },
        { name: this.props.t('establishment.table.status'), sortCode: 'status' },
        { sortDisabled: true }
    ];

    handleTransformToTableContent = (content?: EstablishmentPage[]): (string | JSX.Element)[][] => {
        const { t } = this.props;
        if (content == null || content.length === 0) return [];
        const result: (string | JSX.Element)[][] = [];
        content.forEach((item: EstablishmentPage, index: number) => {
            result.push([
                this.renderEstablishmentName(item),
                this.renderContacts(item),
                item.category ? t(`enum.establishmentCategory.${item.category}`) : t('global.emptyLabel'),
                this.renderStatus(item.status || Status.INACTIVE),
                this.renderLastItems(index, item)
            ]);
        });
        return result;
    };

    renderStatus = (status: Status) => {
        const { t } = this.props;
        const statusClassName = status === Status.ACTIVE ? 'is-active' : 'is-inactive';
        return (
            <div className={classNames([statusClassName, 'status-list-item'])}>
                <span>
                    {t(`enum.status.${status}`)}
                </span>
            </div>
        );
    };

    renderContacts = (item: EstablishmentPage) => {
        const { t } = this.props;
        return (
            <div className={'column-list-item is-contacts'}>
                <span>{item.phone ? maskPhone(item.phone) : t('global.emptyLabel')}</span>
                <span>{item.email || t('global.emptyLabel')}</span>
            </div>
        );
    };

    renderEstablishmentName = (item: EstablishmentPage) => {
        const { t } = this.props;
        return (
            <div className={'row-list-item'}>
                <Icon isDisabled={item.status !== Status.ACTIVE} type={item.category}/>
                <div className={'column-list-item is-establishment'}>
                    <span>{item.name || t('global.emptyLabel')}</span>
                    <span>{item.address ? convertToAddressLabel(item.address) : t('global.emptyLabel')}</span>
                </div>
            </div>
        );
    };

    renderLastItems = (index: number, item: EstablishmentPage) => {
        const { isActionLoading } = this.state;
        const deleteClassName = item.status === Status.ACTIVE ? 'icon-deletar' : 'icon-deletar-reverter';
        const isDisabledClassName = isActionLoading ? 'is-item-disabled' : '';
        return (
            <div key={index} style={{ display: 'flex', justifyContent: 'center' }} className={isDisabledClassName}>
                {item.status === Status.INACTIVE ? (
                    <i style={{ opacity: 0.5, marginRight: '17px' }} className="icon-editar action-list-icon" />
                ) : (
                    <i
                        className="icon-editar action-list-icon"
                        onClick={() => this.props.history.push(`/establishment/edit/${item.id}`)}
                        style={{ marginRight: '17px', cursor: 'pointer' }}
                    />
                )}
                {AuthUtils.isAdmin() && (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.activateEstablishment(item.id!)}
                        className={classNames([deleteClassName, isDisabledClassName, 'action-list-icon'])}
                    />
                )}
            </div>
        );
    };

    render() {
        const { establishment, isLoading } = this.state;
        const disabledRows: boolean[] = establishment?.map(item => item.status === Status.INACTIVE);
        const tableContent: (string | JSX.Element)[][] = this.handleTransformToTableContent(establishment);


        return (
            <Fragment>
                <ContainerPage>
                    <ContainerTitle
                        titleText={this.props.t('establishment.title')}
                        buttonContainer={
                            AuthUtils.isAdmin() ? (
                                <Button
                                    size="36px"
                                    type="submit"
                                    style={{ width: '183px', fontSize: '14px' }}
                                    onClick={() => this.props.history.push(`/establishment/new`)}
                                >
                                    {this.props.t('establishment.button')}
                                </Button>
                            ) : <div />
                        }
                    />
                    <Container>
                        <Row>
                            <Col style={{ marginTop:'30px'}}>
                                <SimpleOrderedTable
                                    rows={tableContent}
                                    isLoading={isLoading}
                                    page={this.state.page}
                                    disabledRows={disabledRows}
                                    columnNameKeys={this.tableHeaders}
                                    onChangePage={this.handleChangePage}
                                    onSort={(code: string) => this.handleSort(code)}
                                    onClickRow={(index) => this.handleClickRow(index)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ContainerPage>
            </Fragment>
        );
    }
}

export default withTranslation()(Establishment);
