import React from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/button/button';
import { ContainerBody, SubTitle, Title, ConclusionImgContainer } from './StyledComponents';
import Footer from '../footer/footer';
import Header from '../../components/header-white/header';
import ConclusionImg from '../../images/conclusion.svg';

export interface ForgotPasswordProps {
    t: any;
}

const RecoverPasswordSucess = (props: ForgotPasswordProps): JSX.Element => {
    const history = useHistory();

    return (
        <div>
            <Header />
            <Container>
                <Row>
                    <Col md={{ size: '4', offset: '4' }}>
                        <ContainerBody>
                            <Title>{props.t('recover-password-sucess.title')}</Title>
                            <ConclusionImgContainer>
                                <img style={{ width: '52px' }} src={ConclusionImg} alt=''/>
                            </ConclusionImgContainer>
                            <SubTitle>
                                <div style={{ maxWidth: '250px', margin: 'auto' }}>
                                    <div style={{ fontSize:'14px'}}></div>{props.t('recover-password-sucess.subtitle')}
                                    <div style={{ marginTop: '14px' }}>{props.t('recover-password-sucess.subtitleSecond')}</div>
                                </div>
                            </SubTitle>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    style={{ width: '175px', height: '44px', fontSize: '14px' }}
                                    onClick={() => history.push('/forgot-password')}
                                    type="submit"
                                >
                                    {props.t('recover-password-sucess.sendAgain')}
                                </Button>
                            </div>
                        </ContainerBody>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default withTranslation()(RecoverPasswordSucess) as React.ComponentType<any>;
