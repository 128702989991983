import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import EstablishmentApi from '../api/establishment-api';
import { EstablishmentPage } from '../model/establishment';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { isResponseSuccess } from '../shared/util/api-utils';
import ToastUtils from '../shared/util/toast-utils';

class EstablishmentService {
    static getAllEstablishments = async (predicate: Predicate, pageable: Pageable): Promise<PageableResponse<EstablishmentPage>> => {
        try {
            const result: AxiosResponse<PageableResponse<EstablishmentPage>> = await EstablishmentApi.getAllEstablishments(predicate, pageable);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getAllEstablishments');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getAllEstablishments');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static getEstablishment = async (id: number): Promise<EstablishmentPage> => {
        try {
            const result: AxiosResponse<EstablishmentPage> = await EstablishmentApi.getEstablishment(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getEstablishment');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getEstablishment');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static getMyEstablishment = async (): Promise<EstablishmentPage> => {
        try {
            const result: AxiosResponse<EstablishmentPage> = await EstablishmentApi.getMyEstablishment();
            if (isResponseSuccess(result.data != null, result.status)) {
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('getEstablishment');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getEstablishment');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static createEstablishment = async (establishment: EstablishmentPage): Promise<EstablishmentPage> => {
        try {
            const result: AxiosResponse<EstablishmentPage> = await EstablishmentApi.createEstablishment(establishment);
            if (isResponseSuccess(result.data != null, result.status, StatusCodes.CREATED)) {
                ToastUtils.emitSuccessToast('createEstablishment');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('createEstablishment');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('createEstablishment');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static updateEstablishment = async (establishment: EstablishmentPage): Promise<EstablishmentPage> => {
        try {
            const result: AxiosResponse<EstablishmentPage> = await EstablishmentApi.updateEstablishment(establishment);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('updateEstablishment');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('updateEstablishment');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('updateEstablishment');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static activateEstablishment = async (id: number): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await EstablishmentApi.activateEstablishment(id);
            if (isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitSuccessToast('activateEstablishment');
                return Promise.resolve(result.data);
            }
            ToastUtils.emitErrorToast('activateEstablishment');
            return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);

        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('activateEstablishment');
            return Promise.reject(error.response as AxiosResponse);
        }
    };
}

export default EstablishmentService;
