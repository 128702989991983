import styled from 'styled-components/macro';
import AsyncSelectField from '../../components/async-select-field/async-select-field';
import Button from '../../components/button/button';
import CustomTextField from '../../components/custom-text-field/custom-text-field';
import SelectField from '../../components/select-field/select-field';
import Colors from '../../styled-components/Colors';

export const ContainerBody = styled.div`
    height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.div`
    font-size: 1.375rem;
    font-weight: 800;
    margin-bottom: 20px;
    color: ${Colors.greyishBrown};
`;

export const SubTitle = styled.div`
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 26px;
    color: ${Colors.greyishBrown};
`;

export const SectionTitle = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    color: ${Colors.greyishBrown};
    text-align: left;
    margin-top: 20px;
    margin-bottom: 16px;
`;

export const SectionTitleAdress = styled(SectionTitle)`
    margin-top: 36px;
    border-bottom: 1px solid ${Colors.sunflowerYellow};
    height: 28px;
`;

export const CustomTextFieldData = styled(CustomTextField)`
    & div:not(.Mui-focused) > input {
        opacity: 0;
    }
    & .filled div input {
        opacity: 1;
    }

    .MuiFilledInput-input {
        padding: 15px 12px 16px;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        margin: 0;
        position: absolute;
        bottom: 9px;
        right: 10px;
        cursor: pointer;
    }
    input[type='date']::-webkit-datetime-edit {
        margin-top: 1em;
    }

    .filled div input {
        border-top: 2px solid #00c391;
        background: white !important;
    }
`;

export const CustomTextFieldMargin = styled(CustomTextField)`
    margin-top: 20px;
`;

export const CustomTextFieldHeight = styled(CustomTextField)`
    margin-top: 20px;
    input {
        height: 40px;
    }
`;

export const CustomTextFieldMarginPadding = styled(CustomTextField)`
    margin-top: 20px;

    .MuiFilledInput-input {
    padding: 36px 12px 8px;
`;

export const AsyncSelectedFieldMargin = styled(AsyncSelectField)`
    margin-top: 20px;
`;

export const SelectedFieldMargin = styled(SelectField)`
    margin-top: 20px;
`;

export const ButtonContainer = styled.div`
    margin-top: 44px;
    margin-bottom: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonLeft = styled(Button)`
    margin-right: 13px;
`;

export const ButtonRight = styled(Button)`
    margin-left: 13px;
`;
