import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './toast-body-element.scss';

interface IToastBodyElementProps extends WithTranslation {
    message: string;
    toastType: ToastType;
}

export enum ToastType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    CONNECTION = 'CONNECTION'
}

class ToastBodyElement extends React.Component<IToastBodyElementProps> {
    $defaultPath: string;

    constructor(props) {
        super(props);
        this.$defaultPath = 'toast';
    }

    complementPath = () => {
        const { toastType } = this.props;
        switch (toastType) {
            case ToastType.ERROR:
                return 'error';

            case ToastType.SUCCESS:
                return 'success';

            case ToastType.CONNECTION:
                return 'connection';

            default:
                return '';
        }
    };

    render() {
        const { t, message } = this.props;
        return (
            <div className={'toast-body-element-wrapper'}>
                <img src={require(`../../images/${this.complementPath()}.svg`)} alt={''} />
                <span>
                    {t(`${this.$defaultPath}.${this.complementPath()}.${message}`)}
                </span>
            </div>
        );
    }
}

export default withTranslation()(ToastBodyElement);
