export const colorSuccessStyle = '#00c391';
export const darkGreyBlue = '#26284f';
export const lightNavy = '#143d66';
export const bluish = '#1e7ac9';
export const darkSkyBlue = '#34bfff';
export const sunflower = '#fdd00f';
export const coral = '#fa5454';
export const black = '#000000';
export const greyishBrown = '#4a4a4a';
export const blueyGrey = '#9199a1';
export const veryLightPink = '#eeeeee';
export const white = '#ffffff';
export const sunflowerYellow = '#fed100';
export const yellowOrange = '#f7b500';
export const brownGrey = '#acacac';
export const cloudyBlue = '#c3c7cb';
export const veryLightPinkTwo = '#e5e5e5';
export const brownGreyTwo = '#808080';
export const saffron = '#fdb50f';
export const brownGreyThree= '#7b7b7b';

const Colors = {
    colorSuccessStyle,
    darkGreyBlue,
    lightNavy,
    bluish,
    darkSkyBlue,
    sunflower,
    coral,
    black,
    greyishBrown,
    blueyGrey,
    veryLightPink,
    white,
    sunflowerYellow,
    yellowOrange,
    brownGrey,
    cloudyBlue,
    veryLightPinkTwo,
    brownGreyTwo,
    saffron,
    brownGreyThree,
};
export default Colors;
