import { Search } from '../../model/enums/search';

export enum SearchActionTypes {
  SAVE_SEARCH = '@@search/SAVE_SEARCH',
}

export interface SearchState {
  search?: Search;
  test?: boolean;
}
