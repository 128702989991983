import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../model/enums/http-request-status';
import { RegisterActionTypes, RegisterState } from './types';

export const initialState: RegisterState = {
  registerStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<RegisterState> = (state = initialState, action) => {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_REQUEST: {
      return { ...state, registerStatus: HttpRequestStatus.ON_GOING };
    }
    case RegisterActionTypes.REGISTER_SUCCESS: {
      return { ...state, registerStatus: HttpRequestStatus.SUCCESS };
    }
    case RegisterActionTypes.REGISTER_ERROR: {
      return { ...state, registerStatus: HttpRequestStatus.ERROR, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as registerReducer };
