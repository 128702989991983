import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import LoadingContainerScreen from '../../../../../components/loading-container-screen/loading-container-screen';
import { SelectOption } from '../../../../../components/select-field/select-field';
import { ButtonContainer } from '../../../../../components/simple-alert-modal/StyledComponents';
import { UserType, UserTypeEstablishment } from '../../../../../model/enums/user-type';
import { User } from '../../../../../model/user';
import { defaultValue, ErrorAndMessage, Validation } from '../../../../../model/validation';
import UserService from '../../../../../services/user-service';
import AuthUtils from '../../../../../shared/util/auth-utils';
import StringUtils from '../../../../../shared/util/string-utils';
import { entityValidator } from '../../../../../shared/util/validator-utils';
import { ButtonLeft, ButtonRight, CustomTextFieldMargin, SelectedFieldMargin } from '../../../StyledComponents';
import './user-create-or-edit-modal.scss';

interface IUserCreateOrEditModalProps extends WithTranslation {
    id?: number;
    isOpen?: boolean;
    onSaveAction: () => void;
    establishmentId?: number;
    onCancelAction: () => void;
    isAdmin?: boolean;
}

interface IUserCreateOrEditModalState {
    isSaving: boolean;
    isLoading: boolean;
    user: User;
    userValidation: {
        name?: Validation[];
        email?: Validation[];
        phone?: Validation[];
        authorities?: Validation[];
    };
    userError: {
        name: ErrorAndMessage;
        email: ErrorAndMessage;
        phone: ErrorAndMessage;
        authorities: ErrorAndMessage;
    };
}

class UserCreateOrEditModal extends React.Component<IUserCreateOrEditModalProps, IUserCreateOrEditModalState> {
    $defaultPath: string;
    $userAuthorities: SelectOption[];

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: false,
            user: {},
            userValidation: {
                name: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.name') }],
                email: [{ handler: event => StringUtils.isEmailInvalid(event[0]), message: this.props.t('errors.email.valid') }],
                phone: [{ handler: event => !StringUtils.isSizeBetween(10, 11, event[0]), message: this.props.t('errors.phone') }],
                authorities: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.type') }],
            },
            userError: {
                name: defaultValue,
                email: defaultValue,
                phone: defaultValue,
                authorities: defaultValue,
            }
        };
        this.$defaultPath = '';
        this.$userAuthorities = this.setUserAuthorities();
    }

    componentWillReceiveProps(nextProps: Readonly<IUserCreateOrEditModalProps>) {
        if (nextProps.id != null && (nextProps.id !== this.props.id)) this.getUser(nextProps.id);
    }

    closeAndReset = async () => {
        await this.resetAll();
        this.props.onCancelAction();
    };

    resetAll = async () => {
        this.setState({
            user: {},
            userError: {
                name: defaultValue,
                email: defaultValue,
                phone: defaultValue,
                authorities: defaultValue,
            }
        });
    };

    setUserAuthorities = () => {
        const { isAdmin } = this.props;
        return Object.values(isAdmin ? UserType : UserTypeEstablishment)
            .map(userAuthorities => {
                return {
                    value: userAuthorities,
                    label: this.props.t(`enum.userType.${userAuthorities}`)
                }
            });
    };


    getUser = (id: number) => {
        this.isLoading(true);
        UserService.getUser(id)
            .then(result => {
                this.setState({
                    user: result
                }, () => this.isLoading(false));
            })
            .catch(() => {
                this.isLoading(false);
                this.closeAndReset().then(callback => callback);
            })
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    isSaving = (isSaving: boolean) => {
        this.setState({
            isSaving
        });
    };

    validateLabel = (key: string) => {
        const { userValidation } = this.state;
        this.setState({
            userError: {
                ...this.state.userError,
                [key]: entityValidator(this.getValidationEvents(key), userValidation[key])
            }
        });
    };

    userAction = () => {
        if (this.state.user.id != null) return this.updateUser();
        return this.createUser();
    };

    updateUser = () => {
        let user = { ...this.state.user };
        const { establishmentId, isAdmin } = this.props;

        this.isSaving(true);

        const establishmentIdValidation = isAdmin ? undefined : AuthUtils.isFgs() ? Number(establishmentId) : undefined;

        UserService.updateUser(user, establishmentIdValidation)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };

    createUser = () => {
        let user = { ...this.state.user };
        const { establishmentId, isAdmin } = this.props;

        this.isSaving(true);

        const establishmentIdValidation = isAdmin ? undefined : AuthUtils.isFgs() ? Number(establishmentId) : undefined;

        UserService.createUser(user, establishmentIdValidation)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };

    globalValidateLabels = () => {
        const newUserError = { ...this.state.userError };
        const { userValidation } = this.state;

        Object.keys(userValidation).forEach(key =>
            newUserError[key] = entityValidator(this.getValidationEvents(key), userValidation[key]));

        this.setState({
            userError: newUserError
        }, () => {
            if (this.findAnyError()) return;
            this.userAction();
        });
    };

    findAnyError = () => {
        return Object.keys(this.state.userError).filter(key => this.state.userError[key]?.value).length > 0;
    };

    getValidationEvents = (key: string) => {
        const { user } = this.state;
        switch (key) {
            case 'name':
                return [[user?.name]];

            case 'email':
                return [[user?.email]];

            case 'phone':
                return [[user?.phone]];

            case 'authorities':
                return [[user?.authorities]];

            default:
                return [[false]]
        }
    };

    updateLabel = (event: any, key: string) => {
        this.setState({
            user: {
                ...this.state.user,
                [key]: event
            }
        });
    };

    updateAuthorities = (event: any, key: string) => {
        this.setState({
            user: {
                ...this.state.user,
                [key]: [event]
            }
        });
    };

    render() {
        const { t, isOpen } = this.props;
        const { user, isLoading, isSaving, userError } = this.state;
        const backdropProps = { timeout: 500 };
        const convenienceClassName = isLoading ? 'is-loading' : '';
        const onCloseAction = !isLoading && !isSaving ? this.closeAndReset : void (0);

        return (
            <Modal
                closeAfterTransition
                open={isOpen || false}
                onClose={onCloseAction}
                BackdropComponent={Backdrop}
                BackdropProps={backdropProps}
                className={'convenience-create-or-edit-wrapper'}
            >
                <Fade in={isOpen}>
                    <div className={classNames([convenienceClassName, 'convenience-create-or-edit-wrap'])}>
                        <LoadingContainerScreen isLoading={isLoading}>
                            <div className="icon-fechar" onClick={onCloseAction} />
                            <span className={'body-title'}>
                                {t(user.id != null ? 'profile.users.editUser' : 'profile.users.newUser')}
                            </span>
                            <div className={'middle-items'}>
                                <CustomTextFieldMargin
                                    id={StringUtils.randomString()}
                                    value={user.name}
                                    error={userError.name?.value}
                                    errorText={userError.name?.message}
                                    onBlur={() => this.validateLabel('name')}
                                    placeholder={t('establishment.textField.username')}
                                    onChange={event => this.updateLabel(event, 'name')}
                                />
                                <CustomTextFieldMargin
                                    value={user.email}
                                    id={StringUtils.randomString()}
                                    error={userError.email?.value}
                                    errorText={userError.email?.message}
                                    onBlur={() => this.validateLabel('email')}
                                    placeholder={t('establishment.textField.email')}
                                    onChange={event => this.updateLabel(event, 'email')}
                                />
                                <CustomTextFieldMargin
                                    value={user.phone}
                                    id={StringUtils.randomString()}
                                    error={userError.phone?.value}
                                    errorText={userError.phone?.message}
                                    onBlur={() => this.validateLabel('phone')}
                                    placeholder={t('establishment.textField.phone')}
                                    onChange={event => this.updateLabel(event, 'phone')}
                                    style={{ marginBottom: '20px' }}
                                />
                                <SelectedFieldMargin
                                    id={StringUtils.randomString()}
                                    dataArray={this.$userAuthorities}
                                    selected={user.authorities != null}
                                    className={'select-field-reference'}
                                    error={userError.authorities?.value}
                                    initialValue={user?.authorities || ''}
                                    errorText={userError.authorities?.message}
                                    onBlur={() => this.validateLabel('authorities')}
                                    onChange={event => this.updateAuthorities(event, 'authorities')}
                                    placeholder={t('establishment.textField.authorities')}
                                />
                            </div>
                            <ButtonContainer>
                                <ButtonLeft onClick={onCloseAction} disabled={isSaving} color="white" type="submit">
                                    {t('global.button.cancel')}
                                </ButtonLeft>
                                <ButtonRight onClick={this.globalValidateLabels} isLoading={isSaving} type="submit">
                                    {t('global.button.save')}
                                </ButtonRight>
                            </ButtonContainer>
                        </LoadingContainerScreen>
                    </div>
                </Fade>
            </Modal>
        );
    }
}

export default withTranslation()(UserCreateOrEditModal);
