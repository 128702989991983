import { AxiosResponse } from 'axios';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { Sale } from '../model/sale';
import { requestParamsFormatter } from '../shared/util/api-utils';
import { api } from './api';

class SaleApi {
    static getAllSales = (predicate: Predicate, page: Pageable, establishmentId?: number): Promise<AxiosResponse<PageableResponse<Sale>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(predicate, page);
        return api.get<PageableResponse<Sale>>(`/conveniences/promotions?establishmentId=${establishmentId ?? ''}&${pageParams}&${filterParams}`);
    };

    static getSale = (id: number): Promise<AxiosResponse<Sale>> => {
        return api.get<Sale>(`/conveniences/${id}`);
    };

    static createSale = (sale: Sale, notifyUser?: boolean): Promise<AxiosResponse<Sale>> => {
        return api.post<Sale>(`/conveniences/${notifyUser ?? false}`, sale);
    };

    static updateSale = (sale: Sale): Promise<AxiosResponse<Sale>> => {
        return api.put<Sale>('/conveniences', sale);
    };

    static deleteSale = (id: number): Promise<AxiosResponse<void>> => {
        return api.delete<void>(`/conveniences/${id}`);
    };

    static activateSale = (id: number): Promise<AxiosResponse<void>> => {
        return api.put<void>(`/conveniences/activate-deactivate/${id}`);
    };
}

export default SaleApi;