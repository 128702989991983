import classNames from 'classnames';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ActivateAccount from '../../features/activate-account/activate-account';
import ActivateAccountSuccess from '../../features/activate-account/activate-account-success';
import MyEstablishment from '../../features/establishment/establishment-index';
import Establishment from '../../features/establishment/index';
import Footer from '../../features/footer/footer';
import ForgotPassword from '../../features/forgot-password/forgot-password';
import RecoverPasswordSucess from '../../features/forgot-password/recover-password-sucess';
import Home from '../../features/home/home';
import Login from '../../features/login/login';
import Logout from '../../features/logout/logout';
import PointsPerLiter from '../../features/points-per-liter/points-per-liter';
import Profile from '../../features/profile/profile';
import { TermsOfUse } from '../../features/terms-of-use';
import PageNotFound from '../error/page-not-found';
import PrivateRoute, { AccessLevel } from './private-routes';

const AppRoutes = ({ match, isAuthenticated }) => {
    const appRoutesClassName = isAuthenticated ? 'has-header' : '';
    return (
        <div className={classNames([appRoutesClassName, 'App-routes-wrapper'])}>
            <div className={'App-routes-wrap'}>
                <Switch>
                    <Route exact path={'/home'} component={Home} />
                    <Route exact path={match.url} component={Login} />
                    <Route exact path={'/logout'} component={Logout} />
                    <Route exact path={'/forgot-password'} component={ForgotPassword} />
                    <Route exact path={'/forgot-password/sucess'} component={RecoverPasswordSucess} />
                    <Route exact path={'/account/activate'} render={props => <ActivateAccount {...props} isFirstAccess />} />
                    <Route exact path={'/account/activate/success'} render={props => <ActivateAccountSuccess {...props} isFirstAccess />} />
                    <Route exact path={'/reset/finish'} component={ActivateAccount} />
                    <Route exact path={'/reset/finish/success'} component={ActivateAccountSuccess} />
                    <Route exact path={'/terms-of-use'} component={TermsOfUse} />

                    <PrivateRoute accessLevel={[AccessLevel.ADMIN, AccessLevel.USER]} path={'/profile'} component={Profile} />
                    <PrivateRoute accessLevel={[AccessLevel.ADMIN, AccessLevel.USER]} path={'/establishment'} component={Establishment} />
                    <PrivateRoute
                        accessLevel={[AccessLevel.ADMIN, AccessLevel.USER]}
                        path={'/points-per-liter'}
                        component={PointsPerLiter}
                    />
                    <PrivateRoute
                        accessLevel={[AccessLevel.ESTABLISHMENT_ADMIN, AccessLevel.ESTABLISHMENT_USER]}
                        path={'/my-establishment'}
                        component={MyEstablishment}
                    />

                    <Route path={'*'} component={PageNotFound} />
                </Switch>
            </div>
            {isAuthenticated && <Footer />}
        </div>
    );
};

export default AppRoutes;
