import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FooterBody, FooterText } from './StyledComponents';

export interface FooterProps {
    t: any;
}
export const Footer = (props: FooterProps): JSX.Element => {
    return (
        <FooterBody>
            <FooterText>{props.t('footer.copyrightMessage')}</FooterText>
        </FooterBody>
    );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Footer) as React.ComponentType<any>;
