import { AxiosResponse } from 'axios';
import { EstablishmentPage } from '../model/establishment';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { requestParamsFormatter } from '../shared/util/api-utils';
import { api } from './api';

class EstablishmentApi {
    static getAllEstablishments = (predicate: Predicate, page: Pageable): Promise<AxiosResponse<PageableResponse<EstablishmentPage>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(predicate, page);
        return api.get<PageableResponse<EstablishmentPage>>(`/establishments?&sort=status,asc&${pageParams}&${filterParams}`);
    };

    static getEstablishment = (id: number): Promise<AxiosResponse<EstablishmentPage>> => {
        return api.get<EstablishmentPage>(`/establishments/${id}`);
    };

    static getMyEstablishment = (): Promise<AxiosResponse<EstablishmentPage>> => {
        return api.get<EstablishmentPage>('/establishments/me');
    };

    static createEstablishment = (establishment: EstablishmentPage): Promise<AxiosResponse<EstablishmentPage>> => {
        return api.post<EstablishmentPage>('/establishments', establishment);
    };

    static updateEstablishment = (establishment: EstablishmentPage): Promise<AxiosResponse<EstablishmentPage>> => {
        return api.put<EstablishmentPage>('/establishments', establishment);
    };

    static activateEstablishment = (id: number): Promise<AxiosResponse<void>> => {
        return api.patch<void>(`/establishments/activate/${id}`);
    };
}

export default EstablishmentApi;
