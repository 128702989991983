import React from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/button/button';
import { ContainerBody, SubTitle, Title } from './StyledComponents';
import Footer from '../footer/footer';
import Header from '../../components/header-white/header';

export interface ActivateAccountSuccessProps {
    t: any;
    isFirstAccess?: boolean;
}

const ActivateAccountSuccess = (props: ActivateAccountSuccessProps): JSX.Element => {
    const history = useHistory();

    return (

        <div>
            <Header />
            <Container>
                <Row>
                    <Col md={{ size: '4', offset: '4' }}>
                        <ContainerBody>
                            <Title>{props.isFirstAccess ? props.t('activate-account.success.title') : props.t('activate-account.success.title-two')}</Title>
                            <div
                                style={{
                                    maxHeight: '60px',
                                    maxWidth: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '100px',
                                    padding: '50px'
                                }}
                            >
                                <img src={require('../../images/lock.svg')} alt="" />
                            </div>
                            <SubTitle>
                                <div style={{ maxWidth: '230px', margin: 'auto' }}>
                                    {props.isFirstAccess ? props.t('activate-account.success.subtitle') : props.t('activate-account.success.subtitle-two')}
                                </div>
                            </SubTitle>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    style={{ width: '175px', height: '44px', fontSize: '14px' }}
                                    onClick={() => history.push('/')}
                                    type="submit"
                                >
                                    {props.t('recover-password-sucess.goLogin')}
                                </Button>
                            </div>
                        </ContainerBody>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default withTranslation()(ActivateAccountSuccess) as React.ComponentType<any>;
