import { AxiosResponse } from 'axios';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { State } from '../model/state';
import { requestParamsFormatter } from '../shared/util/api-utils';
import { api } from './api';

class StateApi {
    static getAllStates = (predicate: Predicate, page: Pageable): Promise<AxiosResponse<PageableResponse<State>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(predicate, page);
        return api.get<PageableResponse<State>>(`/states?${pageParams}&${filterParams}`);
    };
}

export default StateApi;
