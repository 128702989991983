import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CustomCheckbox from '../../../../../components/custom-checkbox/custom-checkbox';
import LoadingContainerScreen from '../../../../../components/loading-container-screen/loading-container-screen';
import { SelectOption } from '../../../../../components/select-field/select-field';
import { ButtonContainer } from '../../../../../components/simple-alert-modal/StyledComponents';
import { Sale } from '../../../../../model/sale';
import { defaultValue, ErrorAndMessage, Validation } from '../../../../../model/validation';
import SaleService from '../../../../../services/sale-service';
import AuthUtils from '../../../../../shared/util/auth-utils';
import StringUtils from '../../../../../shared/util/string-utils';
import { entityValidator } from '../../../../../shared/util/validator-utils';
import { ButtonLeft, ButtonRight, CustomTextFieldData, CustomTextFieldHeight, CustomTextFieldMargin, SelectedFieldMargin } from '../../../StyledComponents';
import './sale-create-or-edit-modal.scss';
import ImageUpload from '../../../../../components/image-upload/image-upload';
import SalesCard from '../../../../../components/sales-card/sales-card';
import { ConvenienceCategory } from '../../../../../model/enums/convenience-category';
import { ConvenienceType } from '../../../../../model/enums/convenience-type';
import moment from 'moment';

interface ISaleCreateOrEditModalProps extends WithTranslation {
    id?: number;
    isOpen?: boolean;
    onSaveAction: () => void;
    establishmentId?: number;
    onCancelAction: () => void;
}

interface ISaleCreateOrEditModalState {
    isSaving: boolean;
    isLoading: boolean;
    notifyUser: boolean;
    step: string;
    sale: Sale;
    saleValidation: {
        image?: Validation[];
        category: Validation[];
        name: Validation[];
        description: Validation[];
        pointAmount: Validation[];
    };
    saleError: {
        image: ErrorAndMessage;
        category: ErrorAndMessage;
        name: ErrorAndMessage;
        description: ErrorAndMessage;
        pointAmount: ErrorAndMessage;
    };
}

class SaleCreateOrEditModal extends React.Component<ISaleCreateOrEditModalProps, ISaleCreateOrEditModalState> {
    $defaultPath: string;
    $imageUploadRef: any;
    $convenienceCategory: SelectOption[];

    constructor(props) {
        super(props);
        this.state = {
            sale: {
                untilEndStock: false,
                productQuantity: 0,
                type: ConvenienceType.PROMOTION,
                startDate: undefined,
                endDate: undefined,
            },
            isSaving: false,
            isLoading: false,
            notifyUser: false,
            step: "one",
            saleValidation: {
                category: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.category') }],
                name: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.name') }],
                description: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.description') }],
                pointAmount: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.pointsPerLiter') }],
            },
            saleError: {
                image: defaultValue,
                category: defaultValue,
                name: defaultValue,
                description: defaultValue,
                pointAmount: defaultValue,
            }
        };
        this.$defaultPath = '';
        this.$convenienceCategory = this.setConvenienceCategory();
        this.$imageUploadRef = React.createRef();
    }

    componentWillReceiveProps(nextProps: Readonly<ISaleCreateOrEditModalProps>) {
        if (nextProps.id != null && (nextProps.id !== this.props.id)) this.getSale(nextProps.id);
    }

    closeAndReset = async () => {
        await this.resetAll();
        this.props.onCancelAction();
    };

    resetAll = async () => {
        this.setState({
            sale: {
                untilEndStock: false,
                productQuantity: 0,
                type: ConvenienceType.PROMOTION,
                startDate: undefined,
                endDate: undefined,
            },
            notifyUser: false,
            step: "one",
            saleError: {
                image: defaultValue,
                category: defaultValue,
                name: defaultValue,
                description: defaultValue,
                pointAmount: defaultValue,
            }
        });
    };

    notifyUser = () => {
        this.setState({
            notifyUser: !this.state.notifyUser
        });
    };

    whileStockLasts = () => {
        const sale = { ...this.state.sale };

        this.setState({
            sale: {
                ...sale, untilEndStock: !sale.untilEndStock
            }

        });
    };

    setConvenienceCategory = () => {
        return Object.values(ConvenienceCategory)
            .map(convenienceCategory => {
                return {
                    value: convenienceCategory,
                    label: this.props.t(`enum.convenienceCategory.${convenienceCategory}`)
                }
            });
    };

    getSale = (id: number) => {
        this.isLoading(true);
        SaleService.getSale(id)
            .then(result => {
                this.setState({
                    sale: result
                }, () => this.isLoading(false));
            })
            .catch(() => {
                this.isLoading(false);
                this.closeAndReset().then(callback => callback);
            })
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    isSaving = (isSaving: boolean) => {
        this.setState({
            isSaving
        });
    };

    validateLabel = (key: string) => {
        const { saleValidation } = this.state;
        this.setState({
            saleError: {
                ...this.state.saleError,
                [key]: entityValidator(this.getValidationEvents(key), saleValidation[key])
            }
        });
    };

    globalValidateLabels = () => {
        const newSaleError = { ...this.state.saleError };
        const { saleValidation } = this.state;
        const sale = { ...this.state.sale };

        sale.image = this.$imageUploadRef.getFile();

        Object.keys(saleValidation).forEach(key =>
            newSaleError[key] = entityValidator(this.getValidationEvents(key), saleValidation[key]));

        this.setState({
            saleError: newSaleError,
            sale
        }, () => {
            if (this.findAnyError()) return;
            this.setState({ step: 'two' })
        });
    };

    findAnyError = () => {
        return Object.keys(this.state.saleError).filter(key => this.state.saleError[key]?.value).length > 0;
    };

    getValidationEvents = (key: string) => {
        const { sale } = this.state;
        switch (key) {
            case 'category':
                return [[sale?.category]];

            case 'name':
                return [[sale?.name]];

            case 'description':
                return [[sale?.description]];

            case 'pointAmount':
                return [[sale?.pointAmount]];

            case 'promotionDescription':
                return [[sale?.promotionDescription]];

            default:
                return [[false]]
        }
    };

    updateLabel = (event: any, key: string) => {
        this.setState({
            sale: {
                ...this.state.sale,
                [key]: event
            }
        });
    };

    saleAction = () => {
        const sale = { ...this.state.sale };
        sale.image = this.$imageUploadRef.getFile();

        if (sale.image == null) delete sale.image;

        if (this.state.sale.id != null) return this.updateSale(sale);
        return this.createSale(sale);
    };

    updateSale = (sale) => {
        this.isSaving(true);

        SaleService.updateSale(sale)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };

    createSale = (sale) => {
        const { notifyUser } = this.state;

        this.isSaving(true);

        if (AuthUtils.isFgs()) {
            sale.establishment = {};
            sale.establishment.id = this.props.establishmentId;
        }


        SaleService.createSale(sale, notifyUser)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };


    isNumber = (number: string) => {
        return /^[0-9]*$/.test(number)
    }

    goBack = () => {
        this.setState({ step: "one" })
    }


    render() {
        const { t, isOpen } = this.props;
        const { isLoading, isSaving, saleError, notifyUser, step } = this.state;
        const backdropProps = { timeout: 500 };
        const convenienceClassName = isLoading ? 'is-loading' : '';
        const onCloseAction = !isLoading && !isSaving ? this.closeAndReset : void (0);
        const sale = { ...this.state.sale };

        return (
            <Modal
                closeAfterTransition
                open={isOpen || false}
                onClose={onCloseAction}
                BackdropComponent={Backdrop}
                BackdropProps={backdropProps}
                className={'convenience-create-or-edit-wrapper'}
            >
                <Fade in={isOpen}>
                    <div className={classNames([convenienceClassName, 'convenience-create-or-edit-wrap'])}>
                        <LoadingContainerScreen isLoading={isLoading}>
                            <div className="icon-fechar" onClick={onCloseAction} />
                            <span className={'body-title'}>
                                {t(sale.id != null
                                    ? 'sale.editModal' : step === "one"
                                        ? 'sale.newModal' : 'sale.previewModal')}
                            </span>
                            <div className={'middle-items'}>
                                {step === "one" ?
                                    <>
                                        <ImageUpload
                                            image={this.state.sale?.image}
                                            callbackRef={ref => (this.$imageUploadRef = ref)}
                                        />
                                        <SelectedFieldMargin
                                            id={StringUtils.randomString()}
                                            dataArray={this.$convenienceCategory}
                                            selected={sale.category != null}
                                            className={'select-field-reference'}
                                            error={saleError.category?.value}
                                            initialValue={sale?.category || ''}
                                            errorText={saleError.category?.message}
                                            onBlur={() => this.validateLabel('category')}
                                            onChange={event => this.updateLabel(event, 'category')}
                                            placeholder={t('convenience.textField.convenienceCategory')}
                                        />
                                        <CustomTextFieldMargin
                                            value={sale.name}
                                            id={StringUtils.randomString()}
                                            error={saleError.name?.value}
                                            errorText={saleError.name?.message}
                                            onBlur={() => this.validateLabel('name')}
                                            placeholder={t('convenience.textField.name')}
                                            onChange={event => this.updateLabel(event, 'name')}
                                        />
                                        <CustomTextFieldHeight
                                            value={sale.description}
                                            id={StringUtils.randomString()}
                                            error={saleError.description?.value}
                                            errorText={saleError.description?.message}
                                            onBlur={() => this.validateLabel('description')}
                                            placeholder={t('convenience.textField.description')}
                                            onChange={event => this.updateLabel(event, 'description')}
                                        />
                                        <CustomTextFieldMargin
                                            isOnlyNumbers
                                            className={'pointAmount'}
                                            id={StringUtils.randomString()}
                                            error={saleError.pointAmount?.value}
                                            errorText={saleError.pointAmount?.message}
                                            onBlur={() => this.validateLabel('pointAmount')}
                                            placeholder={t('convenience.textField.pointAmount')}
                                            onChange={event => this.updateLabel(!StringUtils.isStringEmpty(event) ? Number(event) : null, 'pointAmount')}
                                            value={sale.pointAmount != null ? StringUtils.formatCurrencyAndNumber(sale.pointAmount, true, 0) : undefined}
                                        />
                                        <div className='line'></div>
                                        <div style={{ marginBottom:'20px'}} className='saleTitle'>{t('sale.promotionValidity')}</div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <div>{t('sale.from')}</div>
                                            <CustomTextFieldData
                                                isDate
                                                value={sale.startDate}
                                                id={StringUtils.randomString()}
                                                label={t('sale.textField.startDate')}
                                                placeholder={t('sale.textField.startDate')}
                                                onChange={event => this.updateLabel(event, 'startDate')}
                                                style={{ width: '160px' }}
                                                inputElementProps={{
                                                    max: moment().add(50, 'years').format('YYYY-MM-DD'),
                                                }}
                                            />
                                            <div>{t('sale.to')}</div>
                                            <CustomTextFieldData
                                                isDate
                                                value={sale.endDate}
                                                id={StringUtils.randomString()}
                                                placeholder={t('sale.textField.endDate')}
                                                label={t('sale.textField.endDate')}
                                                onChange={event => this.updateLabel(event, 'endDate')}
                                                style={{ width: '160px' }}
                                                inputElementProps={{
                                                    max: moment().add(50, 'years').format('YYYY-MM-DD'),
                                                }}
                                            />
                                        </div>
                                        <CustomTextFieldHeight
                                            value={sale.promotionDescription}
                                            id={StringUtils.randomString()}
                                            placeholder={t('sale.textField.description')}
                                            onChange={event => this.updateLabel(event, 'promotionDescription')}
                                        />
                                        <div className={sale.untilEndStock ? 'while-stock-lasts-wrapper' : 'notify-user-wrapper'}>
                                            <CustomCheckbox title={t('sale.textField.untilEndStock')} checked={sale.untilEndStock!} onChange={this.whileStockLasts} />
                                            <div className={'howManyProducts'}>
                                                {sale.untilEndStock &&
                                                    <input
                                                        className={'howManyProducts-input'}
                                                        value={sale.productQuantity}
                                                        onChange={(e) => this.setState({ sale: { ...sale, productQuantity: this.isNumber(e.target.value) ? Number(e.target.value) : 0 } })}
                                                    />}
                                                {sale.untilEndStock && <div className={'howManyProducts-text'}> {t('sale.textField.howManyProducts')}</div>}
                                            </div>
                                        </div>
                                        {sale.id == null && (
                                            <div className={'notify-user-wrapper'}>
                                                <CustomCheckbox title={t('convenience.textField.notifyUser')} checked={notifyUser} onChange={this.notifyUser} />
                                            </div>
                                        )}
                                    </>
                                    :
                                    <SalesCard
                                        title={sale.name}
                                        points={sale.pointAmount}
                                        description={sale.description}
                                        secondDescription={sale.promotionDescription}
                                        image={sale.image}
                                        isPreview
                                    />

                                }
                            </div>
                            {step === "one" ?
                                <ButtonContainer>
                                    <ButtonLeft onClick={onCloseAction} disabled={isSaving} color="white" type="submit">
                                        {t('global.button.cancel')}
                                    </ButtonLeft>
                                    <ButtonRight onClick={this.globalValidateLabels} isLoading={isSaving} type="submit">
                                        {t('global.button.save')}
                                    </ButtonRight>
                                </ButtonContainer>
                                :
                                <ButtonContainer>
                                    <ButtonLeft onClick={this.goBack} disabled={isSaving} color="white" type="submit">
                                        {t('global.button.backStep')}
                                    </ButtonLeft>
                                    <ButtonRight onClick={this.saleAction} isLoading={isSaving} type="submit">
                                        {t('global.button.confirm')}
                                    </ButtonRight>
                                </ButtonContainer>
                            }

                        </LoadingContainerScreen>
                    </div>
                </Fade>
            </Modal>
        );
    }
}

export default withTranslation()(SaleCreateOrEditModal);
