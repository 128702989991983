import styled from 'styled-components/macro';

export default styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;

    button:first-of-type {
        margin-right: 10px;
    }
    button:last-of-type {
        margin-left: 10px;
    }
`;
