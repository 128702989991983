import { AxiosResponse } from 'axios';
import { Convenience } from '../model/convenience';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { requestParamsFormatter } from '../shared/util/api-utils';
import { api } from './api';

class ConvenienceApi {
    static getAllConveniences = (predicate: Predicate, page: Pageable, establishmentId?: number): Promise<AxiosResponse<PageableResponse<Convenience>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(predicate, page);
        return api.get<PageableResponse<Convenience>>(`/conveniences?establishmentId=${establishmentId ?? ''}&${pageParams}&${filterParams}`);
    };

    static getConvenience = (id: number): Promise<AxiosResponse<Convenience>> => {
        return api.get<Convenience>(`/conveniences/${id}`);
    };

    static createConvenience = (convenience: Convenience, notifyUser?: boolean): Promise<AxiosResponse<Convenience>> => {
        return api.post<Convenience>(`/conveniences/${notifyUser ?? false}`, convenience);
    };

    static updateConvenience = (convenience: Convenience): Promise<AxiosResponse<Convenience>> => {
        return api.put<Convenience>('/conveniences', convenience);
    };

    static deleteConvenience = (id: number): Promise<AxiosResponse<void>> => {
        return api.delete<void>(`/conveniences/${id}`);
    };
    static activateConvenience = (id: number): Promise<AxiosResponse<void>> => {
        return api.put<void>(`/conveniences/activate-deactivate/${id}`);
    };
}

export default ConvenienceApi;
