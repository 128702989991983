import styled from 'styled-components/macro';

export const FooterBody = styled.div`
    width: 100%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    background-color: white;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #eeeeee;
    position: relative;
    bottom: 0;
`;

export const FooterText = styled.div`
    font-size: 14px;
    font-weight: 300;
    color: #4a4a4a;
`;
