import React from 'react';
import {  useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ButtonContainer, ChildrenStyle, ContainerItems, Title, TitleText } from './StyledComponents';

function ContainerTitleBackSmall ({ titleText, children = null}) {
    // const { titleText, children } = this.props;

    const history = useHistory();

    return (
        <>
            <Title>
                <Container>
                    <Row>
                        <Col>
                            <ContainerItems>
                                <ButtonContainer onClick={() => history.goBack()} className="icon-seta" />
                                <TitleText>{titleText}</TitleText>
                                <ChildrenStyle>{children}</ChildrenStyle>
                            </ContainerItems>
                        </Col>
                    </Row>
                </Container>
            </Title>
        </>
    );
}

export default ContainerTitleBackSmall;
