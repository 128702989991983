import React from 'react';
import {
    CardContainer,
    Description,
    IconsContainer,
    Image,
    Points,
    SecondDescription,
    Title
} from './StyledComponents';

interface Props {
    id?: number;
    t?: any;
    title?: string;
    points?: number;
    image?: any;
    description?: string;
    secondDescription?: string;
    disabled?: boolean;
    hasNew?: boolean;
    isError?: boolean;
    isActive?: boolean;
    onDeleteClick?: () => void;
    onEditClick?: () => void;
    onHandleToggle?: () => void;
    isPreview?: boolean;
}

export class SalesCard extends React.Component<Props> {
    render() {
        const { 
            title, 
            points, 
            hasNew, 
            disabled, 
            description, 
            secondDescription, 
            isError, 
            isActive, 
            image, 
            onDeleteClick, 
            onEditClick, 
            onHandleToggle, 
            isPreview } = this.props;

        return (
            <>
                <CardContainer disabled={disabled} hasNew={hasNew} isError={isError} isActive={isActive}>
                    <IconsContainer>
                        <div className="icon-delete_rounded" onClick={onDeleteClick} />
                        <div className="icon-editar" onClick={onEditClick} />
                        <div className="icon-deletar" onClick={onHandleToggle} />
                    </IconsContainer>
                    <Image>
                        {isPreview ? <img src={`data:${image?.fileContentType};base64, ${image?.file}`} alt=''/> : <img src={image} alt=''/>}
                    </Image>
                    <Title>{title}</Title>
                    <Points>
                        <span>{points}{' PONTOS'}</span>
                    </Points>
                    <Description>{description}</Description>
                    <SecondDescription>{secondDescription}</SecondDescription>
                </CardContainer>
            </>
        );
    }
}

export default SalesCard;
