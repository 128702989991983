import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import ContainerTitleBack from '../../components/container-title-back/container-title-back';
import { SelectOption } from '../../components/select-field/select-field';
import SmallLoading from '../../components/small-loading/small-loading';
import { AsyncSelectResponse } from '../../model/async-select';
import { City } from '../../model/city';
import { ConvenienceCategory } from '../../model/enums/convenience-category';
import { EstablishmentPage } from '../../model/establishment';
import { Pageable } from '../../model/pageable';
import { Predicate } from '../../model/predicate';
import { State } from '../../model/state';
import { defaultValue, ErrorAndMessage, Validation } from '../../model/validation';
import { AuthenticationService } from '../../services/authentication-service';
import CityService from '../../services/city-service';
import EstablishmentService from '../../services/establishment-service';
import StateService from '../../services/state-service';
import StringUtils from '../../shared/util/string-utils';
import { entityValidator } from '../../shared/util/validator-utils';
import ContainerPageEdit from '../../styled-components/ContainerPageEdit';
import {
    AsyncSelectedFieldMargin,
    ButtonContainer,
    ButtonLeft,
    ButtonRight,
    CustomTextFieldMargin,
    SectionTitle,
    SectionTitleAdress,
    SelectedFieldMargin
} from './StyledComponents';

interface IEntityProps extends WithTranslation, RouteComponentProps<{ id: string }> {
    redirectPath?: string;
}

interface IEntityState {
    isLoading: boolean;
    isSending: boolean;
    originalEmail?: string;
    isEmailChanged: boolean;
    isLoadingEmail: boolean;
    temporaryHideCity: boolean;
    establishment: EstablishmentPage;
    establishmentValidation: {
        name: Validation[];
        email: Validation[];
        phone: Validation[];
        category: Validation[];

        state: Validation[];
        city?: Validation[];
        number: Validation[];
        street: Validation[];
        zipcode: Validation[];
        complement?: Validation[];
    };
    establishmentError: {
        name: ErrorAndMessage;
        email: ErrorAndMessage;
        phone: ErrorAndMessage;
        category: ErrorAndMessage;

        state: ErrorAndMessage;
        city?: ErrorAndMessage;
        number: ErrorAndMessage;
        street: ErrorAndMessage;
        zipcode: ErrorAndMessage;
        complement?: ErrorAndMessage;
    };
}

class NewEstablishment extends React.Component<IEntityProps, IEntityState> {
    $defaultPath: string;
    $establishmentCategoryArray: SelectOption[];

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSending: false,
            isEmailChanged: false,
            isLoadingEmail: false,
            temporaryHideCity: false,
            establishment: {
                address: {}
            },
            establishmentValidation: {
                name: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.name') }],
                category: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.category') }],
                email: [{ handler: event => StringUtils.isEmailInvalid(event[0]), message: this.props.t('errors.email.valid') }],
                phone: [{ handler: event => !StringUtils.isSizeBetween(10, 11, event[0]), message: this.props.t('errors.phone') }],

                city: [{ handler: event => event[0] == null, message: this.props.t('errors.city') }],
                state: [{ handler: event => event[0] == null, message: this.props.t('errors.state') }],
                number: [{ handler: event => event[0] == null, message: this.props.t('errors.number') }],
                street: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.street') }],
                zipcode: [{ handler: event => StringUtils.isSizeInvalid(8, event[0]), message: this.props.t('errors.zipcode') }]
            },
            establishmentError: {
                name: defaultValue,
                email: defaultValue,
                phone: defaultValue,
                category: defaultValue,

                city: defaultValue,
                state: defaultValue,
                number: defaultValue,
                street: defaultValue,
                zipcode: defaultValue
            }
        };
        this.$defaultPath = '';
        this.$establishmentCategoryArray = this.setEstablishmentCategoryArray();
    }

    componentDidMount() {
        if (this.props.match.params.id != null) {
            this.getEstablishment();
        }
    }

    setEstablishmentCategoryArray = () => {
        return Object.values(ConvenienceCategory)
            .map(establishmentCategory => {
                return {
                    value: establishmentCategory,
                    label: this.props.t(`enum.convenienceCategory.${establishmentCategory}`)
                }
            });
    };

    temporaryHideCity = () => {
        this.setState({
            temporaryHideCity: true
        }, () => {
            this.setState({
                temporaryHideCity: false
            });
        });
    };

    getEstablishment = () => {
        this.isLoading();
        EstablishmentService.getEstablishment(Number(this.props.match.params.id))
            .then(result => {
                this.setState({
                    originalEmail: result.email,
                    establishment: {
                        ...result,
                        address: {
                            ...result.address,
                            state: result.address?.city?.state || {}
                        }
                    }
                }, () => this.isLoading())
            });
    };

    createOrUpdateAction = () => {
        if (this.props.match.params.id != null) {
            this.updateEstablishment();
        } else {
            this.createEstablishment();
        }
    };

    createEstablishment = () => {
        const { establishment } = this.state;
        this.isSending();
        EstablishmentService.createEstablishment(establishment)
            .then(this.successCreateOrEditEstablishment)
            .catch(this.isSending)
    };

    updateEstablishment = () => {
        const { establishment } = this.state;
        this.isSending();
        EstablishmentService.updateEstablishment(establishment)
            .then(this.successCreateOrEditEstablishment)
            .catch(this.isSending)
    };

    successCreateOrEditEstablishment = () => {
        this.isSending();
        this.redirectAction();
    };

    redirectAction = () => {
        this.props.history.push(this.props.redirectPath || '/establishment');
    };

    redirectToList = () => {
        this.props.history.push('/establishment');
    };

    isLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        });
    };

    isSending = () => {
        this.setState({
            isSending: !this.state.isSending
        });
    };

    isLoadingEmail = (isLoadingEmail: boolean) => {
        this.setState({
            isLoadingEmail
        });
    };

    checkIfEmailIsAlreadyInUse = async () => {
        const { establishment } = this.state;
        let hasEmailError = false;

        if (StringUtils.isStringEmpty(establishment.email)) return;

        this.isLoadingEmail(true);

        await AuthenticationService().checkIfEmailExists(establishment.email!, true)
            .then(() => this.isLoadingEmail(false))
            .catch(() => {
                hasEmailError = true;
                this.isLoadingEmail(false);
            });

        return hasEmailError;
    };

    isEmailChanged = () => {
        this.setState({
            isEmailChanged: true
        });
    };

    validateLabel = (key: string) => {
        const { establishmentValidation } = this.state;
        this.setState({
            establishmentError: {
                ...this.state.establishmentError,
                [key]: entityValidator(this.getValidationEvents(key), establishmentValidation[key])
            }
        });
    };

    isEmailEquals = () => {
        const { establishment, originalEmail } = this.state;
        return establishment.email === originalEmail;
    };

    globalValidateLabels = () => {
        const newForumError = { ...this.state.establishmentError };
        const { establishmentValidation, isEmailChanged } = this.state;

        Object.keys(establishmentValidation).forEach(key =>
            newForumError[key] = entityValidator(this.getValidationEvents(key), establishmentValidation[key]));

        this.setState({
            establishmentError: newForumError
        }, async () => {
            if (isEmailChanged && !this.isEmailEquals() && await this.checkIfEmailIsAlreadyInUse()) return;
            if (this.findAnyError()) return;
            this.createOrUpdateAction();
        });
    };

    findAnyError = () => {
        return Object.keys(this.state.establishmentError).filter(key => this.state.establishmentError[key]?.value).length > 0;
    };

    getValidationEvents = (key: string) => {
        const { establishment } = this.state;
        switch (key) {
            case 'name':
                return [[establishment.name]]

            case 'email':
                return [[establishment.email]]

            case 'phone':
                return [[establishment.phone]]

            case 'category':
                return [[establishment.category]]

            case 'city':
                return [[establishment.address?.city]]

            case 'state':
                return [[establishment.address?.state]]

            case 'number':
                return [[establishment.address?.number]]

            case 'street':
                return [[establishment.address?.street]]

            case 'zipcode':
                return [[establishment.address?.zipcode]]

            default:
                return [[false]]
        }
    };

    getAllCities = async (event: any) => {
        const { establishment } = this.state;
        const asyncSelectResponse: AsyncSelectResponse[] = [];
        const pageable: Pageable = { page: 0, size: 20, sort: 'name,asc' };
        const predicate: Predicate = { name: event, 'state.id': establishment.address?.state?.id };

        await CityService.getAllCities(predicate, pageable).then(result => {
            result.content.forEach(city => {
                asyncSelectResponse.push({
                    value: city.id,
                    label: city.name
                });
            });
        });

        return asyncSelectResponse;
    };

    getAllStates = async (event: any) => {
        const predicate: Predicate = { name: event };
        const asyncSelectResponse: AsyncSelectResponse[] = [];
        const pageable: Pageable = { page: 0, size: 20, sort: 'name,asc' };

        await StateService.getAllStates(predicate, pageable).then(result => {
            result.content.forEach(state => {
                asyncSelectResponse.push({
                    value: state.id,
                    label: state.name
                });
            });
        });

        return asyncSelectResponse;
    };

    updateLabel = (event: any, key: string, additionalField?: string) => {
        if (StringUtils.isStringEmpty(additionalField)) {
            this.setState({
                establishment: {
                    ...this.state.establishment,
                    [key]: event
                }
            });
        } else {
            this.setState({
                establishment: {
                    ...this.state.establishment,
                    [additionalField as string]: {
                        ...this.state.establishment[additionalField as string],
                        [key]: event
                    }
                }
            });
        }
    };

    updateCityLabel = (event: AsyncSelectResponse) => {
        const newCity: City = {
            id: event?.value,
            label: event?.label
        };

        this.setState({
            establishment: {
                ...this.state.establishment,
                address: {
                    ...this.state.establishment.address,
                    city: newCity
                }
            }
        });
    };

    updateStateLabel = (event: AsyncSelectResponse) => {
        const newState: State = {
            id: event?.value,
            name: event?.label
        };

        this.setState({
            establishment: {
                ...this.state.establishment,
                address: {
                    ...this.state.establishment.address,
                    state: newState
                }
            }
        }, () => {
            this.temporaryHideCity();
            this.updateCityLabel({});
        });
    };

    inputPropsLoading = ([filled, focused]) => {
        const { establishmentError, isLoadingEmail } = this.state;

        const isValid = !establishmentError.email && filled && !focused && !isLoadingEmail;
        const isLoadingValid = filled && !focused && isLoadingEmail;
        const isFilledValid = filled && isValid;

        const loadingAdornment = {
            endAdornment: (
                <InputAdornment position={'end'}>
                    {isFilledValid && <div className={'check-icn'} />}
                    {isLoadingValid && <SmallLoading />}
                </InputAdornment>
            )
        };

        return isFilledValid || isLoadingValid ? loadingAdornment : {};
    };

    render() {
        const { t } = this.props;
        const { establishment, establishmentError, temporaryHideCity, isLoadingEmail, isSending } = this.state;

        const disabledButtons = isSending || isLoadingEmail;

        const cityValue: AsyncSelectResponse = {
            value: establishment.address?.city?.id,
            label: establishment.address?.city?.label || establishment.address?.city?.name
        };

        const stateValue: AsyncSelectResponse = {
            value: establishment.address?.state?.id,
            label: establishment.address?.state?.label || establishment.address?.state?.name
        };

        return (
            <ContainerPageEdit>
                <ContainerTitleBack titleText={t('establishment.titleSecond')} />
                <Container>
                    <Row>
                        <Col md={{ size: '6', offset: '3' }}>
                            <SectionTitle>{t('establishment.new.title')}</SectionTitle>
                            <SelectedFieldMargin
                                id={StringUtils.randomString()}
                                className={'select-field-reference'}
                                selected={establishment.category != null}
                                error={establishmentError.category?.value}
                                dataArray={this.$establishmentCategoryArray}
                                initialValue={establishment?.category || ''}
                                errorText={establishmentError.category?.message}
                                onBlur={() => this.validateLabel('category')}
                                placeholder={t('establishment.textField.category')}
                                onChange={event => this.updateLabel(event, 'category')}
                            />
                            <CustomTextFieldMargin
                                value={establishment?.name}
                                id={StringUtils.randomString()}
                                error={establishmentError.name?.value}
                                errorText={establishmentError.name?.message}
                                onBlur={() => this.validateLabel('name')}
                                placeholder={t('establishment.textField.name')}
                                onChange={event => this.updateLabel(event, 'name')}
                            />
                            <CustomTextFieldMargin
                                filledFocusedInputProps
                                value={establishment?.email}
                                id={StringUtils.randomString()}
                                InputProps={this.inputPropsLoading}
                                error={establishmentError.email?.value}
                                errorText={establishmentError.email?.message}
                                onBlur={() => this.validateLabel('email')}
                                placeholder={t('establishment.textField.email')}
                                onChange={event => {
                                    this.isEmailChanged();
                                    this.updateLabel(event, 'email');
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: '4', offset: '3' }}>
                            <CustomTextFieldMargin
                                mask={'99 9 9999-9999'}
                                value={establishment?.phone}
                                id={StringUtils.randomString()}
                                error={establishmentError.phone?.value}
                                errorText={establishmentError.phone?.message}
                                onBlur={() => this.validateLabel('phone')}
                                placeholder={t('establishment.textField.phone')}
                                onChange={event => this.updateLabel(event, 'phone')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: '6', offset: '3' }}>
                            <SectionTitleAdress>{t('establishment.new.titleSecond')}</SectionTitleAdress>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: '3', offset: '3' }}>
                            <CustomTextFieldMargin
                                mask={'99999-999'}
                                id={StringUtils.randomString()}
                                value={establishment?.address?.zipcode}
                                error={establishmentError.zipcode?.value}
                                errorText={establishmentError.zipcode?.message}
                                onBlur={() => this.validateLabel('zipcode')}
                                placeholder={t('establishment.textField.cep')}
                                onChange={event => this.updateLabel(event, 'zipcode', 'address')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: '6', offset: '3' }}>
                            <CustomTextFieldMargin
                                id={StringUtils.randomString()}
                                value={establishment?.address?.street}
                                error={establishmentError.street?.value}
                                errorText={establishmentError.street?.message}
                                onBlur={() => this.validateLabel('street')}
                                placeholder={t('establishment.textField.address')}
                                onChange={event => this.updateLabel(event, 'street', 'address')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingRight: '13px' }} md={{ size: '2', offset: '3' }}>
                            <CustomTextFieldMargin
                                id={StringUtils.randomString()}
                                value={establishment?.address?.number}
                                error={establishmentError.number?.value}
                                errorText={establishmentError.number?.message}
                                onBlur={() => this.validateLabel('number')}
                                placeholder={t('establishment.textField.number')}
                                onChange={event => this.updateLabel(event, 'number', 'address')}
                            />
                        </Col>
                        <Col style={{ paddingLeft: '13px' }} md="4">
                            <CustomTextFieldMargin
                                id={StringUtils.randomString()}
                                value={establishment?.address?.complement}
                                placeholder={t('establishment.textField.complement')}
                                onChange={event => this.updateLabel(event, 'complement', 'address')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingRight: '13px' }} md={{ size: '2', offset: '3' }}>
                            <AsyncSelectedFieldMargin
                                openOnTop
                                value={stateValue}
                                getOptions={this.getAllStates}
                                onChange={this.updateStateLabel}
                                error={establishmentError.state?.value}
                                errorText={establishmentError.state?.message}
                                onBlur={() => this.validateLabel('state')}
                                placeholder={t('establishment.textField.state')}
                                defaultMessage={t('asyncSelect.searchOptionState')}
                                loadingMessage={t('asyncSelect.loadingMessageState')}
                                noOptionsMessage={t('asyncSelect.noOptionsMessageState')}
                            />
                        </Col>
                        <Col style={{ paddingLeft: '13px' }} md="4">
                            {!temporaryHideCity && (
                                <AsyncSelectedFieldMargin
                                    openOnTop
                                    value={cityValue}
                                    getOptions={this.getAllCities}
                                    onChange={this.updateCityLabel}
                                    isDisabled={stateValue.value == null}
                                    error={establishmentError.city?.value}
                                    errorText={establishmentError.city?.message}
                                    onBlur={() => this.validateLabel('city')}
                                    placeholder={t('establishment.textField.city')}
                                    defaultMessage={t('asyncSelect.searchOptionCity')}
                                    loadingMessage={t('asyncSelect.loadingMessageCity')}
                                    noOptionsMessage={t('asyncSelect.noOptionsMessageCity')}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonContainer>
                                <ButtonLeft onClick={this.redirectToList} disabled={disabledButtons} color="white" type="submit">
                                    {t('global.button.cancel')}
                                </ButtonLeft>
                                <ButtonRight onClick={this.globalValidateLabels} disabled={disabledButtons} isLoading={isSending} type="submit">
                                    {t('global.button.save')}
                                </ButtonRight>
                            </ButtonContainer>
                        </Col>
                    </Row>
                </Container>
            </ContainerPageEdit>
        );
    }
}

export default withTranslation()(NewEstablishment);
