import React from 'react';
import styled from 'styled-components/macro';
import StringUtils from '../../shared/util/string-utils';
import Colors from '../../styled-components/Colors';
import SmallLoading from '../small-loading/small-loading';

const StyledButton = styled.button`
  min-width: 146px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 800;
  background: ${props => props.backgroundColor};
  border: solid 1px ${props => props.borderColor};
  color: ${props => props.textColor};
  height: ${props => props.size};
  transition: background 250ms ease, color 250ms ease, border-color 250ms ease, box-shadow 250ms ease;

  & * + * {
    margin-left: 10px;
  }

  &:hover {
    background: ${props => props.hoverColor};
    box-shadow: 0 2px 4px 0 ${Colors.brownGrey};
  }
  &:active {
    background: ${props => props.activeColor};
    box-shadow: 0 1px 3px 0 ${Colors.brownGrey};
  }

  &:disabled {
    opacity: 0.5;
    &:hover,
    &:active {
      background: ${props => props.backgroundColor};
      border: solid 1px ${props => props.borderColor};
      box-shadow: none;
    }
  }
`;

export interface ButtonProps {
    children: any;
    color?: 'yellow' | 'white' | 'blue' | 'red';
    size?: string;
    style?: any;
    type?: string;
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: () => void;
}

const yellowTheme = {
    textColor: Colors.black,
    borderColor: 'transparent',
    backgroundColor: Colors.sunflower,
    hoverColor: Colors.sunflower,
    activeColor: Colors.yellowOrange
};

const redTheme = {
    textColor: Colors.white,
    borderColor: 'transparent',
    backgroundColor: Colors.coral,
    hoverColor: Colors.coral,
    activeColor: Colors.coral
};

const whiteTheme = {
    textColor: Colors.brownGreyThree,
    borderColor: Colors.cloudyBlue,
    backgroundColor: 'white',
    hoverColor: 'white',
    activeColor: Colors.cloudyBlue
};

const blueTheme = {};

const renderButtonLoading = () => {
    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center' }}>
            <SmallLoading />
        </div>
    );
};

const Button = (props: ButtonProps): JSX.Element => {
    const { children, color, size, isLoading, disabled, ...otherProps } = props;
    let buttonColor: any = yellowTheme;
    if (color === 'white') {
        buttonColor = whiteTheme;
    } else if (color === 'blue') {
        buttonColor = blueTheme;
    } else if (color === 'red') {
        buttonColor = redTheme;
    }

    const renderedChildren = children instanceof Array ? children.map(c => <span key={StringUtils.randomString()}>{c}</span>) : <span>{children}</span>;

    return (
        <StyledButton {...buttonColor} {...otherProps} size={size || '50px'} disabled={disabled || isLoading}>
            {isLoading ? renderButtonLoading() : renderedChildren}
        </StyledButton>
    );
};

export default Button;
