import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CustomCheckbox from '../../../../../components/custom-checkbox/custom-checkbox';
import LoadingContainerScreen from '../../../../../components/loading-container-screen/loading-container-screen';
import { SelectOption } from '../../../../../components/select-field/select-field';
import { ButtonContainer } from '../../../../../components/simple-alert-modal/StyledComponents';
import { Convenience } from '../../../../../model/convenience';
import { ConvenienceCategory } from '../../../../../model/enums/convenience-category';
import { ConvenienceType } from '../../../../../model/enums/convenience-type';
import { defaultValue, ErrorAndMessage, Validation } from '../../../../../model/validation';
import ConvenienceService from '../../../../../services/convenience-service';
import ImageUpload from '../../../../../components/image-upload/image-upload';
import AuthUtils from '../../../../../shared/util/auth-utils';
import StringUtils from '../../../../../shared/util/string-utils';
import { entityValidator } from '../../../../../shared/util/validator-utils';
import {
    ButtonLeft,
    ButtonRight,
    CustomTextFieldMargin,
    CustomTextFieldMarginPadding,
    SelectedFieldMargin
} from '../../../StyledComponents';
import './convenience-create-or-edit-modal.scss';
import convenience from '../convenience';

interface IConvenienceCreateOrEditModalProps extends WithTranslation {
    id?: number;
    isOpen?: boolean;
    onSaveAction: () => void;
    establishmentId?: number;
    onCancelAction: () => void;
}

interface IConvenienceCreateOrEditModalState {
    isSaving: boolean;
    isLoading: boolean;
    notifyUser: boolean;
    convenience: Convenience;
    convenienceValidation: {
        name: Validation[];
        category: Validation[];
        image?: Validation[];
        pointAmount: Validation[];
        description: Validation[];
    };
    convenienceError: {
        name: ErrorAndMessage;
        image: ErrorAndMessage;
        category: ErrorAndMessage;
        pointAmount: ErrorAndMessage;
        description: ErrorAndMessage;
    };
}

class ConvenienceCreateOrEditModal extends React.Component<IConvenienceCreateOrEditModalProps, IConvenienceCreateOrEditModalState> {
    $defaultPath: string;
    $imageUploadRef: any;
    $convenienceCategory: SelectOption[];

    constructor(props) {
        super(props);
        this.state = {
            convenience: {
                type: ConvenienceType.PRODUCT
            },
            isSaving: false,
            isLoading: false,
            notifyUser: false,
            convenienceValidation: {
                name: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.name') }],
                category: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.category') }],
                description: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.description') }],
                pointAmount: [{ handler: event => StringUtils.isStringEmpty(event[0]), message: this.props.t('errors.pointsPerLiter') }]
            },
            convenienceError: {
                name: defaultValue,
                image: defaultValue,
                category: defaultValue,
                pointAmount: defaultValue,
                description: defaultValue
            }
        };
        this.$defaultPath = '';
        this.$imageUploadRef = React.createRef();
        this.$convenienceCategory = this.setConvenienceCategory();
    }

    componentWillReceiveProps(nextProps: Readonly<IConvenienceCreateOrEditModalProps>) {
        if (nextProps.id != null && (nextProps.id !== this.props.id)) this.getConvenience(nextProps.id);
    }

    closeAndReset = async () => {
        await this.resetAll();
        this.props.onCancelAction();
    };

    resetAll = async () => {
        this.setState({
            convenience: {
                type: ConvenienceType.PRODUCT
            },
            notifyUser: false,
            convenienceError: {
                name: defaultValue,
                image: defaultValue,
                category: defaultValue,
                pointAmount: defaultValue,
                description: defaultValue
            }
        });
    };

    notifyUser = () => {
        this.setState({
            notifyUser: !this.state.notifyUser
        });
    };

    setConvenienceCategory = () => {
        return Object.values(ConvenienceCategory)
            .map(convenienceCategory => {
                return {
                    value: convenienceCategory,
                    label: this.props.t(`enum.convenienceCategory.${convenienceCategory}`)
                }
            });
    };

    getConvenience = (id: number) => {
        this.isLoading(true);
        ConvenienceService.getConvenience(id)
            .then(result => {
                this.setState({
                    convenience: result
                }, () => this.isLoading(false));
            })
            .catch(() => {
                this.isLoading(false);
                this.closeAndReset().then(callback => callback);
            })
    };

    isLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    };

    isSaving = (isSaving: boolean) => {
        this.setState({
            isSaving
        });
    };

    validateLabel = (key: string) => {
        const { convenienceValidation } = this.state;
        this.setState({
            convenienceError: {
                ...this.state.convenienceError,
                [key]: entityValidator(this.getValidationEvents(key), convenienceValidation[key])
            }
        });
    };

    convenienceAction = () => {
        if (this.state.convenience.id != null) return this.updateConvenience();
        return this.createConvenience();
    };

    updateConvenience = () => {
        this.isSaving(true);
        ConvenienceService.updateConvenience(this.state.convenience)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };

    createConvenience = () => {
        const { notifyUser } = this.state;
        const convenience = { ...this.state.convenience };

        this.isSaving(true);

        if (AuthUtils.isFgs()) {
            convenience.establishment = {};
            convenience.establishment.id = this.props.establishmentId;
        }

        ConvenienceService.createConvenience(convenience, notifyUser)
            .then(() => {
                this.props.onSaveAction();
                this.isSaving(false);
                this.closeAndReset().then(callback => callback);
            })
            .catch(() => this.isSaving(false));
    };

    globalValidateLabels = () => {
        const newConvenienceError = { ...this.state.convenienceError };
        const { convenienceValidation } = this.state;
        const convenience = { ...this.state.convenience };

        convenience.image = this.$imageUploadRef.getFile();

        Object.keys(convenienceValidation).forEach(key =>
            newConvenienceError[key] = entityValidator(this.getValidationEvents(key), convenienceValidation[key]));

        this.setState({
            convenienceError: newConvenienceError,
            convenience
        }, () => {
            if (this.findAnyError()) return;
            this.convenienceAction();
        });
    };

    findAnyError = () => {
        return Object.keys(this.state.convenienceError).filter(key => this.state.convenienceError[key]?.value).length > 0;
    };

    getValidationEvents = (key: string) => {
        const { convenience } = this.state;
        switch (key) {
            case 'name':
                return [[convenience?.name]];

            case 'category':
                return [[convenience?.category]];

            case 'pointAmount':
                return [[convenience?.pointAmount]];

            case 'description':
                return [[convenience?.description]];

            default:
                return [[false]]
        }
    };

    updateLabel = (event: any, key: string) => {
        this.setState({
            convenience: {
                ...this.state.convenience,
                [key]: event
            }
        });
    };

    render() {
        const { t, isOpen } = this.props;
        const { convenience, isLoading, isSaving, convenienceError, notifyUser } = this.state;
        const backdropProps = { timeout: 500 };
        const convenienceClassName = isLoading ? 'is-loading' : '';
        const onCloseAction = !isLoading && !isSaving ? this.closeAndReset : void (0);
        return (
            <Modal
                closeAfterTransition
                open={isOpen || false}
                onClose={onCloseAction}
                BackdropComponent={Backdrop}
                BackdropProps={backdropProps}
                className={'convenience-create-or-edit-wrapper'}
            >
                <Fade in={isOpen}>
                    <div className={classNames([convenienceClassName, 'convenience-create-or-edit-wrap'])}>
                        <LoadingContainerScreen isLoading={isLoading}>
                            <div className="icon-fechar" onClick={onCloseAction} />
                            <span className={'body-title'}>
                                {t(convenience.id != null ? 'convenience.editModal' : 'convenience.newModal')}
                            </span>
                            <div className={'middle-items'}>
                                <ImageUpload
                                    image={this.state.convenience?.image}
                                    callbackRef={ref => (this.$imageUploadRef = ref)}
                                />
                                <SelectedFieldMargin
                                    id={StringUtils.randomString()}
                                    dataArray={this.$convenienceCategory}
                                    selected={convenience.category != null}
                                    className={'select-field-reference'}
                                    error={convenienceError.category?.value}
                                    initialValue={convenience?.category || ''}
                                    errorText={convenienceError.category?.message}
                                    onBlur={() => this.validateLabel('category')}
                                    onChange={event => this.updateLabel(event, 'category')}
                                    placeholder={t('convenience.textField.convenienceCategory')}
                                />
                                <CustomTextFieldMargin
                                    value={convenience.name}
                                    id={StringUtils.randomString()}
                                    error={convenienceError.name?.value}
                                    errorText={convenienceError.name?.message}
                                    onBlur={() => this.validateLabel('name')}
                                    placeholder={t('convenience.textField.name')}
                                    onChange={event => this.updateLabel(event, 'name')}
                                />
                                <CustomTextFieldMarginPadding
                                    value={convenience.description}
                                    id={StringUtils.randomString()}
                                    error={convenienceError.description?.value}
                                    errorText={convenienceError.description?.message}
                                    onBlur={() => this.validateLabel('description')}
                                    placeholder={t('convenience.textField.description')}
                                    onChange={event => this.updateLabel(event, 'description')}
                                />
                                <CustomTextFieldMargin
                                    isOnlyNumbers
                                    className={'pointAmount'}
                                    id={StringUtils.randomString()}
                                    error={convenienceError.pointAmount?.value}
                                    errorText={convenienceError.pointAmount?.message}
                                    onBlur={() => this.validateLabel('pointAmount')}
                                    placeholder={t('convenience.textField.pointAmount')}
                                    onChange={event => this.updateLabel(!StringUtils.isStringEmpty(event) ? Number(event) : null, 'pointAmount')}
                                    value={convenience.pointAmount != null ? StringUtils.formatCurrencyAndNumber(convenience.pointAmount, true, 0) : undefined}
                                />
                                {convenience.id == null && (
                                    <div className={'notify-user-wrapper'}>
                                        <CustomCheckbox title={t('convenience.textField.notifyUser')} checked={notifyUser} onChange={this.notifyUser} />
                                    </div>
                                )}
                            </div>
                            <ButtonContainer>
                                <ButtonLeft onClick={onCloseAction} disabled={isSaving} color="white" type="submit">
                                    {t('global.button.cancel')}
                                </ButtonLeft>
                                <ButtonRight onClick={this.globalValidateLabels} isLoading={isSaving} type="submit">
                                    {t('global.button.save')}
                                </ButtonRight>
                            </ButtonContainer>
                        </LoadingContainerScreen>
                    </div>
                </Fade>
            </Modal>
        );
    }
}

export default withTranslation()(ConvenienceCreateOrEditModal);
