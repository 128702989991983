import styled from 'styled-components/macro';

export const HomeContainer = styled.div`
    display: flex;
    justify-content: center;
    overflow: auto;
    padding-bottom: 80px;
    padding: 40px 20px 0;
    width: 100%;
    height:50px;
`;
